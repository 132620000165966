import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { companyReducer } from './companyReducer';
import { messageReducer } from './messageReducer';
import { inventoryReducer } from './inventoryReducer';
import { contactReducer } from './contactReducer';
import { accountsReducer } from './accountsReducer';
import { settingReducer } from './settingReducer';
import { salesReducer } from './salesReducer';
import { inventoryTransactionReducer } from './inventoryTransactionReducer';
import { paginationReducer } from './paginationReducer';
import { creditNotesReducer } from './creditNotesReducer';
import { debitNotesReducer } from './debitNotesReducer';
import { purchaseReducer } from './purchaseReducer';
import { salesOrderReducer } from './salesOrderReducer';
import { productPricingReducer } from './productPricingReducer';
import { reportsReducer } from './reportsReducer';
import { journalTransactionReducer } from './journalTransactionsReducer';
import { activityReducer } from './ActivityReducer';
import { orderListReducer } from './orderListReducer';
import { workbookReducer } from './workbookReducer';
import { manifestReducer } from './manifestReducer';
import { purchaseChallanReducer } from './purchaseChallanReducer';
import { dashboardReducer } from './dashboardReducer';
import { rtoManifestReducer } from './rtoManifestReducer';
import { notificationsReducer } from './notificationsReducer';
import { membershipReducer } from './membershipReducer';
const appReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  company: companyReducer,
  inventory: inventoryReducer,
  contact: contactReducer,
  accounts: accountsReducer,
  setting: settingReducer,
  sales: salesReducer,
  inventoryTransaction: inventoryTransactionReducer,
  pagination: paginationReducer,
  creditNotes: creditNotesReducer,
  debitNotes: debitNotesReducer,
  purchase: purchaseReducer,
  purchaseChallan: purchaseChallanReducer,
  salesOrder: salesOrderReducer,
  productPricing: productPricingReducer,
  reports: reportsReducer,
  journalTransaction: journalTransactionReducer,
  activity: activityReducer,
  orderList: orderListReducer,
  workbook: workbookReducer,
  manifest: manifestReducer,
  rtoManifest: rtoManifestReducer,
  dashboard: dashboardReducer,
  notification: notificationsReducer,
  membership: membershipReducer,
});

export default appReducer;
