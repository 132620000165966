import moment from 'moment';
import { WORKBOOK_STATUS_ID } from '../global/constants';
import { getTimeDiffrence, groupBy } from '../helper/common';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import * as Actions from './types';
/**
 * @desc Set workbook Loader
 */
export const setWorkbookLoader = (payload) => {
  return {
    type: Actions.SET_WORKBOOK_LOADER,
    payload,
  };
};

/**
 * @desc Set workbook pending & processed List
 */
export const setWorkbookList = (payload) => {
  let allWorkbooks = payload;
  if (payload && payload.length > 0) {
    allWorkbooks = getAllWorkbooks(payload);
  }
  return {
    type: Actions.SET_WORKBOOK_LIST,
    payload: allWorkbooks,
  };
};

/**
 * @desc Set workbook item
 */
export const setWorkbookItem = (payload) => {
  return {
    type: Actions.SET_WORKBOOK_ITEM,
    payload,
  };
};

/**
 * @desc update workbook Filtered List
 */
export const updateWorkbookFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_WORKBOOK_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Update workbook Item
 */
export const updateWorkbookItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_WORKBOOK_ITEM,
    payload,
  };
};

/**
 * @desc Clear workbook item
 */
export const clearWorkbookItem = () => {
  return {
    type: Actions.CLEAR_WORKBOOK_ITEM,
  };
};

/**
 * @desc Set Next Workbook Number
 */
export const setNextWorkbookNumber = (payload) => {
  return {
    type: Actions.SET_NEXT_WORKBOOK_NUMBER,
    payload,
  };
};

/**
 * @desc Set Workbook Summary List - Vendor Wise
 */
export const setWorkBookSummaryList = (payload) => {
  return {
    type: Actions.SET_WORKBOOK_SUMMARY_LIST,
    payload,
  };
};

/**
 * @desc Clear workbook data
 */
export const clearWorkbookData = () => {
  return {
    type: Actions.CLEAR_WORKBOOK_DATA,
  };
};

const getAllWorkbooks = (payload) => {
  const dueInDays = UserPreferenceSingleton.getInstance().getWorkbookDueInDays();

  let updatedList = payload;
  let pendingWorkbookList = updatedList?.filter((x) => x?.status < WORKBOOK_STATUS_ID.COMPLETED);

  //Find Duplicate Orders
  const duplicateList = pendingWorkbookList?.filter(
    (value, index, self) =>
      self.findIndex((x) => x?.contact?.id === value?.contact?.id && checkIfAllItemsAreSame(x?.items, value?.items)) !==
      index,
  );

  let allDuplicatesIds = [];
  let groupByCustomer = [];

  //Group order list by customer id and get all duplicate orders with same customer
  duplicateList?.forEach((item) => {
    allDuplicatesIds.push(item?.id);
    groupByCustomer = groupBy(pendingWorkbookList, 'contact.id');
    const groupIndexByContact = groupByCustomer?.findIndex((group) => group?.id === item?.contact?.id);
    if (groupIndexByContact !== -1) {
      groupByCustomer[groupIndexByContact]?.orderItems?.forEach((order) => {
        const isItemsSame = checkIfAllItemsAreSame(item?.items, order?.items);
        if (isItemsSame) {
          allDuplicatesIds.push(order?.id);
        }
      });
    }
  });

  // Get all unique duplicates and set isDuplicate True or False
  allDuplicatesIds = allDuplicatesIds?.filter((v, i, a) => a.indexOf(v) === i);

  updatedList = updatedList?.map((workbook) => {
    if (workbook?.status < WORKBOOK_STATUS_ID.COMPLETED) {
      const isDuplicate = allDuplicatesIds?.includes(workbook?.id);
      const expiryDate = moment.utc(workbook?.items?.[0]?.estimate_date).format('YYYY-MM-DD');
      const differenceDays = Math.round(getTimeDiffrence(new Date(), new Date(expiryDate)));
      workbook.isDuplicate = isDuplicate ? true : false;
      workbook.isToBeDue = differenceDays < dueInDays && differenceDays >= 0 ? true : false;
      workbook.isOverdue = differenceDays < 0 ? true : false;
    }
    workbook.total_qty = workbook?.items?.reduce((sum, val) => sum + Number(val?.qty || 0), 0);
    return workbook;
  });
  return updatedList;
};

const checkIfAllItemsAreSame = (itemsArr1, itemsArr2) => {
  if (itemsArr1?.length !== itemsArr2?.length) return false;
  let isAllSame;
  itemsArr1?.forEach((y, i) => {
    if (y?.product?.id === itemsArr2?.[i]?.product?.id) {
      isAllSame = true;
    } else {
      isAllSame = false;
      return isAllSame;
    }
  });
  return isAllSame;
};

/**
 * @desc Set Workbook Items
 */
export const setWorkbookItems = (payload) => {
  return {
    type: Actions.SET_WORKBOOK_ITEMS,
    payload,
  };
};
