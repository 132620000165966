import amplitude from 'amplitude-js';
import _ from 'lodash';
import { REACT_APP_AMPLITUDE_ANALYTICS_KEY } from '../global/environment';
import { getCookie, isDev } from '../helper/common';

const analytics = amplitude.getInstance();
analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);

let currentUserId;
let currentCompanyId;

/**
 * @desc init analytics
 */
export const initAnalytics = () => {
  try {
    if (isDev()) {
      console.log('initAnalytics');
      return;
    }
    if (REACT_APP_AMPLITUDE_ANALYTICS_KEY) analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc set user's company information for analytics
 */
const setAnalyticCompany = (id, properties) => {
  try {
    currentCompanyId = id;
    if (isDev()) {
      // console.log('setAnalyticCompany', id, properties);
      return;
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc set userid and email for analytics
 */
const setAnalyticUser = (user) => {
  try {
    currentUserId = user?.id;
    if (isDev()) {
      // console.log('setAnalyticUser', user?.id);
      return;
    }
    if (user && user.email) {
      if (analytics) {
        analytics.setUserId(user.email);
        analytics.setUserProperties({
          email: user.email,
        });
      }
    } else {
      clearAnlayticsUser();
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc set use and company for analytics
 */
export const setAnlayticUserAndCompany = (user, company) => {
  try {
    currentUserId = user?.id;
    currentCompanyId = company?.id;
    if (isDev()) {
      // console.log('setUserAndCompany', user, company);
      return;
    }
    const utm_source = getCookie('utm_source');
    const utm_medium = getCookie('utm_medium');
    const utm_campaign = getCookie('utm_campaign');
    const utm_content = getCookie('utm_content');
    const utm_term = getCookie('utm_term');
    const referrer = getCookie('referrer');
    const ref = getCookie('ref');

    const inputPayload = {
      id: user.id,
      email: user.email,
      created_at: user.createdAt,
      name: user.name,
      phone: user.mobile,
      company: {},
    };

    if (utm_source) inputPayload.utm_source = utm_source;
    if (utm_medium) inputPayload.utm_medium = utm_medium;
    if (utm_campaign) inputPayload.utm_campaign = utm_campaign;
    if (utm_content) inputPayload.utm_content = utm_content;
    if (utm_term) inputPayload.utm_term = utm_term;
    if (referrer) inputPayload.referrer = referrer;
    if (ref) inputPayload.refer_by = ref;

    setAnalyticUser(inputPayload);

    if (company) {
      inputPayload.company = {
        id: company.id,
        name: company.name,
        created_at: company.created_on,
        plan: company.plan,
        monthly_spend: company.spend,
      };
      setAnalyticCompany(company.id, inputPayload.company);
    }
    if (window.salesmachine) {
      const salesmachinePayload = { ...inputPayload };
      delete salesmachinePayload.company; //Delete company payload for salesmachine
      if (company) {
        salesmachinePayload.account_uid = company.id;
      }
      window.salesmachine.contact(user.id, salesmachinePayload);
      if (company) {
        const accountPayload = inputPayload.company;
        if (accountPayload && company && window.salesmachine) window.salesmachine.account(company.id, accountPayload);
      }
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc clear user information for analytics
 */
export const clearAnlayticsUser = () => {
  try {
    currentUserId = undefined;
    currentCompanyId = undefined;
    if (isDev()) {
      console.log('clearAnlayticsUser');
      return;
    }
    if (analytics) analytics.setUserId(null);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track activity in analytics
 */
export const trackAnalyticActivity = (name, params) => {
  try {
    if (isDev()) {
      // console.log('trackAnalyticActivity', name, params);
      return;
    }
    if (analytics) analytics.logEvent(name, params);
    if (window.salesmachine && currentUserId) {
      if (!params) params = {};
      if (currentCompanyId) params.account_uid = currentCompanyId;
      window.salesmachine.track(currentUserId, name, params);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track page view
 */
export const trackPage = (params) => {
  try {
    var fnTrackPage = _.throttle(() => {
      if (isDev()) {
        // console.log('trackPage', params);
        return;
      }
      if (window.salesmachine && currentUserId) {
        if (!params) params = {};
        if (currentCompanyId) params.account_uid = currentCompanyId;
        window.salesmachine.track(currentUserId, 'pageview', params);
      }
    }, 500);
    fnTrackPage();
  } catch (e) {
    console.log('ANALYTICS ERROR trackPage', e);
  }
};
