import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  productList: undefined,
  filteredDataList: undefined,
  productkit: {
    id: undefined,
    product: undefined,
    items: [],
    total_qty: 0,
    total_amount: 0,
    is_show_in_product_detail: true,
    is_update_sales_price: true,
    maintenance_cost: 0,
  },
  productPreview: undefined,
  productValues: undefined,
};

export const productPricingReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_PRODUCT_PRICING_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_PRODUCT_PRICING_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case Actions.SET_PRODUCT_PRICING_ITEM:
      return {
        ...state,
        productkit: action.payload,
      };
    case Actions.SET_PRODUCT_PRICING_VALUES:
      return {
        ...state,
        productValues: action.payload,
      };
    case Actions.UPDATE_PRODUCT_PRICING_ITEM:
      return {
        ...state,
        productkit: { ...state.productkit, ...action.payload },
      };
    case Actions.UPDATE_PRODUCT_PRICING_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_PRODUCT_PRICING_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.productkit));
      return {
        ...state,
        productkit: invoiceData,
      };
    case Actions.SET_PRODUCT_PRICING_PREVIEW:
      return {
        ...state,
        productPreview: action.payload,
      };
    case Actions.CLEAR_PRODUCT_PRICING_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
