import * as Actions from './types';

/**
 * @desc Set Company List
 */

export const setCompanyList = (payload) => ({
  type: Actions.SET_COMPANY_LIST,
  payload,
});

/**
 * @desc Set Selected Company
 */
export const setSelectedCompany = (payload) => ({
  type: Actions.SET_SELECTED_COMPANY,
  payload,
});

/**
 * @desc Set Company Loader
 */

export const setCompanyLoader = (payload) => {
  return {
    type: Actions.SET_COMPANY_LOADER,
    payload: payload,
  };
};

/**
 * @desc Set Company Details
 */
export const setCompanyDetails = (payload) => {
  return {
    type: Actions.SET_COMPANY_DETAILS,
    payload,
  };
};

/**
 * @desc Add Organisation Item
 */
export const addOrganisationItem = (payload) => {
  return {
    type: Actions.ADD_ORGANISATION_ITEM,
    payload,
  };
};

/**
 * @desc Set Organisation Item
 */
export const setOrganisationItem = (payload) => {
  return {
    type: Actions.SET_ORGANISATION_ITEM,
    payload,
  };
};

/**
 * @desc Update Organisation Item
 */
export const updateOrganisationItem = (payload) => {
  return {
    type: Actions.UPDATE_ORGANISATION_ITEM,
    payload,
  };
};

/**
 * @desc Clear Organisation Item
 */
export const clearOrganisationItem = () => {
  return {
    type: Actions.CLEAR_ORGANISATION_ITEM,
  };
};

/**
 * @desc Clear Company Data
 */
export const clearCompanyData = () => {
  return {
    type: Actions.CLEAR_COMPANY_DATA,
  };
};
