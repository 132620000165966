import * as Actions from './types';

/**
 * @desc Set Journal Transactions Loader
 */
export const setJournalTransactionsLoader = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_LOADER,
    payload,
  };
};

/**
 * @desc set Journal Transactions List
 */
export const setJournalTransactionsList = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_LIST,
    payload,
  };
};
/**
 * @desc update Journal Transactions Filtered List
 */
export const setJournalTransactionsFilteredList = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc set Journal Transactions Item
 */
export const setJournalTransactionsItem = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_ITEM,
    payload,
  };
};

/**
 * @desc set Journal Transactions Item Details
 */
export const setJournalTransactionsDetails = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_ITEM_DETAILS,
    payload,
  };
};

/**
 * @desc update Journal Transactions Item
 */
export const updateJournalTransactionsItem = (payload) => {
  return {
    type: Actions.UPDATE_JOURNAL_TRANSACTION_ITEM,
    payload,
  };
};

/**
 * @desc clear Journal Transactions Item
 */
export const clearJournalTransactionsItem = () => {
  return {
    type: Actions.CLEAR_JOURNAL_TRANSACTION_ITEM,
  };
};

/**
 * @desc clear Journal Transactions Data
 */
export const clearJournalTransactionsData = () => {
  return {
    type: Actions.CLEAR_JOURNAL_TRANSACTION_DATA,
  };
};

/**
 * @desc Set Journal Transaction items
 */
export const setJournalTransactionItems = (payload) => {
  return {
    type: Actions.SET_JOURNAL_TRANSACTION_ITEMS,
    payload,
  };
};
