import * as Actions from '../actions/types';
import { BANK_ACCOUNTS_TYPE_BANK } from '../global/constants';
import { getDate } from '../helper/common';

const initialState = {
  loading: false,
  bankList: undefined,
  bankTransactionList: undefined,
  filterDataList: [],
  taxList: undefined,
  tdsList: undefined,
  tcsList: undefined,
  bankTransactionDetails: {},
  matchTransactionInvoiceList: [],
  bankItem: {
    type: BANK_ACCOUNTS_TYPE_BANK,
    name: '',
  },
  transactionItem: {
    date: new Date(),
    chequeDate: getDate(),
    bankObj: {},
    bank: '',
    transaction: '',
    account: '',
    amount: 0,
    description: '',
    totalAmount: 0,
    contactObj: {},
    cheque: '',
    bankName: '',
  },
  transferFundItem: {
    amount: 0,
    date: new Date(),
    fromBank: undefined,
    toBank: undefined,
  },
  bankTransactionsUncategorizedBenchmark: undefined,
};

export const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_BANK_ACCOUNT_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_BANK_ACCOUNT_LIST:
      return {
        ...state,
        bankList: action.payload,
      };
    case Actions.SET_BANKS_TRANSACTIONS_LIST:
      return {
        ...state,
        bankTransactionList: action.payload,
      };
    case Actions.SET_BANKS_ITEM:
      return {
        ...state,
        bankItem: action.payload,
      };
    case Actions.UPDATE_BANK_ITEM:
      return {
        ...state,
        bankItem: { ...state.bankItem, ...action.payload },
      };
    case Actions.CLEAR_BANK_ITEM:
      const bankData = JSON.parse(JSON.stringify(initialState.bankItem));
      return {
        ...state,
        bankItem: bankData,
      };
    case Actions.UPDATE_BANK_TRANSACTIONS_ITEM:
      return {
        ...state,
        transactionItem: { ...state.transactionItem, ...action.payload },
      };
    case Actions.CLEAR_BANK_TRANSACTIONS_ITEM:
      const transactionData = JSON.parse(JSON.stringify(initialState.transactionItem));
      return {
        ...state,
        transactionItem: transactionData,
      };
    case Actions.UPDATE_TRANSFER_FUND_ITEM:
      return {
        ...state,
        transferFundItem: { ...state.transferFundItem, ...action.payload },
      };
    case Actions.CLEAR_TRANSFER_FUND_ITEM:
      const transferFundItemData = JSON.parse(JSON.stringify(initialState.transferFundItem));
      return {
        ...state,
        transferFundItem: transferFundItemData,
      };
    case Actions.UPDATE_FILTER_DATA_LIST:
      return {
        ...state,
        filterDataList: action.payload,
      };
    case Actions.SET_TAX_LIST:
      return {
        ...state,
        taxList: action.payload,
      };
    case Actions.SET_TDS_LIST:
      return {
        ...state,
        tdsList: action.payload,
      };
    case Actions.SET_TCS_LIST:
      return {
        ...state,
        tcsList: action.payload,
      };
    case Actions.SET_BANKS_TRANSACTIONS_DETAILS:
      return {
        ...state,
        bankTransactionDetails: action.payload,
      };
    case Actions.UPDATE_BANKS_TRANSACTIONS_DETAILS:
      return {
        ...state,
        bankTransactionDetails: { ...state.bankTransactionDetails, ...action.payload },
      };
    case Actions.CLEAR_BANKS_TRANSACTIONS_DETAILS:
      const bankTransactionDetails = JSON.parse(JSON.stringify(initialState.bankTransactionDetails));
      return {
        ...state,
        bankTransactionDetails: bankTransactionDetails,
      };
    case Actions.SET_MATCH_TRANSACTION_INVOICE_LIST:
      return {
        ...state,
        matchTransactionInvoiceList: action.payload,
      };
    case Actions.UPDATE_MATCH_TRANSACTION_INVOICE_LIST:
      let list = [...state.matchTransactionInvoiceList];
      const { invoiceIndex, propNameArr, valueArr } = action.payload;
      if (invoiceIndex !== -1 && propNameArr?.length === valueArr?.length) {
        propNameArr?.forEach((propName, i) => {
          list[invoiceIndex][propName] = valueArr[i];
        });
      }
      return {
        ...state,
        matchTransactionInvoiceList: list,
      };
    case Actions.SET_BANK_TRANSACTIONS_UNCATEGORIZED_BENCHMARK:
      return {
        ...state,
        bankTransactionsUncategorizedBenchmark: action.payload,
      };
    case Actions.CLEAR_MATCH_TRANSACTION_INVOICE_LIST:
      const matchTransactionInvoiceList = JSON.parse(JSON.stringify(initialState.matchTransactionInvoiceList));
      return {
        ...state,
        matchTransactionInvoiceList: matchTransactionInvoiceList,
      };
    case Actions.CLEAR_BANK_ACCOUNT_DATA:
      return initialState;
    default:
      return state;
  }
};
