import * as Actions from './types';

/**
 * @desc Set Manifest Loader
 */
export const setManifestLoader = (payload) => {
  return {
    type: Actions.SET_MANIFEST_LOADER,
    payload,
  };
};

/**
 * @desc Set manifest List
 */
export const setManifestList = (payload) => {
  return {
    type: Actions.SET_MANIFEST_LIST,
    payload,
  };
};

/**
 * @desc update Manifest Filtered List
 */
export const updateManifestFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_MANIFEST_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Clear manifest data
 */
export const clearManifestData = () => {
  return {
    type: Actions.CLEAR_MANIFEST_DATA,
  };
};
