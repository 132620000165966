import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isRoleAccess } from '../services/baseService';
import store from '../store/store';
import { trackPage } from '../services/analyticsService';

const handleAccess = (roleName) => {
  return store.dispatch(isRoleAccess(roleName));
};

const PrivateRoute = ({ component: Component, auth, setting, ...rest }) => {
  if (rest?.location?.pathname) trackPage({ visit_url: rest?.location?.pathname });
  let hasAccess = { is_read_access: true, is_write_access: true, is_delete_access: true };
  if (rest.role) {
    hasAccess = handleAccess(rest.role);
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {auth.isAuthenticated === true ? (
              <>
                {hasAccess?.is_read_access === true ? (
                  <Component
                    hasWriteAccess={hasAccess?.is_write_access}
                    hasDeleteAccess={hasAccess?.is_delete_access}
                    {...props}
                  />
                ) : (
                  <Redirect to='/access-denied' />
                )}
              </>
            ) : (
              <Redirect to='/login' />
            )}
          </>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps)(PrivateRoute);
