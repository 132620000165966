import moment from 'moment';
import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  chartOfAccountsList: undefined,
  companySettings: undefined,
  userAndRolesList: undefined,
  invitedUsers: undefined,
  userRoles: undefined,
  catalogueList: undefined,
  filteredCatalogueList: undefined,
  catalogueItem: undefined,
  brandList: undefined,
  brandItem: {
    name: '',
    address: '',
    phone: '',
    email: '',
  },
  announcementList: undefined,
  annoucementFilteredList: undefined,
  announcementItem: {
    from_date: new Date(),
    to_date: new Date(),
    title: '',
    description: '',
    type: 1,
  },
  openingBalance: {
    date: moment(new Date()).format('YYYY-MM-DD'),
    accounts: undefined,
  },
  lockingPeriodList: undefined,
  lockingPeriodFilteredList: undefined,
  lockingPeriodItem: {
    from_date: new Date(),
    to_date: new Date(),
    type: 1,
  },
  benchmark: { online_orders: {}, offline_orders: {}, bank_transactions: {} },
  eInvoiceSettings: {},
  exotelSettings: {},
  shiprocketSettings: {},
  delhiverySettings: {},
  marketplaceList: [],
  marketplaceItem: {
    name: '',
  },
  chartOfAccountCategoryList: [],
  categoryList: undefined,
  categoryItem: {
    name: '',
    hsn_code: '',
    tax_slab: '',
    weight: '',
    dimension: 'CM',
    length: '',
    breadth: '',
    height: '',
    qty_label: [{ name: '' }],
  },
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_SETTING_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_USERS_AND_ROLES_LIST:
      return {
        ...state,
        userAndRolesList: action.payload.userAndRolesList,
        invitedUsers: action.payload.invitedUsers,
        userRoles: action.payload.userRoles,
      };
    case Actions.SET_CHART_OF_ACCOUNTS_LIST:
      return {
        ...state,
        chartOfAccountsList: action.payload,
      };
    case Actions.SET_CHART_OF_ACCOUNTS_DETAILS:
      return {
        ...state,
        chartOfAccountsDetails: action.payload,
      };
    case Actions.SET_CHART_OF_ACCOUNTS_CATEGORY_LIST:
      return {
        ...state,
        chartOfAccountCategoryList: action.payload,
      };
    case Actions.SET_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: action.payload,
      };
    case Actions.SET_COMPANY_E_INVOICE_SETTINGS:
      return {
        ...state,
        eInvoiceSettings: action.payload,
      };
    case Actions.SET_COMPANY_EXOTEL_SETTINGS:
      return {
        ...state,
        exotelSettings: action.payload,
      };
    case Actions.SET_COMPANY_SHIPROCKET_SETTINGS:
      return {
        ...state,
        shiprocketSettings: action.payload,
      };
    case Actions.SET_COMPANY_DELHIVERY_SETTINGS:
      return {
        ...state,
        delhiverySettings: action.payload,
      };
    case Actions.SET_OPENING_BALANCE:
      return {
        ...state,
        openingBalance: action.payload,
      };
    case Actions.UPDATE_OPENING_BALANCE:
      return {
        ...state,
        openingBalance: { ...state.openingBalance, ...action.payload },
      };
    case Actions.SET_CATALOGUE_LIST:
      return {
        ...state,
        catalogueList: { ...state.catalogueList, ...action.payload },
      };
    case Actions.SET_FILTERED_CATALOGUE_LIST:
      return {
        ...state,
        filteredCatalogueList: { ...action.payload },
      };
    case Actions.SET_CATALOGUE_ITEM:
      return {
        ...state,
        catalogueItem: { ...state.catalogueItem, ...action.payload },
      };
    case Actions.CLEAR_CATALOGUE_ITEM:
      const catalogueItem = initialState.catalogueItem;
      return {
        ...state,
        catalogueItem: catalogueItem,
      };
    case Actions.SET_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcementList: action.payload,
      };
    case Actions.SET_ANNOUNCEMENT_ITEM:
      return {
        ...state,
        announcementItem: action.payload,
      };
    case Actions.CLEAR_ANNOUNCEMENT_ITEM:
      const announcementItem = JSON.parse(JSON.stringify(initialState.announcementItem));
      return {
        ...state,
        announcementItem,
      };
    case Actions.SET_ANNOUNCEMENT_FILTERED_LIST:
      return {
        ...state,
        annoucementFilteredList: action.payload,
      };
    case Actions.SET_LOCKING_PERIOD_LIST:
      return {
        ...state,
        lockingPeriodList: action.payload,
      };
    case Actions.SET_LOCKING_PERIOD_FILTERED_LIST:
      return {
        ...state,
        lockingPeriodFilteredList: action.payload,
      };
    case Actions.SET_LOCKING_PERIOD_ITEM:
      return {
        ...state,
        lockingPeriodItem: action.payload,
      };
    case Actions.CLEAR_LOCKING_PERIOD_ITEM:
      const lockingPeriodItem = JSON.parse(JSON.stringify(initialState.lockingPeriodItem));
      return {
        ...state,
        lockingPeriodItem,
      };
    case Actions.SET_MARKETPLACE_LIST:
      return {
        ...state,
        marketplaceList: action.payload,
      };
    case Actions.SET_MARKETPLACE_ITEM:
      return {
        ...state,
        marketplaceItem: action.payload,
      };
    case Actions.UPDATE_MARKETPLACE_ITEM:
      return {
        ...state,
        marketplaceItem: { ...state.marketplaceItem, ...action.payload },
      };
    case Actions.CLEAR_MARKETPLACE_LIST:
      return {
        ...state,
        marketplaceList: [],
      };
    case Actions.CLEAR_MARKETPLACE_ITEM:
      const marketplaceItem = JSON.parse(JSON.stringify(initialState.marketplaceItem));
      return {
        ...state,
        marketplaceItem,
      };
    case Actions.CLEAR_USERS_AND_ROLES_DATA:
      return initialState;
    case Actions.SET_BRAND_LIST:
      return {
        ...state,
        brandList: action.payload,
      };
    case Actions.CLEAR_BRAND_ITEM:
      const initialBrandItem = { name: '', address: '' };
      return {
        ...state,
        brandItem: initialBrandItem,
      };
    case Actions.UPDATE_BRAND_ITEM:
      return {
        ...state,
        brandItem: { ...state.brandItem, ...action.payload },
      };
    case Actions.SET_BRAND_ITEM:
      return {
        ...state,
        brandItem: action.payload,
      };
    case Actions.SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case Actions.SET_CATEGORY_ITEM:
      return {
        ...state,
        categoryItem: action.payload,
      };
    case Actions.UPDATE_CATEGORY_ITEM:
      return {
        ...state,
        categoryItem: { ...state.categoryItem, ...action.payload },
      };
    case Actions.CLEAR_CATEGORY_ITEM:
      const initialCategoryItem = JSON.parse(JSON.stringify(initialState.categoryItem));
      return {
        ...state,
        categoryItem: initialCategoryItem,
      };
    default:
      return state;
  }
};
