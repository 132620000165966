import * as Actions from './types';

/**
 * @desc Set Contact Loader
 */
export const setContactLoader = (payload) => {
  return {
    type: Actions.SET_CONTACT_LOADER,
    payload,
  };
};

/**
 * @desc Set Contact List Updated Flag
 */
export const setContactListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_CONTACT_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set All Contact List
 */
export const setAllContactList = (payload) => {
  return {
    type: Actions.SET_ALL_CONTACT_LIST,
    payload,
  };
};

/**
 * @desc Set Customer List
 */
export const setCustomerList = (payload) => {
  return {
    type: Actions.SET_CUSTOMER_LIST,
    payload,
  };
};

/**
 * @desc Set Contact  List
 */
export const setContactDetails = (payload) => {
  return {
    type: Actions.SET_CONTACT_DETAILS,
    payload,
  };
};

/**
 * @desc Add Contact Item (For invoice).
 */
export const addContactItem = (payload) => {
  return {
    type: Actions.ADD_CONTACT_ITEM,
    payload,
  };
};

/**
 * @desc Get Contact Tags Option
 */
export const setContactTagsOption = (payload) => {
  return {
    type: Actions.SET_CONTACT_TAGS_OPTION,
    payload,
  };
};

/**
 * @desc Set Contact Item
 */
export const setContactItem = (payload) => {
  return {
    type: Actions.SET_CONTACT_ITEM,
    payload,
  };
};

/**
 * @desc Update Contact item
 */
export const updateContactItem = (payload) => {
  return {
    type: Actions.UPDATE_CONTACT_ITEM,
    payload,
  };
};

/**
 * @desc Clear Contact Item data
 */
export const clearContactItemData = () => {
  return {
    type: Actions.CLEAR_CONTACT_ITEM,
  };
};

/**
 * @desc Set Supplier List
 */
export const setSupplierList = (payload) => {
  return {
    type: Actions.SET_SUPPLIER_LIST,
    payload,
  };
};

/**
 * @desc Set Account Manager List
 */
export const setAccountManagerList = (payload) => {
  payload = payload?.map((item) => {
    return { ...item, qty: item?.count || 0 };
  });
  return {
    type: Actions.SET_ACCOUNT_MANAGER_LIST,
    payload,
  };
};

/**
 * @desc Clear Sales Person Data
 */
export const setSalesPersonList = (payload) => {
  if (payload) {
    payload = payload.map((item) => {
      const formattedMobileNumbers = item.mobile_numbers?.map((x) => `${x.code}-${x.number}`);
      return {
        ...item,
        formatted_mobile_numbers: formattedMobileNumbers,
        label: item.company_name || item.first_name + ' ' + (item.last_name ? item.last_name : ''),
      };
    });
  }

  return {
    type: Actions.SET_SALES_PERSON_LIST,
    payload,
  };
};

/**
 * @desc Set Sales Person Details
 */
export const setSalesPersonItem = (payload) => {
  return {
    type: Actions.SET_SALES_PERSON_ITEM,
    payload,
  };
};

/**
 * @desc Update SalesPerson item
 */
export const updateSalesPersonItem = (payload) => {
  return {
    type: Actions.UPDATE_SALES_PERSON_ITEM,
    payload,
  };
};

/**
 * @desc Clear SalesPerson Item data
 */
export const clearSalesPersonItemData = () => {
  return {
    type: Actions.CLEAR_SALES_PERSON_ITEM,
  };
};

/**
 * @desc Clear Contact Data
 */
export const clearContactData = () => {
  return {
    type: Actions.CLEAR_CONTACT_DATA,
  };
};

/**
 * @desc update Contact Filtered Data List
 */
export const updateContactFilteredDataList = (payload) => {
  return {
    type: Actions.UPDATE_CONTACT_FILTERED_DATA_LIST,
    payload,
  };
};
