import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  purchaseChallanList: undefined,
  invoiceProducts: undefined,
  purchaseNumber: undefined,
  filteredDataList: undefined,
  challan: {
    id: undefined,
    contact: undefined,
    number: '',
    date: new Date(),
    due_date: 0,
    items: [],
    total_qty: 0,
    net_amount: 0,
    total_discount: 0,
    additional_fields: [],
    total_amount: 0,
    notes: '',
    sales_order_id: undefined,
    total_tax: 0,
    tax_list: undefined,
  },
  purchaseChallanItems: [],
  purchaseChallanPreview: undefined,
  purchaseChallanTagOptions: undefined,
};

export const purchaseChallanReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_PURCHASE_CHALLAN_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        purchaseChallanList: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_INVOICE_PRODUCT_LIST:
      return {
        ...state,
        invoiceProducts: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_ITEM:
      return {
        ...state,
        challan: action.payload,
      };
    case Actions.UPDATE_PURCHASE_CHALLAN_ITEM:
      return {
        ...state,
        challan: { ...state.challan, ...action.payload },
      };
    case Actions.CLEAR_PURCHASE_CHALLAN_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.challan));
      const purchaseChallanData = JSON.parse(JSON.stringify(initialState.purchaseChallanItems));
      return {
        ...state,
        purchaseChallanItems: purchaseChallanData,
        challan: invoiceData,
      };
    case Actions.SET_PURCHASE_CHALLAN_PREVIEW:
      return {
        ...state,
        purchaseChallanPreview: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_TAG_OPTIONS:
      return {
        ...state,
        purchaseChallanTagOptions: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_ITEMS:
      return {
        ...state,
        purchaseChallanItems: action.payload,
      };
    case Actions.SET_PURCHASE_CHALLAN_ITEMS_ROW:
      return {
        ...state,
        purchaseChallanItems: state.purchaseChallanItems.map((x, i) => {
          if (i === action.payload.index) x = action.payload.item;
          return x;
        }),
      };
    case Actions.CLEAR_PURCHASE_CHALLAN_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
