import socketIOClient from 'socket.io-client';
import { REACT_APP_APIURL } from '../global/environment';
import { getInventoryItemsList } from './inventoryService';
import { getOfflineOrderList, getOnlineOrderList } from './orderListService';

let singleton;
export default class RealtimeService {
  static getInstance() {
    if (!singleton) {
      singleton = new RealtimeService();
    }
    return singleton;
  }

  init = (userId, companyId) => async (dispatch) => {
    try {
      this.userId = userId;
      this.companyId = companyId;
      this.isUpdateRedux = false;
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
      this.socket = socketIOClient(REACT_APP_APIURL.replace('/api', ''));
      this.socket.emit('user_connected', { user_id: userId, company_id: companyId });
      this.socket.on('order-list-online-updated', (data) => {
        dispatch(getOnlineOrderList(companyId, this.isUpdateRedux));
      });
      this.socket.on('order-list-offline-updated', (data) => {
        dispatch(getOfflineOrderList(companyId));
      });
      this.socket.on('product-updated', (data) => {
        dispatch(getInventoryItemsList(companyId, undefined, undefined, undefined, this.isUpdateRedux));
      });
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  disconnect = (userId, companyId) => {
    try {
      if (this.socket) {
        const user_id = userId || this.userId;
        const company_id = companyId || this.companyId;
        if (user_id && company_id) {
          this.socket.emit('user_disconnected', {
            user_id: userId || this.userId,
            company_id: companyId || this.companyId,
          });
        }
        this.socket.disconnect();
        this.socket = null;
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  };
}
