import * as Actions from '../actions/types';

const initialState = {
  bestSalesProductsList: undefined,
  topVendorsList: undefined,
  channelWiseSalesList: undefined,
  RtoCountList: undefined,
  salesVolume: undefined,
  activeAnnouncementList: undefined,
  profitAndLoss: undefined,
  loading: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_DASHBOARD_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_BEST_SALES_PRODUCTS_LIST:
      return {
        ...state,
        bestSalesProductsList: action.payload,
      };
    case Actions.SET_TOP_VENDOR_LIST:
      return {
        ...state,
        topVendorsList: action.payload,
      };
    case Actions.SET_CHANNEL_WISE_SALES_LIST:
      return {
        ...state,
        channelWiseSalesList: action.payload,
      };
    case Actions.SET_RTO_COUNT_LIST:
      return {
        ...state,
        RtoCountList: action.payload,
      };
    case Actions.SET_SALES_VOLUME_LIST:
      return {
        ...state,
        salesVolume: action.payload,
      };
    case Actions.SET_PROFIT_AND_LOSS_OVERVIEW:
      return {
        ...state,
        profitAndLoss: action.payload,
      };
    case Actions.SET_ACTIVE_ANNOUNCEMENT_LIST:
      return {
        ...state,
        activeAnnouncementList: action.payload,
      };
    case Actions.CLEAR_DASHBOARD:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
