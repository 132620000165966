import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  rtoManifestList: undefined,
  filteredDataList: [],
  rtoManifestItem: undefined,
  rtoManifestPutUpList: undefined,
};

export const rtoManifestReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_RTO_MANIFEST_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_RTO_MANIFEST_LIST:
      return {
        ...state,
        rtoManifestList: action.payload,
      };
    case Actions.UPDATE_RTO_MANIFEST_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_RTO_MANIFEST_ITEM:
      return {
        ...state,
        rtoManifestItem: action.payload,
      };
    case Actions.SET_RTO_MANIFEST_PUT_UP_LIST:
      return {
        ...state,
        rtoManifestPutUpList: action.payload,
      };
    case Actions.CLEAR_RTO_MANIFEST_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
