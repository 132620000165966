import * as Actions from './../actions/types';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  inventoryTransactionList: undefined,
  inventoryTransactionDetails: {},
  inventoryTransactionByItem: undefined,
  filteredDataList: undefined,
};

export const inventoryTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_INVENTORY_TRANSACTION_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_INVENTORY_TRANSACTION_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_INVENTORY_TRANSACTION_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        inventoryTransactionList: action.payload,
      };
    case Actions.SET_INVENTORY_TRANSACTION_DETAILS:
      return {
        ...state,
        inventoryTransactionDetails: action.payload,
      };
    case Actions.SET_INVENTORY_TRANSACTION_BY_ITEM:
      return {
        ...state,
        inventoryTransactionByItem: action.payload,
      };
    case Actions.UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_INVENTORY_TRANSACTION_DATA:
      return initialState;
    default:
      return state;
  }
};
