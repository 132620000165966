import * as Actions from '../actions/types';
import { CONTACT_TYPE_ALL, ONLINE_ORDERS_PRIORITY, ORDER_STATUS_ID } from '../global/constants';

const initialState = {
  loading: false,
  filteredOrderList: undefined,
  onlineOrderPickUpListHistory: undefined,
  filteredOfflineOrderList: undefined,
  isOnlineListDataUpdated: false,
  isOfflineListDataUpdated: false,
  allOrders: undefined,
  filteredDataList: undefined,
  orderListItem: {
    id: undefined,
    date: new Date(),
    items: [],
    number: '',
    notes: '',
    status: ORDER_STATUS_ID.OPEN,
  },
  offlineItems: [],
  loadedEntry: undefined,
  channelList: undefined,
  channelItem: {
    name: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
  },
  couriersList: undefined,
  courierItem: {
    name: '',
    client_name: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    pickup_location_name: '',
    phone: '',
    country: '',
  },
  paymentGatewayList: undefined,
  onlineOrderPreview: undefined,
  onlineOrderDetail: undefined,
  offlineOrderDetail: undefined,
  offlineOrderPreview: undefined,
  onlineOrderTagOption: [],
  onlineOrderItem: {
    date: new Date(),
    formatChecked: 2,
    orderDetail: '',
    contact: {
      first_name: '',
      email: '',
      contact_type: CONTACT_TYPE_ALL,
    },
    addresses: [
      {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        name: 'Default Address',
        pincode: '',
        state: '',
        state_code: Number(''),
      },
    ],
    mobile_numbers: [
      { code: '', number: '' },
      { code: '', number: '' },
    ],
    priority: ONLINE_ORDERS_PRIORITY.REGULAR,
    orderListItem: [],
    total_amount: '',
    total_qty: '',
    payment_mode: 1,
    channel: undefined,
    courier: undefined,
    payment_gateway: undefined,
    reference_number: undefined,
    reference_id: '',
    notes: '',
    tracking_number: '',
    isExistingContact: false,
    number: '',
    payment_method: [],
    tags: [],
  },
  pendingBenchmarkPercentage: undefined,
  confirmedBenchmarkPercentage: undefined,
  processedBenchmarkPercentage: undefined,
  offlinePendingBenchmarkPercentage: undefined,
  onlineOrderCallHistory: undefined,
};

export const orderListReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_ORDER_LIST_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_ONLINE_ORDER_LIST_UPDATED_FLAG:
      return {
        ...state,
        isOnlineListDataUpdated: action.payload,
      };
    case Actions.SET_OFFLINE_ORDER_LIST_UPDATED_FLAG:
      return {
        ...state,
        isOfflineListDataUpdated: action.payload,
      };
    case Actions.SET_FILTERED_ORDER_LIST:
      return {
        ...state,
        filteredOrderList: action.payload,
      };
    case Actions.SET_FILTERED_OFFLINE_ORDER_LIST:
      return {
        ...state,
        filteredOfflineOrderList: action.payload,
      };
    case Actions.SET_OFFLINE_ORDER_LIST:
      return {
        ...state,
        isOfflineListDataUpdated: true,
        offlineAllOrders: action.payload.allOrders,
      };
    case Actions.UPDATE_ORDER_ITEM:
      return {
        ...state,
        orderListItem: { ...state.orderListItem, ...action.payload },
      };
    case Actions.SET_NEXT_OFFLINE_ORDER_NUMBER:
      return {
        ...state,
        orderListItem: { ...state.orderListItem, number: action.payload },
      };

    case Actions.UPDATE_ORDER_LIST_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_ORDER_ITEM:
      const orderData = JSON.parse(JSON.stringify(initialState.orderListItem));
      const orderItems = JSON.parse(JSON.stringify(initialState.offlineItems));
      return {
        ...state,
        orderListItem: orderData,
        offlineItems: orderItems,
      };
    case Actions.SET_OFFLINE_ORDER_ITEMS:
      return {
        ...state,
        offlineItems: action.payload,
      };
    case Actions.SET_OFFLINE_ORDER_ITEMS_ROW:
      return {
        ...state,
        offlineItems: state.offlineItems.map((x, i) => {
          if (i === action.payload.index) return action.payload.item;
          return x;
        }),
      };
    case Actions.CLEAR_ONLINE_ORDER_ITEM:
      const orderItem = JSON.parse(JSON.stringify(initialState.onlineOrderItem));
      return {
        ...state,
        onlineOrderItem: orderItem,
      };
    case Actions.SET_ONLINE_ORDER_LIST:
      return {
        ...state,
        isOnlineListDataUpdated: true,
        onlineAllOrders: action.payload.allOrders,
      };
    case Actions.UPDATE_ONLINE_ORDER_ITEM:
      return {
        ...state,
        onlineOrderItem: { ...state.onlineOrderItem, ...action.payload },
      };
    case Actions.SET_LOADED_ENTRY_DATA:
      return {
        ...state,
        loadedEntry: action.payload,
      };
    case Actions.SET_CHANNEL_LIST:
      return {
        ...state,
        channelList: action.payload,
      };
    case Actions.SET_CHANNEL_ITEM:
      return {
        ...state,
        channelItem: action.payload,
      };
    case Actions.UPDATE_CHANNEL_ITEM:
      return {
        ...state,
        channelItem: { ...state.channelItem, ...action.payload },
      };
    case Actions.CLEAR_CHANNEL_ITEM:
      const orderChannelItem = JSON.parse(JSON.stringify(initialState.channelItem));
      return {
        ...state,
        channelItem: orderChannelItem,
      };
    case Actions.SET_NEXT_ORDER_NUMBER:
      return {
        ...state,
        onlineOrderItem: { ...state.onlineOrderItem, number: action.payload },
      };
    case Actions.SET_COURIERS_LIST:
      return {
        ...state,
        couriersList: action.payload,
      };
    case Actions.SET_COURIER_ITEM:
      return {
        ...state,
        courierItem: action.payload,
      };
    case Actions.UPDATE_COURIER_ITEM:
      return {
        ...state,
        courierItem: { ...state.courierItem, ...action.payload },
      };
    case Actions.CLEAR_COURIER_ITEM:
      const orderCourierItem = JSON.parse(JSON.stringify(initialState.courierItem));
      return {
        ...state,
        courierItem: orderCourierItem,
      };
    case Actions.SET_PAYMENT_GATEWAY_LIST:
      return {
        ...state,
        paymentGatewayList: action.payload,
      };
    case Actions.SET_ONLINE_ORDER_PREVIEW:
      return {
        ...state,
        onlineOrderPreview: action.payload,
      };
    case Actions.SET_ONLINE_ORDER_DETAIL:
      return {
        ...state,
        onlineOrderDetail: action.payload,
      };
    case Actions.SET_OFFLINE_ORDER_DETAIL:
      return {
        ...state,
        offlineOrderDetail: action.payload,
      };
    case Actions.SET_ONLINE_PICKUP_LIST_HISTORY:
      return {
        ...state,
        onlineOrderPickUpListHistory: action.payload,
      };
    case Actions.SET_ONLINE_ORDER_TAG_OPTION:
      return {
        ...state,
        onlineOrderTagOption: action.payload,
      };
    case Actions.SET_OFFLINE_ORDER_PREVIEW:
      return {
        ...state,
        offlineOrderPreview: action.payload,
      };
    case Actions.SET_PENDING_BENCHMARK_PERCENTAGE:
      return {
        ...state,
        pendingBenchmarkPercentage: action.payload,
      };
    case Actions.SET_CONFIRMED_BENCHMARK_PERCENTAGE:
      return {
        ...state,
        confirmedBenchmarkPercentage: action.payload,
      };
    case Actions.SET_PROCESSED_BENCHMARK_PERCENTAGE:
      return {
        ...state,
        processedBenchmarkPercentage: action.payload,
      };
    case Actions.SET_OFFLINE_PENDING_BENCHMARK_PERCENTAGE:
      return {
        ...state,
        offlinePendingBenchmarkPercentage: action.payload,
      };
    case Actions.SET_ONLINE_ORDER_CALL_HISTORY:
      return {
        ...state,
        onlineOrderCallHistory: action.payload,
      };

    case Actions.CLEAR_ORDER_LIST_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
