import { toast } from 'react-toastify';
import * as Actions from './../actions/types';

const initialState = {
  resError: '',
  resSuccess: '',
  resWarning: '',
  itemResSuccess: '',
  itemResError: '',
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_RES_SUCCESS_MSG:
    case Actions.SET_ITEM_SUCESS_MSG: {
      toast.success(action.payload);
      return state;
    }
    case Actions.SET_RES_ERROR_MSG:
    case Actions.SET_ITEM_ERROR_MSG: {
      toast.error(action.payload);
      return state;
    }
    case Actions.SET_RES_WARNING_MSG: {
      toast.warning(action.payload);
      return state;
    }
    case Actions.CLEAR_RES_MSG:
      return initialState;
    default:
      return state;
  }
};
