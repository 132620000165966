import * as Actions from './types';

/**
 * @desc Set Debit Note Loader
 */
export const setDebitNoteLoader = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_LOADER,
    payload,
  };
};

/**
 * @desc Set Debit Note List Updated Flag
 */
export const setDebitNoteListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Debit Note List
 */
export const setDebitNoteList = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_LIST,
    payload,
  };
};

/**
 * @desc set Debit Notes Item
 */
export const setDebitNotesInvoiceItem = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_ITEM,
    payload,
  };
};

/**
 * @desc Update Debit Notes Invoice Item
 */
export const updateDebitNotesInvoiceItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_DEBIT_NOTES_ITEM,
    payload,
  };
};

/**
 * @desc set Selected Purchase Invoice Item
 */
export const setSelectedPurchaseInvoiceItem = (payload) => {
  return {
    type: Actions.SET_SELECTED_PURCHASE_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Clear Debit Notes Invoice data
 */
export const clearDebitNotesInvoiceData = () => {
  return {
    type: Actions.CLEAR_DEBIT_NOTES_INVOICE_ITEM,
  };
};

/**
 * @desc Set sales invoice preview
 */
export const setDebitNotesInvoicePreview = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_INVOICE_PREVIEW,
    payload,
  };
};

/**
 * @desc Clear Debit Note data
 */
export const clearDebitNoteData = (payload = {}) => {
  return {
    type: Actions.CLEAR_DEBIT_NOTES_DATA,
    payload,
  };
};

/**
 * @desc Set Invoice Items List(Product)
 */
export const setInvoiceProductsList = (payload) => {
  return {
    type: Actions.SET_INVOICE_PRODUCT_LIST,
    payload,
  };
};

/**
 * @desc update Debit Notes Filtered List
 */
export const updateDebitNotesFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_DEBIT_NOTES_FILTERED_LIST,
    payload,
  };
};
/**
 * @desc seet  new Debit Notes number
 */
export const setNewDebitNoteNumber = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTES_NEW_NUMBER,
    payload,
  };
};

/**
 * @desc Set due Debit Note List
 */
export const setDueDebitNote = (payload) => {
  return {
    type: Actions.SET_DUE_DEBIT_NOTES,
    payload,
  };
};

/**
 * @desc Get DEbit Note Tag Option
 */
export const setDebitTagOption = (payload) => {
  return {
    type: Actions.SET_DEBIT_NOTE_TAG_OPTIONS,
    payload,
  };
};

/**
 * @desc Get DEbit Note Tag Option
 */
export const setDueDebitNotesList = (payload) => {
  return {
    type: Actions.SET_DUE_DEBIT_NOTES_LIST,
    payload,
  };
};
