import { isEmpty } from '../helper/common';
import * as Actions from './types';

/**
 * @desc Set Reports Loader
 */
export const setReportsLoader = (payload) => {
  return {
    type: Actions.SET_REPORTS_LOADER,
    payload,
  };
};

/**
 * @desc Set trial balance
 */
export const setTrialBalance = (payload) => {
  return {
    type: Actions.SET_TRIAL_BALANCE,
    payload,
  };
};

/**
 * @desc Set general expense
 */
export const setGeneralExpense = (payload) => {
  return {
    type: Actions.SET_GENERAL_EXPENSE,
    payload,
  };
};

/**
 * @desc Set Inventory Items Summary
 */
export const setInventoryItemsSummary = (payload) => {
  return {
    type: Actions.SET_INVENTORY_ITEMS_SUMMARY,
    payload,
  };
};

/**
 * @desc Set Inventory Item Product Detail
 */
export const setInventoryItemDetails = (payload) => {
  return {
    type: Actions.SET_INVENTORY_ITEM_DETAILS,
    payload,
  };
};

/**
 * @desc Set Product Inventory Transactions
 */
export const setInventoryTransactions = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTIONS,
    payload,
  };
};

/**
 * @desc Set Sales By Product Details
 */
export const setSalesByProductDetails = (payload) => {
  return {
    type: Actions.SET_SALES_BY_PRODUCT_DETAILS,
    payload,
  };
};

/**
 * @desc Set Cash And Bank Details
 */
export const setCashAndBankDetails = (payload) => {
  return {
    type: Actions.SET_CASH_AND_BANK_DETAILS,
    payload,
  };
};

/**
 * @desc Set outstanding receivable
 */
export const setOutstandingReceivables = (payload) => {
  return {
    type: Actions.SET_OUTSTANDING_RECEIVABLES,
    payload,
  };
};

/**
 * @desc Set outstanding receivable details
 */
export const setOutstandingReceivableDetails = (payload) => {
  return {
    type: Actions.SET_OUTSTANDING_RECEIVABLES_DETAILS,
    payload,
  };
};

/**
 * @desc Set Outstanding invoice summary
 */
export const setOutstandingInvoiceSummary = (payload) => {
  return {
    type: Actions.SET_OUTSTANDING_INVOICE_SUMMARY,
    payload,
  };
};

/**
 * @desc Set sales Income by customer
 */
export const setIncomeByCustomer = (payload) => {
  return {
    type: Actions.SET_INCOME_BY_CUSTOMER,
    payload,
  };
};

/**
 * @desc Set Sales By SalesPerson
 */
export const setSalesBySalesPerson = (payload) => {
  return {
    type: Actions.SET_SALES_BY_SALESPERSON,
    payload,
  };
};

/**
 * @desc Set Sales By SalesPerson detail
 */
export const setSalesBySalesPersonDetails = (payload) => {
  return {
    type: Actions.SET_SALES_BY_SALESPERSON_DETAILS,
    payload,
  };
};

/**
 * @desc Set sales invoice summary
 */
export const setSalesInvoiceSummary = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_SUMMARY,
    payload,
  };
};

/**
 * @desc Set Purchase report
 */
export const setPurchasereport = (payload) => {
  return {
    type: Actions.SET_PURCHASE_REPORT,
    payload,
  };
};

/**
 * @desc Set Outstanding Payables
 */
export const setOutstandingPayables = (payload) => {
  return {
    type: Actions.SET_OUTSTANDING_PAYABLES,
    payload,
  };
};

/**
 * @desc Set Outstanding Payables details
 */
export const setOutstandingPayablesDetails = (payload) => {
  return {
    type: Actions.SET_OUTSTANDING_PAYABLES_DETAILS,
    payload,
  };
};

/**
 * @desc Set Payable Invoice Summary
 */
export const setPayableInvoiceSummary = (payload) => {
  return {
    type: Actions.SET_PAYABLE_INVOICE_SUMMARY,
    payload,
  };
};

/**
 * @desc Set Outcome By Supplier
 */
export const setOutcomeBySupplier = (payload) => {
  return {
    type: Actions.SET_OUTCOME_BY_SUPPLIER,
    payload,
  };
};

/**
 * @desc Set Customer/Supplier Ledger Summary
 */
export const setLedgerSummary = (payload) => {
  return {
    type: Actions.SET_LEDGER_SUMMARY,
    payload,
  };
};

/**
 * @desc Set Customer/Supplier Ledger Details
 */
export const setLedgerDetails = (payload) => {
  return {
    type: Actions.SET_LEDGER_DETAILS,
    payload,
  };
};

/**
 * @desc Set Purchase Invoice Summary
 */
export const setPurchaseInvoiceSummary = (payload) => {
  return {
    type: Actions.SET_PURCHASE_INVOICE_SUMMARY,
    payload,
  };
};

/**
 * @desc Set Journal Report
 */
export const setJournalReport = (payload) => {
  return {
    type: Actions.SET_JOURNAL_REPORT,
    payload,
  };
};

/**
 * @desc Clear Reports Data
 */
export const clearReportsData = () => {
  return {
    type: Actions.CLEAR_REPORTS_DATA,
  };
};

/**
 * @desc Set TDS payable report
 */
export const setTDSPayableDetails = (payload) => {
  return {
    type: Actions.SET_TDS_PAYABLE_DETAILS,
    payload,
  };
};

/**
 * @desc Set TCS payable report
 */
export const setTCSPayableDetails = (payload) => {
  return {
    type: Actions.SET_TCS_PAYABLE_DETAILS,
    payload,
  };
};

/**
 * @desc Set General Ledger Details report
 */
export const setGeneralLedgerDetails = (payload) => {
  return {
    type: Actions.SET_GENERAL_LEDGER_DETAILS,
    payload,
  };
};

/**
 *  @desc Set General Ledger Summary
 */
export const setGeneralLedgerSummary = (payload) => {
  return {
    type: Actions.SET_GENERAL_LEDGER_SUMMARY,
    payload,
  };
};

/**
 * @desc Set GST R1 Summary
 */
export const setGSTR1Summary = (payload) => {
  return {
    type: Actions.SET_GST_R1_SUMMARY,
    payload,
  };
};

/**
 * @desc Set GST R2 Summary
 */
export const setGSTR2Summary = (payload) => {
  return {
    type: Actions.SET_GST_R2_SUMMARY,
    payload,
  };
};

/**
 * @desc Set GST R2 Verification Report
 */
export const setGSTR2VerificationReport = (payload) => {
  return {
    type: Actions.SET_GST_R2_VERIFICATION_REPORT,
    payload,
  };
};

/**
 * @desc Set Sales By Items
 */
export const setSalesByItems = (payload) => {
  return {
    type: Actions.SET_SALES_BY_ITEMS,
    payload,
  };
};

/**
 * @desc Set Profit And Loss
 */
export const setProfitAndLoss = (payload) => {
  payload = getDataWithChilds(payload);
  return {
    type: Actions.SET_PROFIT_AND_LOSS,
    payload,
  };
};

/**
 * @desc Set Balance Sheet
 */
export const setBalanceSheet = (payload) => {
  payload = getDataWithChilds(payload, true);
  return {
    type: Actions.SET_BALANCE_SHEET,
    payload,
  };
};

/**
 * @desc Set Sales by contact
 */
export const setSalesByContact = (payload) => {
  return {
    type: Actions.SET_SALES_BY_CONTACT,
    payload,
  };
};

/**
 * @desc Set Sales by product
 */
export const setSalesByProduct = (payload) => {
  return {
    type: Actions.SET_SALES_BY_PRODUCT,
    payload,
  };
};

/**
 * @desc Set Warehouse stock data
 */
export const setWareHouseProductStock = (payload) => {
  return {
    type: Actions.SET_WAREHOUSE_PRODUCT_STOCK,
    payload,
  };
};

const getDataWithChilds = (payload = {}, isFromBalSheet) => {
  let { data } = payload;
  let { transactions } = data || {};

  let transaction2;
  if (!isFromBalSheet) transaction2 = data?.transaction2 || {};
  else transaction2 = data?.transactions2 || {};

  const maxTransactions = Math.max(transactions?.length, transaction2?.length);

  for (let i = 0; i < maxTransactions; i++) {
    const expenses = transactions?.[i]?.account_transactions || [];
    const incomes = transaction2?.[i]?.account_transactions || [];
    const expenseTrans = [];
    const incomeTrans = [];

    // Set Childs into account_transactions main list to show in list and pdf
    if (incomes?.length > 0) {
      incomes?.forEach((x) => {
        incomeTrans.push(x);
        if (x?.child_accounts?.length > 0) {
          x.hasChilds = true;
          x?.child_accounts?.forEach((y) => (y.isChild = true));
          incomeTrans.push(...x?.child_accounts);
        }
      });
    }
    if (expenses?.length > 0) {
      expenses?.forEach((x) => {
        expenseTrans.push(x);
        if (x?.child_accounts?.length > 0) {
          x.hasChilds = true;
          x?.child_accounts?.forEach((y) => (y.isChild = true));
          expenseTrans.push(...x?.child_accounts);
        }
      });
    }
    if (!isEmpty(data)) {
      if (data?.transactions?.length > i) data.transactions[i].account_transactions = expenseTrans;
      if (!isFromBalSheet && data?.transaction2?.length > i) data.transaction2[i].account_transactions = incomeTrans;
      else if (data?.transactions2?.length >= i) data.transactions2[i].account_transactions = incomeTrans;
    }
  }

  return payload;
};
