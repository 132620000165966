import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  filteredDataList: [],
  historyList: undefined,
};

export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_ACTIVITY_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_ACTIVITY_HISTORY_LIST:
      return {
        ...state,
        historyList: action.payload,
      };
    case Actions.UPDATE_HISTORY_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_ACTIVITY_DATA:
      return initialState;
    default:
      return state;
  }
};
