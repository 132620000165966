import { process } from '@progress/kendo-data-query';
import moment from 'moment';
import { ORDER_STATUS_ID, PRODUCT_STATUS } from '../global/constants';
import { groupBy, isEmpty } from '../helper/common';
import store from '../store/store';
import * as Actions from './types';

/**
 * @desc Set Order List Loader
 */
export const setOrderListLoader = (payload) => {
  return {
    type: Actions.SET_ORDER_LIST_LOADER,
    payload,
  };
};

/**
 * @desc Set Online Order List Updated Flag
 */
export const setOnlineOrderListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_ONLINE_ORDER_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Offline Order List Updated Flag
 */
export const setOfflineOrderListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_OFFLINE_ORDER_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set offline order List
 */
export const setOfflineOrderList = (payload) => {
  let allOrders = payload;
  if (payload && payload.length > 0) {
    allOrders = getAllAvailableOfflineOrders(payload);
  }
  return {
    type: Actions.SET_OFFLINE_ORDER_LIST,
    payload: {
      allOrders,
    },
  };
};

/**
 * @desc Set online order List
 */
export const setOnlineOrderList = (payload) => {
  let allOrders = payload;
  if (payload && payload.length > 0) {
    allOrders = getAllAvailableOrders(payload);
  }
  return {
    type: Actions.SET_ONLINE_ORDER_LIST,
    payload: {
      allOrders,
    },
  };
};

/**
 * @desc Set Filter Order List
 */
export const setFilteredSearchResult = (payload) => (dispatch) => {
  let allOrders = getAllAvailableOrders(payload);
  allOrders = allOrders?.map((order) => {
    let enableCheckbox = true;
    if (order.status < ORDER_STATUS_ID.MARK_AS_CONFIRMED) enableCheckbox = order?.isAllowMarkAsConfirm;
    else if (order.status === ORDER_STATUS_ID.MARK_AS_CONFIRMED)
      enableCheckbox = order?.isProductAvailableForBulkInvoice;
    else if (order.status < ORDER_STATUS_ID.READY_TO_SHIP) {
      if (order?.delhiver_status_history?.length > 0) {
        //Disable checkbox for bulk printing if Non-serviceable location
        let notServisableOrder = order?.delhiver_status_history?.findIndex(
          (x) => x.ScanDetail?.Instructions === 'Non-serviceable location',
        );
        if (notServisableOrder !== -1) {
          enableCheckbox = false;
          order.nonServiceable = true; //Set variable to enable change courier and tracking details
          if (!order.error) order.error = 'Non-serviceable location'; //Set error to display warning
        }
      }
    }

    return { ...order, enableCheckbox: enableCheckbox };
  });

  dispatch(setFilteredOrderList(allOrders));
};

/**
 * @desc Set Filter Order List
 */
export const setFilteredOrderList = (payload) => {
  if (payload && payload.length > 0) {
    // Check for orders status less than ready to ship if they achieved the benchmark by relative status
    payload = getIsBenchmarkAlert(payload);
  }
  return {
    type: Actions.SET_FILTERED_ORDER_LIST,
    payload,
  };
};

/**
 * @desc Set Filter Offline Order List
 */
export const setFilteredOfflineOrderList = (payload) => {
  if (payload && payload.length > 0) {
    // Check for orders status less than ready to ship if they achieved the benchmark by relative status
    payload = getIsOfflineBenchmarkAlert(payload);
  }
  return {
    type: Actions.SET_FILTERED_OFFLINE_ORDER_LIST,
    payload,
  };
};

/**
 * @desc update Order Filtered List
 */
export const updateOrderListFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_ORDER_LIST_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set next offline order Number
 */
export const setNextOfflineOrderNumber = (payload) => {
  return {
    type: Actions.SET_NEXT_OFFLINE_ORDER_NUMBER,
    payload,
  };
};

/**
 * @desc Update offline order Item
 */
export const updateOrderItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_ORDER_ITEM,
    payload,
  };
};

/**
 * @desc Update online order Item
 */
export const updateOnlineOrderItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_ONLINE_ORDER_ITEM,
    payload,
  };
};

/**
 * @desc Clear order item
 */
export const clearOrderItem = () => {
  return {
    type: Actions.CLEAR_ORDER_ITEM,
  };
};

/**
 * @desc Clear Online order item
 */
export const clearOnlineOrderItem = () => {
  return {
    type: Actions.CLEAR_ONLINE_ORDER_ITEM,
  };
};

/**
 * @desc Set sales order preview
 */
export const setSalesOrderPreview = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER_PREVIEW,
    payload,
  };
};

/**
 * @desc Set Loaded Entry Data
 */
export const setLoadedEntryData = (payload) => {
  return {
    type: Actions.SET_LOADED_ENTRY_DATA,
    payload,
  };
};

/**
 * @desc Set Channel List
 */
export const setChannelList = (payload) => {
  return {
    type: Actions.SET_CHANNEL_LIST,
    payload,
  };
};

/**
 * @desc Set Channel Item
 */
export const setChannelItem = (payload) => {
  return {
    type: Actions.SET_CHANNEL_ITEM,
    payload,
  };
};
/**
 * @desc Update Channel Item
 */
export const updateChannelItem = (payload) => {
  return {
    type: Actions.UPDATE_CHANNEL_ITEM,
    payload,
  };
};
/**
 * @desc Clear Channel Item
 */
export const clearChannelItem = (payload) => {
  return {
    type: Actions.CLEAR_CHANNEL_ITEM,
    payload,
  };
};
/**
 * @desc Set Next Order Number
 */
export const setNextOrderNumber = (payload) => {
  return {
    type: Actions.SET_NEXT_ORDER_NUMBER,
    payload,
  };
};

/**
 * @desc Set Couriers List
 */
export const setCouriersList = (payload) => {
  return {
    type: Actions.SET_COURIERS_LIST,
    payload,
  };
};
/**
 * @desc Set Couriers Item
 */
export const setCouriersItem = (payload) => {
  return {
    type: Actions.SET_COURIER_ITEM,
    payload,
  };
};
/**
 * @desc Update Couriers Item
 */
export const updateCouriersItem = (payload) => {
  return {
    type: Actions.UPDATE_COURIER_ITEM,
    payload,
  };
};
/**
 * @desc Clear Couriers Item
 */
export const clearCouriersItem = (payload) => {
  return {
    type: Actions.CLEAR_COURIER_ITEM,
    payload,
  };
};
/**
 * @desc Set Payment Gateway List
 */
export const setPaymentGatewayList = (payload) => {
  return {
    type: Actions.SET_PAYMENT_GATEWAY_LIST,
    payload,
  };
};

/**
 * @desc set online order preview
 */
export const setOnlineOrdersPreview = (payload) => {
  return {
    type: Actions.SET_ONLINE_ORDER_PREVIEW,
    payload,
  };
};

/**
 * @desc Set Offline Order preview
 */
export const setOfflineOrdersPreview = (payload) => {
  return {
    type: Actions.SET_OFFLINE_ORDER_PREVIEW,
    payload,
  };
};

/**
 * @desc Set Online Order Detail
 */
export const setOnlineOrderDetail = (payload) => {
  return {
    type: Actions.SET_ONLINE_ORDER_DETAIL,
    payload,
  };
};

/**
 * @desc Set Online Online PickUp List History
 */
export const setOnlinePickUpListHistory = (payload) => {
  return {
    type: Actions.SET_ONLINE_PICKUP_LIST_HISTORY,
    payload,
  };
};

/**
 * @desc Get Online Order Tag Option
 */
export const setOnlineOrderTagOption = (payload) => {
  return {
    type: Actions.SET_ONLINE_ORDER_TAG_OPTION,
    payload,
  };
};

/**
 * @desc Get Offline Order Detail
 */
export const setOfflineOrderDetail = (payload) => {
  return {
    type: Actions.SET_OFFLINE_ORDER_DETAIL,
    payload,
  };
};

/**
 * @desc Set Offline Order Items
 */
export const setOfflineOrderItems = (payload) => {
  return {
    type: Actions.SET_OFFLINE_ORDER_ITEMS,
    payload,
  };
};

/**
 * @desc Set Offline Order Items Row
 */
export const setOfflineOrderItemsRow = (payload) => {
  return {
    type: Actions.SET_OFFLINE_ORDER_ITEMS_ROW,
    payload,
  };
};

/**
 * @desc Set Pending Benchmark Percentage
 */
export const setPendingBenchmarkPercentage = (payload) => {
  return {
    type: Actions.SET_PENDING_BENCHMARK_PERCENTAGE,
    payload,
  };
};

/**
 * @desc Set Confirmed Benchmark Percentage
 */
export const setConfirmedBenchmarkPercentage = (payload) => {
  return {
    type: Actions.SET_CONFIRMED_BENCHMARK_PERCENTAGE,
    payload,
  };
};

/**
 * @desc Set Processed Benchmark Percentage
 */
export const setProcessedBenchmarkPercentage = (payload) => {
  return {
    type: Actions.SET_PROCESSED_BENCHMARK_PERCENTAGE,
    payload,
  };
};

/**
 * @desc Set Offline Pending Benchmark Percentage
 */
export const setOfflinePendingBenchmarkPercentage = (payload) => {
  return {
    type: Actions.SET_OFFLINE_PENDING_BENCHMARK_PERCENTAGE,
    payload,
  };
};

/**
 * @desc Set Online Order Call History
 */
export const setOnlineOrderCallHistory = (payload) => {
  return {
    type: Actions.SET_ONLINE_ORDER_CALL_HISTORY,
    payload,
  };
};

/**
 * @desc Clear order List data
 */
export const clearOrderListData = () => {
  return {
    type: Actions.CLEAR_ORDER_LIST_DATA,
  };
};

const getAllAvailableOrders = (payload) => {
  const state = store.getState();
  const { invoiceProducts = [] } = state.sales;
  let updatedList = payload;
  let allProductsList = [];

  // Get all Confirm Orders
  let confirmOrders = updatedList?.filter(
    (order) =>
      order?.status === ORDER_STATUS_ID.MARK_AS_CONFIRMED || order?.status === ORDER_STATUS_ID.PARTIALLY_PROCESSED,
  );

  //Give first priority to older orders
  const sorted_data = process(confirmOrders, {
    sort: [{ field: 'createdAt', dir: 'asc' }],
  });
  confirmOrders = sorted_data?.data;

  // Filter invoice products list by qty > 0 and filter by only items ordered in confirm orders.
  if (invoiceProducts && invoiceProducts?.length > 0) {
    const availableItemList = invoiceProducts?.filter((item) => item?.qty > 0);
    let allConfirmOrderItemIds = [];
    confirmOrders?.forEach((order) => order?.items?.forEach((item) => allConfirmOrderItemIds.push(item?.product?.id)));
    const uniquePendingOrderItemIds = allConfirmOrderItemIds?.filter((v, i, a) => a.indexOf(v) === i);
    const orderedItemList = availableItemList?.filter((product) => uniquePendingOrderItemIds?.includes(product?.id));
    allProductsList = JSON.parse(JSON.stringify(orderedItemList));
  }

  // check if all products available for confirmed orders to generate Invoice
  // if (tabIndex === ORDER_TAB_ID.CONFIRMED) {
  updatedList = updatedList?.map((order) => {
    let allProductAvailableForOrder = false;
    if (order?.items?.length > 0) {
      for (const item of order?.items) {
        if (allProductsList?.length > 0) {
          const isProductQtyAvailable =
            item?.qty <= allProductsList?.find((product) => product?.id === item?.product?.id)?.qty;
          if (isProductQtyAvailable) {
            allProductAvailableForOrder = true;
          } else {
            allProductAvailableForOrder = false;
            break;
          }
        }
      }
    }
    return {
      ...order,
      allProductAvailableForOrder,
    };
  });
  // }

  // Identify confirm orders which can be proceed with bulk Invoice creation
  confirmOrders = confirmOrders?.map((order, i) => {
    let isProductAvailableForBulkInvoice;
    for (const item of order?.items) {
      const productIndex = allProductsList?.findIndex((product) => product?.id === item?.product?.id);
      const isProuctAvailable = item?.qty <= allProductsList?.[productIndex]?.qty;
      if (productIndex !== -1 && isProuctAvailable) {
        isProductAvailableForBulkInvoice = true;
        allProductsList[productIndex].qty = allProductsList[productIndex].qty - item?.qty;
      } else {
        isProductAvailableForBulkInvoice = false;
        break;
      }
    }
    return {
      ...order,
      isProductAvailableForBulkInvoice: isProductAvailableForBulkInvoice,
    };
  });

  // Get all Ids of available orders for bulk invoice
  const availableConfirmOrdersIds = confirmOrders
    ?.filter((order) => order?.isProductAvailableForBulkInvoice)
    .map((order) => order?.id);

  // Update the list with the available orders for the bulk invoice
  updatedList = updatedList?.map((order) => {
    const isAvailable = availableConfirmOrdersIds?.includes(order?.id);
    return {
      ...order,
      isProductAvailableForBulkInvoice: isAvailable ? true : false,
    };
  });

  const pendingOrderStatus = [ORDER_STATUS_ID.OPEN, ORDER_STATUS_ID.IVR_APPROVED, ORDER_STATUS_ID.IVR_CANCELED];
  const pendingOrders = updatedList?.filter((order) => pendingOrderStatus.includes(order?.status));

  //Find Duplicate Orders
  const duplicateList = pendingOrders?.filter(
    (value, index, self) =>
      self.findIndex((x) => x?.contact?.id === value?.contact?.id && checkIfAllItemsAreSame(x?.items, value?.items)) !==
      index,
  );

  let allDuplicatesIds = [];
  let groupByCustomer = [];

  //Group order list by customer id and get all duplicate orders with same customer
  duplicateList?.forEach((item) => {
    allDuplicatesIds.push(item?.id);
    groupByCustomer = groupBy(pendingOrders, 'contact.id');
    const groupIndexByContact = groupByCustomer?.findIndex((group) => group?.id === item?.contact?.id);
    if (groupIndexByContact !== -1) {
      groupByCustomer[groupIndexByContact]?.orderItems?.forEach((order) => {
        const isItemsSame = checkIfAllItemsAreSame(item?.items, order?.items);
        if (isItemsSame) {
          allDuplicatesIds.push(order?.id);
        }
      });
    }
  });

  // Get all unique duplicates and set isDuplicate True or False
  allDuplicatesIds = allDuplicatesIds.filter((v, i, a) => a.indexOf(v) === i);

  updatedList = updatedList?.map((order) => {
    if (order?.status < ORDER_STATUS_ID.PROCESSED) {
      let isAllowMarkAsConfirm = true;
      order.items = order?.items?.map((item) => {
        const product = invoiceProducts?.find((x) => x?.id === item?.product?.id);
        if (product) {
          item.product = product;
          item.product.availableQty = parseInt(product?.qty || 0);
        }

        if (item?.product?.status === PRODUCT_STATUS.INACTIVE && item?.product?.qty < item?.qty)
          isAllowMarkAsConfirm = false;
        return item;
      });
      order.isAllowMarkAsConfirm = isAllowMarkAsConfirm;
    }
    if (order?.status <= ORDER_STATUS_ID.MARK_AS_CONFIRMED) {
      const isAmountZeroItem = order?.items?.filter((x) => x.amount <= 0)?.length > 0;
      if (isAmountZeroItem && !order.error) {
        order.error = 'Invalid item amount!';
      }
    }
    const isDuplicate = allDuplicatesIds?.includes(order?.id);
    return {
      ...order,
      isDuplicate: isDuplicate ? true : false,
    };
  });

  return updatedList;
};

const getIsBenchmarkAlert = (list) => {
  if (list?.length === 0) return [];
  const state = store.getState();
  const { companySettings } = state.setting;
  const { confirmed, processed, ready_to_ship } = companySettings?.benchmark?.online_orders || {};
  if (!companySettings?.benchmark?.online_orders) return list;

  let benchmarkVal;
  list = list?.map((x) => {
    if (x?.status < ORDER_STATUS_ID.READY_TO_SHIP && x?.status_updated_at && x?.status_change_history) {
      switch (x?.status) {
        case ORDER_STATUS_ID.OPEN:
          benchmarkVal = confirmed;
          break;
        case ORDER_STATUS_ID.MARK_AS_CONFIRMED:
        case ORDER_STATUS_ID.PARTIALLY_PROCESSED:
          benchmarkVal = processed;
          break;
        case ORDER_STATUS_ID.PROCESSED:
          benchmarkVal = ready_to_ship;
          break;

        default:
          break;
      }
      let isBenchMarkAlert = false;
      const statusUpdatedDate = moment(x?.status_updated_at);
      const todayDate = moment(new Date());
      const differenceInHours = todayDate.diff(statusUpdatedDate, 'hours');
      const benchmarkInHours = benchmarkVal * 24;
      isBenchMarkAlert = differenceInHours > benchmarkInHours;
      x.isBenchMarkAlert = isBenchMarkAlert;
    }
    return x;
  });
  return list;
};

const checkIfAllItemsAreSame = (itemsArr1, itemsArr2) => {
  if (itemsArr1?.length !== itemsArr2?.length) return false;
  let isAllSame;
  itemsArr1?.forEach((y, i) => {
    if (y?.product?.id === itemsArr2?.[i]?.product?.id && y?.qty === itemsArr2?.[i]?.qty) {
      isAllSame = true;
    } else {
      isAllSame = false;
      return isAllSame;
    }
  });
  return isAllSame;
};

const getIsOfflineBenchmarkAlert = (list) => {
  if (list?.length === 0) return [];
  const state = store.getState();
  const { companySettings } = state.setting;
  const { processed } = companySettings?.benchmark?.offline_orders || {};
  if (isEmpty(companySettings?.benchmark?.offline_orders)) return list;

  list = list?.map((x) => {
    if (x?.status < ORDER_STATUS_ID.PROCESSED && x?.status_updated_at && x?.status_change_history) {
      let isBenchMarkAlert = false;
      const statusUpdatedDate = moment(x?.status_updated_at);
      const todayDate = moment(new Date());
      const differenceInHours = todayDate.diff(statusUpdatedDate, 'hours');
      const benchmarkInHours = processed * 24;
      isBenchMarkAlert = differenceInHours > benchmarkInHours;
      x.isBenchMarkAlert = isBenchMarkAlert;
    }
    return x;
  });
  return list;
};

const getAllAvailableOfflineOrders = (payload) => {
  const state = store.getState();
  const { invoiceProducts = [] } = state.sales;
  let updatedList = payload;
  let allProductsList = [];

  // Get all Pending Orders
  let pendingOrders = updatedList?.filter((order) => order?.status === ORDER_STATUS_ID.OPEN);

  // Filter invoice products list by qty > 0 and filter by only items ordered in confirm orders.
  if (invoiceProducts && invoiceProducts?.length > 0) {
    const availableItemList = invoiceProducts?.filter((item) => item?.qty > 0);
    let allPendingOrderItemIds = [];
    pendingOrders?.forEach((order) => order?.items?.forEach((item) => allPendingOrderItemIds.push(item?.product?.id)));
    const uniquePendingOrderItemIds = allPendingOrderItemIds?.filter((v, i, a) => a.indexOf(v) === i);
    const orderedItemList = availableItemList?.filter((product) => uniquePendingOrderItemIds?.includes(product?.id));
    allProductsList = JSON.parse(JSON.stringify(orderedItemList));
  }

  // Commented this code as we need to enable invoice button even if all products are not available,
  // so we can make invoice of the available items
  // check if all products available for confirmed orders to generate Invoice
  // if (tabIndex === ORDER_TAB_ID.CONFIRMED) {
  // updatedList = updatedList?.map((order) => {
  //   let allProductAvailableForOrder = false;
  //   if (order?.items?.length > 0) {
  //     for (const item of order?.items) {
  //       if (allProductsList?.length > 0) {
  //         const isProductQtyAvailable =
  //           item?.qty <= allProductsList?.find((product) => product?.id === item?.product?.id)?.qty;
  //         if (isProductQtyAvailable) {
  //           allProductAvailableForOrder = true;
  //         } else {
  //           allProductAvailableForOrder = false;
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   return {
  //     ...order,
  //     allProductAvailableForOrder,
  //   };
  // });

  // Identify confirm orders which can be proceed with bulk Invoice creation
  pendingOrders = pendingOrders?.map((order, i) => {
    let isProductAvailableForBulkInvoice;
    for (const item of order?.items) {
      const productIndex = allProductsList?.findIndex((product) => product?.id === item?.product?.id);
      const isProuctAvailable = item?.qty <= allProductsList?.[productIndex]?.qty;
      if (productIndex !== -1 && isProuctAvailable) {
        isProductAvailableForBulkInvoice = true;
        allProductsList[productIndex].qty = allProductsList[productIndex].qty - item?.qty;
      } else {
        isProductAvailableForBulkInvoice = false;
        break;
      }
    }
    return {
      ...order,
      isProductAvailableForBulkInvoice: isProductAvailableForBulkInvoice,
    };
  });

  // Get all Ids of available orders for bulk invoice
  const availableConfirmOrdersIds = pendingOrders
    ?.filter((order) => order?.isProductAvailableForBulkInvoice)
    .map((order) => order?.id);

  // Update the list with the available orders for the bulk invoice
  updatedList = updatedList?.map((order) => {
    if (order?.status === ORDER_STATUS_ID.OPEN || order?.status === ORDER_STATUS_ID.PARTIALLY_PROCESSED) {
      order.items = order?.items?.map((item) => {
        const product = invoiceProducts?.find((x) => x?.id === item?.product?.id);
        if (product) {
          item.product = product;
          item.product.availableQty = parseInt(product?.qty || 0);
        }
        return item;
      });
    }
    order.total_qty = order?.items?.reduce((sum, val) => sum + Number(val?.qty || 0), 0);
    const isAvailable = availableConfirmOrdersIds?.includes(order?.id);
    return {
      ...order,
      isProductAvailableForBulkInvoice: isAvailable ? true : false,
    };
  });

  return updatedList;
};
