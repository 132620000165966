import axios from 'axios';
import {
  setSettingLoader,
  setUsersAndRolesList,
  setchartOfAccountsList,
  setCompanySettings,
  setChartOfAccountsDetails,
  setOpeningBalance,
  setCatalogueList,
  setCatalogueItem,
  setAnnouncementList,
  setAnnouncementItem,
  setLockingPeriodList,
  setLockingPeriodItem,
  setCompanyeInvoiceSettings,
  setMarketplaceItem,
  setMarketplaceList,
  setChartOfAccountCategoryList,
  setBrandList,
  setBrandItem,
  setCompanyExotelSettings,
  setCompanyShiprocketSettings,
  setCompanyDelhiverySettings,
  setCategoryList,
  setCategoryItem,
} from '../actions/settingActions';
import {
  clearResponseMessage,
  setErrorMessage,
  setItemErrorMessage,
  setSuccessMessage,
} from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/environment';
import { getAPIResponseError, isEmail, isEmpty } from '../helper/common';
import { setChannelItem } from '../actions/orderListActions';
import { setCouriersItem } from '../actions/orderListActions';
import store from '../store/store';
import { CHART_OF_ACCOUNT_TYPES, REQUEST_CANCEL_MESSAGE } from '../global/constants';

/**
 * @desc  Get Users And Roles list
 * @param {*} CompanyId
 */
export const getUsersAndRolesList = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/company/${company_id}`, { signal });
    const { data } = response.data;
    if (data) dispatch(setUsersAndRolesList({ users: data.users || [], invitedUsers: data.invited_users }));

    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Invite User
 * @param {*} CompanyId
 * @param {*} obj Data Obj
 */
export const inviteUsers = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    if (!obj) {
      dispatchSettingError('Email is Required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchSettingError('Email is Required', dispatch);
      return;
    } else if (obj.email && !isEmail(obj.email)) {
      dispatchSettingError('Invalid email address', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/company/${company_id}/invite`, obj);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Invite User
 * @param {*} CompanyId
 * @param {*} user_id
 * @param {*} obj Data Obj
 */
export const updateUserPermission = (company_id, user_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/company/${company_id}/user/${user_id}`, obj);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Delete Users
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteUser = (company_id, user_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/company/${company_id}/user/${user_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Chart Of Accounts list
 * @param {*} CompanyId
 */
export const getChartOfAccountsList =
  (company_id, isModalError, forceRefresh = false, signal) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearResponseMessage());
      const state = getState();
      const chartOfAccountsList = state?.setting?.chartOfAccountsList;
      if (chartOfAccountsList?.length > 0 && !forceRefresh) return true;
      dispatch(setSettingLoader(true));

      const response = await axios.get(`${REACT_APP_APIURL}/accounts/${company_id}`, { signal });

      const { data } = response.data;
      if (data) dispatch(setchartOfAccountsList(data || []));

      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchSettingError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Get Chart Of Accounts category list
 * @param {*} CompanyId
 */
export const getChartOfAccountCategoryList = (company_id, signal) => async (dispatch, getState) => {
  try {
    const state = getState();
    const chartOfAccountCategoryList = state?.setting?.chartOfAccountCategoryList;
    if (chartOfAccountCategoryList?.length > 0) return true;

    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/accounts/${company_id}/categories`, { signal });
    const { data } = response.data;
    if (data) {
      const categoryList = data.filter(
        (x) => x.type !== CHART_OF_ACCOUNT_TYPES.BANK && x.type !== CHART_OF_ACCOUNT_TYPES.CONTACTS,
      );
      dispatch(setChartOfAccountCategoryList(categoryList));
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Chart Of Accounts Details
 * @param {*} CompanyId,item_id
 */
export const getChartOfAccountsDetails = (company_id, item_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/accounts/${company_id}/item/${item_id}`, { signal });
    const { data } = response.data;
    if (data) dispatch(setChartOfAccountsDetails(data || {}));

    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - create Chart Of Accounts
 * @param {*} obj company_id Data Obj
 */
export const createChartOfAccounts = (company_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    if (!payload.category) {
      dispatchSettingError('Type is Required', dispatch, isModalError);
      return;
    } else if (!payload.name) {
      dispatchSettingError('Name is Required', dispatch, isModalError);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/accounts/${company_id}`, payload);
    if (response.data) return response?.data?.data;
    return false;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - update Chart Of Accounts
 * @param {*} obj company_id, item_id  Data Obj
 */
export const updateChartOfAccounts = (company_id, item_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    if (!payload) {
      dispatchSettingError('Name is Required', dispatch, isModalError);
      return;
    } else if (!payload.name) {
      dispatchSettingError('Name is Required', dispatch, isModalError);
      return;
    }
    const response = await axios.put(`${REACT_APP_APIURL}/accounts/${company_id}/item/${item_id}`, payload);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Delete Chart Of Accounts
 * @param {*} obj CompanyId , ItemId
 */
export const deleteChartOfAccounts = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/accounts/${company_id}/item/${item_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    console.log('ERROR', e);
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get company Settings
 * @param {*} CompanyId
 */
export const getCompanySettings = (company_id, isModalError, isRefresh, signal) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage());
    const state = getState();
    const companySettings = state?.setting?.companySettings;
    if (companySettings && !isRefresh) return;

    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/company/${company_id}/settings`, { signal });
    const { data } = response.data;
    if (data) dispatch(setCompanySettings(data || {}));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get company's integration einvoice Settings
 * @param {*} CompanyId
 */
export const getCompanyIntegrationSettings =
  (company_id, isModalError, isRefresh, signal) => async (dispatch, getState) => {
    try {
      dispatch(clearResponseMessage());
      const state = getState();
      const companySettings = state?.setting?.companySettings;
      if (companySettings && !isRefresh) return;

      dispatch(setSettingLoader(true));

      const response = await axios.get(`${REACT_APP_APIURL}/company/${company_id}/settings`, { signal });
      const { data } = response.data;
      if (data) {
        if (data?.eInvoice) dispatch(setCompanyeInvoiceSettings(data.eInvoice || {}));
        if (data?.exotel) dispatch(setCompanyExotelSettings(data.exotel || {}));
        if (data?.shiprocket) dispatch(setCompanyShiprocketSettings(data.shiprocket || {}));
        if (data?.delhivery) dispatch(setCompanyDelhiverySettings(data.delhivery || {}));
      }
      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchSettingError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc company - update company Setting
 * @param {*} obj company_id,  Data Obj
 */
export const updateCompanySettings = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/company/${company_id}/settings`, payload);
    const { data } = response.data;
    if (data) {
      dispatchSettingSuccess('Company settings updated', dispatch);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Opening Balance
 * @param {*} CompanyId
 */
export const getOpeningBalance = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/accounts/${company_id}/opening-balance`, { signal });
    const { data } = response.data;
    if (data) dispatch(setOpeningBalance(data || []));

    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Update Opening Balance
 * @param {*} CompanyId,obj
 */
export const updateOpeningBalance = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/accounts/${company_id}/opening-balance`, obj);
    if (response.data) {
      dispatchSettingSuccess(response.data.message, dispatch);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Catalogue List
 * @param {*} CompanyId
 */
export const getCatalogueList = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/values/catalogue`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setCatalogueList(data || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Catalogue Item
 * @param {*} CompanyId
 */
export const getCatalogueItem = (company_id, catalogueName, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/catalogue-contact/${company_id}/item/${catalogueName}`, {
      signal,
    });
    const { data } = response.data;
    if (data) {
      dispatch(setCatalogueItem(data || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Create or Update Catalogue Item
 * @param {*} CompanyId
 */
export const updateCatalogueAccess = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/catalogue-contact/${company_id}`, payload);
    const { data } = response.data;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};
/**
 * @desc  Get Channel Item
 * @param {*} CompanyId
 */
export const getChannelItem = (company_id, channelId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/channel/${company_id}/item/${channelId}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setChannelItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Channel Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create Channel
 * @param {*} obj company_id Data Obj
 */
export const createChannel =
  (company_id, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.post(`${REACT_APP_APIURL}/channel/${company_id}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to create Channel, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc company - update Channel
 * @param {*} obj company_id, courierId  Data Obj
 */
export const updateChannel =
  (company_id, courierId, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.put(`${REACT_APP_APIURL}/channel/${company_id}/item/${courierId}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to update channel, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Delete Channel
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteChannel = (company_id, courierId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/channel/${company_id}/item/${courierId}`);
    if (response.data) return true;
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete channel, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Courier Item
 * @param {*} CompanyId
 */
export const getCourierItem = (company_id, courierId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/courier/${company_id}/item/${courierId}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setCouriersItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Courier Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create Courier
 * @param {*} obj company_id Data Obj
 */
export const createCourrier =
  (company_id, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.post(`${REACT_APP_APIURL}/courier/${company_id}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to create Courier, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc company - update Courier
 * @param {*} obj company_id, courierId  Data Obj
 */
export const updateCourier =
  (company_id, courierId, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.put(`${REACT_APP_APIURL}/courier/${company_id}/item/${courierId}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to update courier, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Delete Courier
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteCourier = (company_id, courierId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/courier/${company_id}/item/${courierId}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete courier, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create Payment Gatway
 * @param {*} obj company_id Data Obj
 */
export const createPaymentGatway = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    if (!payload?.name) {
      dispatchSettingError('Name is Required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/payment-gateway/${company_id}`, payload);
    if (response.data) return true;
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Unable to create Payment Gatway, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - update Payment Gatway
 * @param {*} obj company_id, courierId  Data Obj
 */
export const updatePaymentGatway = (company_id, paymentGatWayId, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    if (!payload?.name) {
      dispatchSettingError('Name is Required', dispatch);
      return;
    }
    const response = await axios.put(
      `${REACT_APP_APIURL}/payment-gateway/${company_id}/item/${paymentGatWayId}`,
      payload,
    );
    if (response.data) return true;
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Unable to update Payment Gatway, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Delete Payment Gatway
 * @param {*} company_id CompanyId
 * @param {*} user_id paymentGatWayId
 */
export const deletePaymentGatway = (company_id, paymentGatWayId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/payment-gateway/${company_id}/item/${paymentGatWayId}`);
    if (response.data) return true;
  } catch (e) {
    dispatch(
      setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete Payment Gatway, please try again later'),
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Create Announcement
 * @param {*} obj company_id Data Obj
 */
export const createAnnouncement = (company_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setSettingLoader(true));
    if (!payload) {
      dispatchSettingError('Title is Required', dispatch, isModalError);
      return;
    } else if (!payload?.title) {
      dispatchSettingError('Title is Required', dispatch, isModalError);
      return;
    } else if (!payload?.type) {
      dispatchSettingError('Type is Required', dispatch, isModalError);
      return;
    } else if (!payload?.from_date) {
      dispatchSettingError('From date is Required', dispatch, isModalError);
      return;
    } else if (!payload?.to_date) {
      dispatchSettingError('To date is Required', dispatch, isModalError);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/announcements/${company_id}`, payload);
    if (response.data) return true;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to Create Announcement, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Announcement List
 * @param {*} CompanyId
 */
export const getAnnouncementList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const state = store.getState();
    const { announcementList } = state.setting;
    if (announcementList && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/announcements/${company_id}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setAnnouncementList(data || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Unable to get Announcement list, please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Announcement items Details
 * @param {*} obj CompanyId
 */
export const getAnnouncementItemDetails = (company_id, item_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/announcements/${company_id}/item/${item_id}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setAnnouncementItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Announcement Item, please try again',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};
/**
 * @desc  Update announcement item
 * @param {*} obj company_id, itemId
 */
export const updateAnnouncementItemDetails = (company_id, itemId, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    if (!payload) {
      dispatchSettingError('Title is Required', dispatch, isModalError);
      return;
    } else if (!payload?.title) {
      dispatchSettingError('Title is Required', dispatch, isModalError);
      return;
    } else if (!payload?.type) {
      dispatchSettingError('Type is Required', dispatch, isModalError);
      return;
    } else if (!payload?.from_date) {
      dispatchSettingError('From date is Required', dispatch, isModalError);
      return;
    } else if (!payload?.to_date) {
      dispatchSettingError('To date is Required', dispatch, isModalError);
      return;
    }
    const response = await axios.put(`${REACT_APP_APIURL}/announcements/${company_id}/item/${itemId}`, payload);
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to update announcement, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Delete Announcement
 * @param {*} obj CompanyId , ItemId
 */
export const deleteAnnouncementItem = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/announcements/${company_id}/item/${item_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Unable to delete announcement, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Locking Period List
 * @param {*} CompanyId
 */
export const getLockingPeriodList = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/locking-period/${company_id}/locking-period`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setLockingPeriodList(data || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Unable to get Locking Period list, please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc   Get Locking Period Item
 * @param {*} obj CompanyId
 */
export const getLockingPeriodItem = (company_id, item_id, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/locking-period/${company_id}/locking-period/${item_id}`, {
      signal,
    });
    const { data } = response.data;
    if (data) {
      dispatch(setLockingPeriodItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Locking Period Item, please try again',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Create Locking Period Item
 * @param {*} obj company_id Data Obj
 */
export const createLockingPeriodItem = (company_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/locking-period/${company_id}`, payload);
    if (response.data) return true;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to Create Locking Period Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Locking Period Item
 * @param {*} obj company_id Data Obj
 */
export const updateLockingPeriodItem = (company_id, item_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/locking-period/${company_id}/${item_id}`, payload);
    if (response.data) return true;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to Update Locking Period Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Delete Locking Period Item
 * @param {*} obj CompanyId , ItemId
 */
export const deleteLockingPeriodItem = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/locking-period/${company_id}/${item_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to delete Locking Period Item, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Marketplace List
 * @param {*} company_id company id
 */
export const getMarketplaceList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const state = store.getState();
    const { marketplaceList } = state.setting;
    if (!isRefresh && marketplaceList?.length > 0) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/e-com-partners/${company_id}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setMarketplaceList(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Marketplace Item
 * @param {*} CompanyId
 */
export const getMarketplaceItem = (company_id, marketplaceId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/e-com-partners/${company_id}/item/${marketplaceId}`, {
      signal,
    });
    const { data } = response.data;
    if (data) {
      dispatch(setMarketplaceItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Marketplace Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create Marketplace
 * @param {*} obj company_id Data Obj
 */
export const createMarketplace =
  (company_id, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.post(`${REACT_APP_APIURL}/e-com-partners/${company_id}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to create Marketplace, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc company - update Marketplace
 * @param {*} obj company_id, courierId  Data Obj
 */
export const updateMarketplace =
  (company_id, courierId, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.put(`${REACT_APP_APIURL}/e-com-partners/${company_id}/item/${courierId}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to update marketplace, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Delete Marketplace
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteMarketplace = (company_id, courierId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/e-com-partners/${company_id}/item/${courierId}`);
    if (response.data) return true;
  } catch (e) {
    dispatch(
      setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete marketplace, please try again later'),
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Brand List
 * @param {*} company_id company id
 */
export const getBrandList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));

    // const state = store.getState();
    // const { couriersList } = state.orderList;
    // if (couriersList && !isRefresh) return true;
    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/brands`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setBrandList(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create Brand
 * @param {*} obj company_id Data Obj
 */
export const createBrand =
  (company_id, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      } else if (!payload.phone) {
        dispatchSettingError('Phone is Required', dispatch, isModalError);
        return;
      } else if (payload.phone?.length !== 10) {
        dispatchSettingError('Enter valid phone number', dispatch, isModalError);
        return;
      } else if (!payload.email) {
        dispatchSettingError('Email is Required', dispatch, isModalError);
        return;
      } else if (!isEmail(payload.email)) {
        dispatchSettingError('Enter valid email', dispatch, isModalError);
        return;
      }
      const response = await axios.post(`${REACT_APP_APIURL}/brand/${company_id}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to create Brand, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Get Brand Item
 * @param {*} CompanyId
 */
export const getBrandItem = (company_id, brandId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/brand/${company_id}/item/${brandId}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setBrandItem(data || {}));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get Brand Item, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - update Brand
 * @param {*} obj company_id, brandId  Data Obj
 */
export const updateBrand =
  (company_id, brandId, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      } else if (!payload.phone) {
        dispatchSettingError('Phone is Required', dispatch, isModalError);
        return;
      } else if (payload.phone?.length !== 10) {
        dispatchSettingError('Enter valid phone number', dispatch, isModalError);
        return;
      } else if (!payload.email) {
        dispatchSettingError('Email is Required', dispatch, isModalError);
        return;
      } else if (!isEmail(payload.email)) {
        dispatchSettingError('Enter valid email', dispatch, isModalError);
        return;
      }
      const response = await axios.put(`${REACT_APP_APIURL}/brand/${company_id}/item/${brandId}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to update brand, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Delete Brand
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteBrand = (company_id, brandId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/brand/${company_id}/item/${brandId}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete brand, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - update company Setting
 * @param {*} obj company_id,  Data Obj
 */
export const updateEmailRecipientsCompanySettings = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setSettingLoader(true));
    const { rto_notifications, duplicate_invoices, minus_stocks } = payload;
    const rto_notifications_emails = rto_notifications.split(',');
    let error = false;
    rto_notifications_emails.forEach((email) => {
      if (!isEmail(email) && !isEmpty(email)) {
        dispatchSettingError('Email in RTO notifications is invalid.', dispatch);
        error = true;
      }
    });
    const duplicate_invoices_emails = duplicate_invoices.split(',');
    duplicate_invoices_emails.forEach((email) => {
      if (!isEmail(email) && !isEmpty(email)) {
        dispatchSettingError('Email in duplicate invoices is invalid.', dispatch);
        error = true;
      }
    });
    const minus_stocks_emails = minus_stocks.split(',');
    minus_stocks_emails.forEach((email) => {
      if (!isEmail(email) && !isEmpty(email)) {
        dispatchSettingError('Email in minus stocks is invalid.', dispatch);
        error = true;
      }
    });
    if (error) {
      return false;
    }
    const response = await dispatch(updateCompanySettings(company_id, { email_recipients: payload }));
    if (response) {
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Category List
 * @param {*} company_id company id
 */
export const getCategoryList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/category/${company_id}/categories`, { signal });
    const { data } = response.data;
    if (data) {
      const updatedData = data?.map((item) => {
        return { ...item, label: item?.name };
      });
      dispatch(setCategoryList(updatedData));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - Create category
 * @param {*} obj company_id Data Obj
 */
export const createCategory =
  (company_id, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      } else if (!payload.hsn_code) {
        dispatchSettingError('HSN Code is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.post(`${REACT_APP_APIURL}/category/${company_id}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to create Category, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc  Delete Category
 * @param {*} company_id CompanyId
 * @param {*} user_id userId
 */
export const deleteCategory = (company_id, categoryId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/category/${company_id}/item/${categoryId}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Unable to delete category, please try again later'));
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc  Get Category Item
 * @param {*} CompanyId
 */
export const getCategoryItem = (company_id, categoryId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/category/${company_id}/item/${categoryId}`, { signal });
    const { data } = response.data;
    if (data) {
      await dispatch(setCategoryItem(data || {}));
      return data;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return undefined;
    dispatchSettingError(
      getAPIResponseError(e) || 'Unable to get category Item, please try again later',
      dispatch,
      isModalError,
    );
    return undefined;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc company - update Category
 * @param {*} obj company_id, categoryId  Data Obj
 */
export const updateCategory =
  (company_id, categoryId, payload, isModalError = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return;
      dispatch(setSettingLoader(true));
      if (!payload.name) {
        dispatchSettingError('Name is Required', dispatch, isModalError);
        return;
      }
      const response = await axios.put(`${REACT_APP_APIURL}/category/${company_id}/item/${categoryId}`, payload);
      if (response.data) return true;
    } catch (e) {
      dispatchSettingError(
        getAPIResponseError(e) || 'Unable to update category, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

function dispatchSettingError(msg, dispatch, isModalError) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
function dispatchSettingSuccess(msg, dispatch, isModalError) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setSuccessMessage(msg));
}
