import * as Actions from './types';

/**
 * @desc Set Inventory Transaction Loader
 */
export const setInventoryTransactionLoader = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTION_LOADER,
    payload,
  };
};

/**
 * @desc Set Inventory Transaction List Updated Flag
 */
export const setInventoryTransactionListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTION_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Inventory Transaction List
 */
export const setInventoryTransactionList = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTION_LIST,
    payload,
  };
};

/**
 * @desc Set Update Inventory Transaction Details
 */
export const setUpdateInventoryTransactionDetails = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTION_DETAILS,
    payload,
  };
};

/**
 * @desc Clear Inventory Transaction Data
 */
export const clearInventoryTransactionData = () => {
  return {
    type: Actions.CLEAR_INVENTORY_TRANSACTION_DATA,
  };
};

/**
 * @desc Set Inventory Transaction By Item
 */
export const setInventoryTransactionByItem = (payload) => {
  return {
    type: Actions.SET_INVENTORY_TRANSACTION_BY_ITEM,
    payload,
  };
};

/**
 * @desc update Inventory Transaction Filtered List
 */
export const updateInventoryTransactionFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST,
    payload,
  };
};
