import * as Actions from '../actions/types';
import { INVOICE_TYPE } from '../global/constants';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  salesList: undefined,
  invoiceProducts: [],
  dueInvoiceList: undefined,
  filteredDataList: undefined,
  salesInvoiceNumber: undefined,
  invoicePaymentItem: {},
  invoice: {
    id: undefined,
    contact: undefined,
    number: '',
    date: new Date(),
    due_date: 0,
    billing_address: undefined,
    shipping_address: undefined,
    sales_person: undefined,
    courier_provider: undefined,
    tracking_no: '',
    items: [],
    total_qty: 0,
    net_amount: 0,
    total_discount: 0,
    additional_fields: [],
    total_amount: 0,
    notes: '',
    sales_order_id: undefined,
    total_tax: 0,
    tax_list: undefined,
    type: INVOICE_TYPE.SALE_INVOICE,
    reason: undefined,
    box_ids: [''],
  },
  salesItems: [],
  invoicePreview: undefined,
  salesInvoiceTagOptions: undefined,
  exportList: [],
};

export const salesReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_SALES_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_SALES_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_SALES_INVOICE_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        salesList: action.payload,
      };
    case Actions.UPDATE_FILTERED_DATA_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_INVOICE_PRODUCT_LIST:
      return {
        ...state,
        invoiceProducts: action.payload,
      };
    case Actions.UPDATE_INVOICE_PRODUCT_LIST_ITEM:
      let { invoiceProducts = [] } = state;
      const newList = JSON.parse(JSON.stringify(invoiceProducts));
      const updatedItems = action.payload.updated;
      const archivedItems = action.payload.archived;
      if (updatedItems?.length > 0) {
        updatedItems?.forEach((item) => {
          const itemIndex = newList?.findIndex((x) => x?.id === item?.id);
          if (itemIndex !== -1 && itemIndex !== undefined) newList[itemIndex] = item;
          else newList?.push(item);
        });
      }
      if (archivedItems?.length > 0) {
        archivedItems?.forEach((itemId) => {
          const itemIndex = newList?.findIndex((x) => x?.id === itemId);
          if (itemIndex !== -1 && itemIndex !== undefined) newList?.splice(itemIndex, 1);
        });
      }
      return {
        ...state,
        invoiceProducts: newList,
      };
    case Actions.SET_SALES_INVOICE_ITEM:
      return {
        ...state,
        invoice: action.payload,
      };
    case Actions.UPDATE_SALES_INVOICE_ITEM:
      return {
        ...state,
        invoice: { ...state.invoice, ...action.payload },
      };
    case Actions.CLEAR_SALES_INVOICE_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.invoice));
      const salesItems = JSON.parse(JSON.stringify(initialState.salesItems));
      return {
        ...state,
        salesItems,
        invoice: invoiceData,
      };
    case Actions.SET_SALES_INVOICE_ITEMS:
      return {
        ...state,
        salesItems: action.payload,
      };
    case Actions.SET_SALES_INVOICE_ITEMS_ROW:
      return {
        ...state,
        salesItems: state.salesItems.map((x, i) => {
          if (i === action.payload.index) return action.payload.item;
          return x;
        }),
      };
    case Actions.SET_SALES_INVOICE_PREVIEW:
      return {
        ...state,
        invoicePreview: action.payload,
      };
    case Actions.SET_INVOICE_NUMBER:
      return {
        ...state,
        invoice: { ...state.invoice, number: action.payload },
      };
    case Actions.SET_DUE_INVOICE_LIST:
      return {
        ...state,
        dueInvoiceList: action.payload,
      };
    case Actions.UPDATE_DUE_INVOICE_LIST:
      return {
        ...state,
        dueInvoiceList: [...state.dueInvoiceList, ...action.payload],
      };
    case Actions.SET_SALES_INVOICE_NUMBER_LIST:
      return {
        ...state,
        salesInvoiceNumber: action.payload,
      };
    case Actions.SET_INVOICE_PAYMENT_ITEM:
      return {
        ...state,
        invoicePaymentItem: action.payload,
      };
    case Actions.UPDATE_INVOICE_PAYMENT_ITEM:
      return {
        ...state,
        invoicePaymentItem: { ...state.invoicePaymentItem, ...action.payload },
      };
    case Actions.CLEAR_INVOICE_PAYMENT_ITEM:
      const invoicePaymentItem = JSON.parse(JSON.stringify(initialState.invoicePaymentItem));
      return {
        ...state,
        invoicePaymentItem: invoicePaymentItem,
      };
    case Actions.SET_SALES_INVOICE_TAG_OPTIONS:
      return {
        ...state,
        salesInvoiceTagOptions: action.payload,
      };
    case Actions.SET_EXPORT_LIST:
      return {
        ...state,
        exportList: action.payload,
      };
    case Actions.CLEAR_SALES_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
