import { isEmpty } from '../helper/common';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import * as Actions from './types';

// Set logged in user
export const setCurrentUser = (payload) => {
  const language = UserPreferenceSingleton.getInstance().getLanguage();
  if (!isEmpty(payload)) payload = { ...payload, language };
  return {
    type: Actions.SET_CURRENT_USER,
    payload: payload,
  };
};

// Set logged in user's profile
export const setCurrentUserProfile = (payload) => {
  return {
    type: Actions.SET_CURRENT_USER_PROFILE,
    payload: payload,
  };
};

/**
 * @desc Set Auth Validation Errors
 */
export const setAuthValidationErrors = (payload) => {
  return {
    type: Actions.GET_AUTH_VALIDATION_ERRORS,
    payload: payload,
  };
};

/**
 * @desc Set Auth Loader
 */
export const setAuthLoader = (payload) => {
  return {
    type: Actions.SET_AUTH_LOADER,
    payload: payload,
  };
};

/**
 * @desc Clear Auth Data
 */
export const clearAuthData = () => {
  return {
    type: Actions.CLEAR_AUTH_DATA,
  };
};
