import * as Actions from './types';

/**
 * @desc Set Dashboard Loader
 */
export const setDashboardLoader = (payload) => {
  return {
    type: Actions.SET_DASHBOARD_LOADER,
    payload,
  };
};

/**
 * @desc Set Best Sales Product List
 */
export const setBestSalesProductsList = (payload) => {
  return {
    type: Actions.SET_BEST_SALES_PRODUCTS_LIST,
    payload,
  };
};

/**
 * @desc Set Top Vendor List
 */
export const setTopVendorList = (payload) => {
  return {
    type: Actions.SET_TOP_VENDOR_LIST,
    payload,
  };
};
/**
 * @desc Set Channel Wise Sales List
 */
export const setChannelWiseSalesList = (payload) => {
  return {
    type: Actions.SET_CHANNEL_WISE_SALES_LIST,
    payload,
  };
};
/**
 * @desc Set Rto Count List
 */
export const setRtoCountList = (payload) => {
  return {
    type: Actions.SET_RTO_COUNT_LIST,
    payload,
  };
};
/**
 * @desc Set Rto Count List
 */
export const setSalesVolumeList = (payload) => {
  return {
    type: Actions.SET_SALES_VOLUME_LIST,
    payload,
  };
};
/**
 * @desc Set Profit And Loss Overview
 */
export const setProfitAndLossOverview = (payload) => {
  return {
    type: Actions.SET_PROFIT_AND_LOSS_OVERVIEW,
    payload,
  };
};
/**
 * @desc Set Active Announcement List
 */
export const setActiveAnnouncementList = (payload) => {
  return {
    type: Actions.SET_ACTIVE_ANNOUNCEMENT_LIST,
    payload,
  };
};

/**
 * @desc Set Active Announcement List
 */
export const clearDashboard = () => {
  return {
    type: Actions.CLEAR_DASHBOARD,
  };
};
