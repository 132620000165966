/* eslint-disable max-len */
import Dexie from 'dexie';
import moment from 'moment';
const DB_VERSION_NUMBER = 14;

export const PRODUCTS_TABLE_NAME = 'Products';
export const CONTACTS_TABLE_NAME = 'Contacts';
export const ONLINE_ORDERS_TABLE_NAME = 'OnlineOrders';
export const OFFLINE_ORDERS_TABLE_NAME = 'OfflineOrders';
export const INVENTORY_TRANSACTION_TABLE_NAME = 'InventoryTransactions';
export const JOURNAL_TRANSACTION_TABLE_NAME = 'JournalTransactions';
export const SALES_INVOICE_TABLE_NAME = 'SalesInvoice';
export const PURCHASE_INVOICE_TABLE_NAME = 'PurchaseInvoice';
export const PURCHASE_CHALLAN_TABLE_NAME = 'PurchaseChallan';
export const FORWARD_MANIFEST_TABLE_NAME = 'ForwardManifest';
export const RTO_MANIFEST_TABLE_NAME = 'RtoManifest';
export const WORKBOOK_TABLE_NAME = 'Workbooks';
export const SALES_ORDER_TABLE_NAME = 'SalesOrders';
export const DEBIT_NOTE_TABLE_NAME = 'DebitNotes';
export const CREDIT_NOTE_TABLE_NAME = 'CreditNotes';
export const CASH_AND_BANK_TABLE_NAME = 'cashAndBank';
export const UPDATED_ON_TABLE_NAME = 'UpdatedOn';

export const UPDATE_ON_ID = {
  PRODUCTS: 'PRODUCTS',
  CONTACTS: 'CONTACTS',
  ONLINE_ORDERS: 'ONLINE_ORDERS',
  OFFLINE_ORDERS: 'OFFLINE_ORDERS',
  INVENTORY_TRANSACTIONS: 'INVENTORY_TRANSACTIONS',
  SALES_INVOICES: 'SALES_INVOICES',
  PURCHASE_INVOICES: 'PURCHASE_INVOICES',
  PURCHASE_CHALLANS: 'PURCHASE_CHALLANS',
  FORWARD_MANIFEST: 'FORWARD_MANIFEST',
  RTO_MANIFEST: 'RTO_MANIFEST',
  WORKBOOKS: 'WORKBOOKS',
  SALES_ORDERS: 'SALES_ORDERS',
  DEBIT_NOTES: 'DEBIT_NOTES',
  CREDIT_NOTE: 'CREDIT_NOTE',
  CASH_AND_BANK: 'CASH_AND_BANK',
  JOURNAL_TRANSACTIONS: 'JOURNAL_TRANSACTIONS',
};

export default class LocalDbService {
  constructor(props) {
    this.props = props;
    const db_name = props.dbName;
    this.db = new Dexie(db_name);
    const storeOptions = {};
    storeOptions[PRODUCTS_TABLE_NAME] = '&id,status,createdAt,value';
    storeOptions[CONTACTS_TABLE_NAME] = '&id,contact_type,createdAt,value';
    storeOptions[ONLINE_ORDERS_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[OFFLINE_ORDERS_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[INVENTORY_TRANSACTION_TABLE_NAME] = '&id,type,date,createdAt,value';
    storeOptions[JOURNAL_TRANSACTION_TABLE_NAME] = '&id,date,createdAt,value';
    storeOptions[SALES_INVOICE_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[PURCHASE_INVOICE_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[PURCHASE_CHALLAN_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[FORWARD_MANIFEST_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[RTO_MANIFEST_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[WORKBOOK_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[SALES_ORDER_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[DEBIT_NOTE_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[CREDIT_NOTE_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[CASH_AND_BANK_TABLE_NAME] = '&id,status,date,createdAt,value';
    storeOptions[UPDATED_ON_TABLE_NAME] = '&id,createdAt,value';
    this.db
      .version(DB_VERSION_NUMBER)
      .stores(storeOptions)
      .upgrade((trans) => {
        if (DB_VERSION_NUMBER === 11) {
          const clearDbData = async () => {
            await this.clearDbData();
            window.location.reload();
          };
          clearDbData();
        }
      });
  }

  getDb() {
    const tableName = this.props.tableName;
    if (this.db) return this.db[tableName];
  }

  closeDb() {
    if (this.db) {
      this.db.close();
      this.db = undefined;
    }
  }

  getMappedValues(list) {
    const dbItems = list?.map((x) => {
      const item = {
        id: x.id,
        createdAt: x.createdAt,
        value: x,
      };
      if (x.status) item.status = x.status;
      if (x.date) item.date = x.date;

      if (this.props.tableName === CONTACTS_TABLE_NAME && x.contact_type) item.contact_type = x.contact_type;
      else if (this.props.tableName === INVENTORY_TRANSACTION_TABLE_NAME && x.type) item.type = x.type;

      return item;
    });
    return dbItems;
  }

  async get() {
    if (this.db) {
      const rows = await this.getDb().toArray();
      const result = rows.map((x) => x.value).filter((x) => x);
      return result;
    }
    return [];
  }

  async getItem(id) {
    if (this.db) {
      const rows = await this.getDb().where({ id: id }).limit(1).toArray();
      if (rows && rows.length > 0) {
        const result = rows.map((x) => x.value).filter((x) => x);
        return result[0];
      }
    }
    return null;
  }

  async clearDbData() {
    if (this.db) {
      await this.getDb().clear();
      await this.db[PRODUCTS_TABLE_NAME].clear();
      await this.db[CONTACTS_TABLE_NAME].clear();
      await this.db[ONLINE_ORDERS_TABLE_NAME].clear();
      await this.db[OFFLINE_ORDERS_TABLE_NAME].clear();
      await this.db[INVENTORY_TRANSACTION_TABLE_NAME].clear();
      await this.db[JOURNAL_TRANSACTION_TABLE_NAME].clear();
      await this.db[SALES_INVOICE_TABLE_NAME].clear();
      await this.db[PURCHASE_INVOICE_TABLE_NAME].clear();
      await this.db[PURCHASE_CHALLAN_TABLE_NAME].clear();
      await this.db[FORWARD_MANIFEST_TABLE_NAME].clear();
      await this.db[RTO_MANIFEST_TABLE_NAME].clear();
      await this.db[WORKBOOK_TABLE_NAME].clear();
      await this.db[SALES_ORDER_TABLE_NAME].clear();
      await this.db[DEBIT_NOTE_TABLE_NAME].clear();
      await this.db[CREDIT_NOTE_TABLE_NAME].clear();
      await this.db[CASH_AND_BANK_TABLE_NAME].clear();
      await this.db[UPDATED_ON_TABLE_NAME].clear();
      return true;
    }
    return false;
  }

  async addBulk(items) {
    if (this.db) {
      const result = await this.getDb().bulkPut(this.getMappedValues(items));
      return result;
    }
    return false;
  }

  async removeBulk(items) {
    if (this.db) {
      const result = await this.getDb().bulkDelete(items);
      return result;
    }
    return false;
  }

  async updateExisting(id, value) {
    if (value && this.db) {
      const dbItem = {
        id: id,
        tableId: value.tableId,
        value: value,
      };
      const result = await this.getDb().update(id, dbItem);
      return result;
    }
    return null;
  }

  async getLastUpdatedTime(id) {
    try {
      if (this.db) {
        const first = await this.db[UPDATED_ON_TABLE_NAME].where('id').equals(id).first();
        return first?.value || 0;
      }
      return 0;
    } catch (e) {
      return 0;
    }
  }

  async setLastUpdatedTime(id, value) {
    if (value && this.db) {
      const result = await this.db[UPDATED_ON_TABLE_NAME].put({ id: id, value: value });
      return result;
    }
    return null;
  }

  async syncList(newList, archivedList) {
    if (this.db) {
      //Add/update list only if have list of new/archived
      if (newList && newList.length > 0) {
        await this.addBulk(newList);
      }
      if (archivedList && archivedList.length > 0) {
        await this.removeBulk(archivedList);
      }
    }
  }

  async clearOlderThanThreeMonthsLocalData(statusArr) {
    const daysToClearOlderData = 90;
    const dateBeforeNDays = new Date(moment().subtract(daysToClearOlderData, 'days')).toISOString();
    await this.getDb()
      .where('date')
      .belowOrEqual(dateBeforeNDays)
      .and((item) => (statusArr?.length === 0 ? true : statusArr?.includes(item?.status)))
      .delete();
  }
}
