import { isEmpty } from '../helper/common';
import store from '../store/store';
import * as Actions from './types';

/**
 * @desc Set Setting Loader
 */
export const setSettingLoader = (payload) => {
  return {
    type: Actions.SET_SETTING_LOADER,
    payload,
  };
};

/**
 * @desc Set Users And Roles List
 */
export const setUsersAndRolesList = (payload) => {
  const userAndRolesList = payload.users;
  const invitedUsers = payload.invitedUsers;
  const state = store.getState();
  const auth = state.auth;
  const currentUser = auth.user;
  let userRoles;
  if (!isEmpty(currentUser) && userAndRolesList && userAndRolesList.length > 0) {
    userRoles = userAndRolesList.find((x) => x.id === currentUser.id);
  }
  const userRolesPayload = {
    userAndRolesList,
    userRoles,
    invitedUsers,
  };
  return {
    type: Actions.SET_USERS_AND_ROLES_LIST,
    payload: userRolesPayload,
  };
};

/**
 * @desc Clear Users And Roles Data
 */
export const clearUserAndRoles = () => {
  return {
    type: Actions.CLEAR_USERS_AND_ROLES_DATA,
  };
};

/**
 * @desc Set Chart Of Accounts List
 */
export const setchartOfAccountsList = (payload) => {
  return {
    type: Actions.SET_CHART_OF_ACCOUNTS_LIST,
    payload,
  };
};

/**
 * @desc Set Chart Of Accounts Details
 */
export const setChartOfAccountsDetails = (payload) => {
  return {
    type: Actions.SET_CHART_OF_ACCOUNTS_DETAILS,
    payload,
  };
};

/**
 * @desc Set Chart Of Accounts category list
 */
export const setChartOfAccountCategoryList = (payload) => {
  return {
    type: Actions.SET_CHART_OF_ACCOUNTS_CATEGORY_LIST,
    payload,
  };
};

/**
 * @desc Set company Setting
 */
export const setCompanySettings = (payload) => {
  return {
    type: Actions.SET_COMPANY_SETTINGS,
    payload,
  };
};

/**
 * @desc Set company Setting
 */
export const setCompanyeInvoiceSettings = (payload) => {
  return {
    type: Actions.SET_COMPANY_E_INVOICE_SETTINGS,
    payload,
  };
};

/**
 * @desc Set company Setting
 */
export const setCompanyExotelSettings = (payload) => {
  return {
    type: Actions.SET_COMPANY_EXOTEL_SETTINGS,
    payload,
  };
};

/**
 * @desc Set company Setting
 */
export const setCompanyShiprocketSettings = (payload) => {
  return {
    type: Actions.SET_COMPANY_SHIPROCKET_SETTINGS,
    payload,
  };
};

/**
 * @desc Set company Setting
 */
export const setCompanyDelhiverySettings = (payload) => {
  return {
    type: Actions.SET_COMPANY_DELHIVERY_SETTINGS,
    payload,
  };
};

/**
 * @desc Set Opening Balance
 */
export const setOpeningBalance = (payload) => {
  return {
    type: Actions.SET_OPENING_BALANCE,
    payload,
  };
};

/**
 * @desc Update Opening Balance Item
 */
export const updateOpeningBalanceItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_OPENING_BALANCE,
    payload,
  };
};

/**
 * @desc Set Catalogue List
 */
export const setCatalogueList = (payload) => {
  return {
    type: Actions.SET_CATALOGUE_LIST,
    payload,
  };
};

/**
 * @desc Set Catalogue Filter List
 */
export const setFilteredCatalogueList = (payload) => {
  return {
    type: Actions.SET_FILTERED_CATALOGUE_LIST,
    payload,
  };
};

/**
 * @desc Set Catalogue Item
 */
export const setCatalogueItem = (payload) => {
  return {
    type: Actions.SET_CATALOGUE_ITEM,
    payload,
  };
};

/**
 * @desc Clear Catalogue Item
 */
export const clearCatalogueItem = (payload) => {
  return {
    type: Actions.CLEAR_CATALOGUE_ITEM,
    payload,
  };
};

/**
 * @desc Set Announcement List
 */
export const setAnnouncementList = (payload) => {
  return {
    type: Actions.SET_ANNOUNCEMENT_LIST,
    payload,
  };
};

/**
 * @desc Set Announcement Filtered List
 */
export const setAnnouncementFilteredList = (payload) => {
  return {
    type: Actions.SET_ANNOUNCEMENT_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set Announcement Item
 */
export const setAnnouncementItem = (payload) => {
  return {
    type: Actions.SET_ANNOUNCEMENT_ITEM,
    payload,
  };
};

/**
 * @desc Clear Announcement Item
 */
export const clearAnnouncementItem = (payload) => {
  return {
    type: Actions.CLEAR_ANNOUNCEMENT_ITEM,
    payload,
  };
};

/**
 * @desc Set Locking Period List
 */
export const setLockingPeriodList = (payload) => {
  return {
    type: Actions.SET_LOCKING_PERIOD_LIST,
    payload,
  };
};

/**
 * @desc Set Locking Period Filtered List
 */
export const setLockingPeriodFilteredList = (payload) => {
  return {
    type: Actions.SET_LOCKING_PERIOD_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set Locking Period Item
 */
export const setLockingPeriodItem = (payload) => {
  return {
    type: Actions.SET_LOCKING_PERIOD_ITEM,
    payload,
  };
};

/**
 * @desc Clear Locking Period Item
 */
export const clearLockingPeriodItem = (payload) => {
  return {
    type: Actions.CLEAR_LOCKING_PERIOD_ITEM,
    payload,
  };
};

/**
 * @desc Set Marketplace List
 */
export const setMarketplaceList = (payload) => {
  return {
    type: Actions.SET_MARKETPLACE_LIST,
    payload,
  };
};

/**
 * @desc Set Marketplace Item
 */
export const setMarketplaceItem = (payload) => {
  return {
    type: Actions.SET_MARKETPLACE_ITEM,
    payload,
  };
};

/**
 * @desc update Marketplace Item
 */
export const updateMarketplaceItem = (payload) => {
  return {
    type: Actions.UPDATE_MARKETPLACE_ITEM,
    payload,
  };
};

/**
 * @desc clear Marketplace Item
 */
export const clearMarketplaceList = (payload) => {
  return {
    type: Actions.CLEAR_MARKETPLACE_LIST,
    payload,
  };
};

/**
 * @desc clear Marketplace Item
 */
export const clearMarketplaceItem = (payload) => {
  return {
    type: Actions.CLEAR_MARKETPLACE_ITEM,
    payload,
  };
};

/**
 * @desc Set Brand List
 */
export const setBrandList = (payload) => {
  return {
    type: Actions.SET_BRAND_LIST,
    payload,
  };
};

/**
 * @desc Clear Brand Item
 */
export const clearBrandItem = (payload) => {
  return {
    type: Actions.CLEAR_BRAND_ITEM,
    payload,
  };
};

/**
 * @desc Update Brand Item
 */
export const updateBrandItem = (payload) => {
  return {
    type: Actions.UPDATE_BRAND_ITEM,
    payload,
  };
};

/**
 * @desc Set Couriers Item
 */
export const setBrandItem = (payload) => {
  return {
    type: Actions.SET_BRAND_ITEM,
    payload,
  };
};

/**
 * @desc Set Couriers Item
 */
export const setCategoryList = (payload) => {
  return {
    type: Actions.SET_CATEGORY_LIST,
    payload,
  };
};

/**
 * @desc Set Couriers Item
 */
export const setCategoryItem = (payload) => {
  return {
    type: Actions.SET_CATEGORY_ITEM,
    payload,
  };
};

/**
 * @desc Set Couriers Item
 */
export const updateCategoryItem = (payload) => {
  return {
    type: Actions.UPDATE_CATEGORY_ITEM,
    payload,
  };
};

/**
 * @desc Set Couriers Item
 */
export const clearCategoryItem = (payload) => {
  return {
    type: Actions.CLEAR_CATEGORY_ITEM,
    payload,
  };
};
