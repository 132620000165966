import React, { Component, Suspense } from 'react';
import styled from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { createBrowserHistory } from 'history';
import routes from './routes/routes-without-navbar';
import store from './store/store';
import { isDev } from './helper/common';
import PrivateRoutes from './components/PrivateRoutes';
import { setupToken } from './helper/authTokenHelpers';
import { setCurrentUser } from './actions/authActions';
import { loadRequiredData } from './services/baseService';
import { APP_INIT_RESPONSE_TYPE } from './global/constants';
import myImage from './assets/images/svg/pageloader.svg';
import RealtimeService from './services/realtimeService';
import UserPreferenceSingleton from './helper/UserPreferenceSingleton';
import { initAnalytics } from './services/analyticsService';

const PageLoading = styled.div`
  background-image: url(${myImage});
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const history = createBrowserHistory();

const token = setupToken();
if (token) {
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
}

initAnalytics();

let hidden = 'hidden';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  onChangePageVisibility = async (evt) => {
    if (document.hidden) {
      RealtimeService.getInstance().disconnect();
    } else {
      const state = store.getState();
      const user = state.auth.user;
      if (user) {
        const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
        if (companyId) {
          await store.dispatch(RealtimeService.getInstance().init(user.id, companyId));
        }
      }
    }
  };

  initPageBlur = () => {
    if (hidden in document) document.addEventListener('visibilitychange', this.onChangePageVisibility);
    else if ((hidden = 'mozHidden') in document)
      document.addEventListener('mozvisibilitychange', this.onChangePageVisibility);
    else if ((hidden = 'webkitHidden') in document)
      document.addEventListener('webkitvisibilitychange', this.onChangePageVisibility);
    else if ((hidden = 'msHidden') in document)
      document.addEventListener('msvisibilitychange', this.onChangePageVisibility);
    // IE 9 and lower:
    else if ('onfocusin' in document) document.onfocusin = document.onfocusout = this.onChangePageVisibility;
    // All others:
    else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.onChangePageVisibility;
  };

  componentDidMount() {
    if (isDev()) {
      console.log('Development');
    }
    this.loadData();
    window.addEventListener('keypress', (e) => {
      if (e.keyCode === 12) {
        history.push(`/challan`);
      }
    });
    this.initPageBlur();
  }

  loadData = async () => {
    try {
      if (token) {
        const appInitResult = await store.dispatch(loadRequiredData());
        if (appInitResult && appInitResult.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          return history.push(appInitResult.path);
        }
      }
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <PageLoading />;
    }
    const routeComponents = routes.map((r, i) => {
      if (r.private) {
        return <PrivateRoutes key={i} {...r} />;
      } else {
        return <Route key={i} {...r} />;
      }
    });
    return (
      <>
        <Provider store={store}>
          <Suspense fallback={<PageLoading />}>
            <Router history={history}>
              <Switch>
                {routeComponents}
                <Route render={() => <Redirect to={{ pathname: '/' }} />} />
              </Switch>
            </Router>
          </Suspense>
        </Provider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </>
    );
  }
}
export default App;
