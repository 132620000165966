import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  trialBalance: undefined,
  generalExpense: undefined,
  inventoryItemsSummary: undefined,
  inventoryItemDetails: undefined,
  inventoryTransactions: undefined,
  salesByProductDetails: undefined,
  bankDetails: undefined,
  outstandingReceivables: undefined,
  outstandingReceivablesDetails: undefined,
  outstandingInvoiceSummary: undefined,
  incomeByCustomer: undefined,
  salesBySalesPerson: undefined,
  salesBySalesPersonDetails: undefined,
  salesInvoiceSummary: undefined,
  purchaseReport: undefined,
  outstandingPayables: undefined,
  outstandingPayablesDetails: undefined,
  payableInvoiceSummary: undefined,
  purchaseInvoiceSummary: undefined,
  outcomeBySupplier: undefined,
  ledgerSummary: undefined,
  ledgerDetails: undefined,
  journalReport: undefined,
  TDSPayableDetails: undefined,
  TCSPayableDetails: undefined,
  generalLedgerDetails: undefined,
  generalLedgerSummary: undefined,
  gstR1Summary: undefined,
  gstR2Summary: undefined,
  salesByItems: undefined,
  profitAndLoss: undefined,
  balanceSheet: undefined,
  gstR2VerificationReport: undefined,
  warehouseProductStock: undefined,
  salesByContact: undefined,
  salesByProduct: undefined,
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_REPORTS_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_TRIAL_BALANCE:
      return {
        ...state,
        trialBalance: action.payload,
      };
    case Actions.SET_GENERAL_EXPENSE:
      return {
        ...state,
        generalExpense: action.payload,
      };
    case Actions.SET_INVENTORY_ITEMS_SUMMARY:
      return {
        ...state,
        inventoryItemsSummary: action.payload,
      };
    case Actions.SET_INVENTORY_ITEM_DETAILS:
      return {
        ...state,
        inventoryItemDetails: action.payload,
      };
    case Actions.SET_INVENTORY_TRANSACTIONS:
      return {
        ...state,
        inventoryTransactions: action.payload,
      };
    case Actions.SET_SALES_BY_PRODUCT_DETAILS:
      return {
        ...state,
        salesByProductDetails: action.payload,
      };
    case Actions.SET_CASH_AND_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case Actions.SET_OUTSTANDING_RECEIVABLES:
      return {
        ...state,
        outstandingReceivables: action.payload,
      };
    case Actions.SET_OUTSTANDING_RECEIVABLES_DETAILS:
      return {
        ...state,
        outstandingReceivablesDetails: action.payload,
      };

    case Actions.SET_OUTSTANDING_INVOICE_SUMMARY:
      return {
        ...state,
        outstandingInvoiceSummary: action.payload,
      };
    case Actions.SET_INCOME_BY_CUSTOMER:
      return {
        ...state,
        incomeByCustomer: action.payload,
      };
    case Actions.SET_SALES_BY_SALESPERSON:
      return {
        ...state,
        salesBySalesPerson: action.payload,
      };
    case Actions.SET_SALES_BY_SALESPERSON_DETAILS:
      return {
        ...state,
        salesBySalesPersonDetails: action.payload,
      };
    case Actions.SET_SALES_INVOICE_SUMMARY:
      return {
        ...state,
        salesInvoiceSummary: action.payload,
      };
    case Actions.SET_PURCHASE_REPORT:
      return {
        ...state,
        purchaseReport: action.payload,
      };
    case Actions.SET_OUTSTANDING_PAYABLES:
      return {
        ...state,
        outstandingPayables: action.payload,
      };
    case Actions.SET_OUTSTANDING_PAYABLES_DETAILS:
      return {
        ...state,
        outstandingPayablesDetails: action.payload,
      };
    case Actions.SET_PAYABLE_INVOICE_SUMMARY:
      return {
        ...state,
        payableInvoiceSummary: action.payload,
      };
    case Actions.SET_OUTCOME_BY_SUPPLIER:
      return {
        ...state,
        outcomeBySupplier: action.payload,
      };
    case Actions.SET_LEDGER_SUMMARY:
      return {
        ...state,
        ledgerSummary: action.payload,
      };
    case Actions.SET_LEDGER_DETAILS:
      return {
        ...state,
        ledgerDetails: action.payload,
      };
    case Actions.SET_PURCHASE_INVOICE_SUMMARY:
      return {
        ...state,
        purchaseInvoiceSummary: action.payload,
      };
    case Actions.SET_JOURNAL_REPORT:
      return {
        ...state,
        journalReport: action.payload,
      };
    case Actions.SET_TDS_PAYABLE_DETAILS:
      return {
        ...state,
        TDSPayableDetails: action.payload,
      };
    case Actions.SET_TCS_PAYABLE_DETAILS:
      return {
        ...state,
        TCSPayableDetails: action.payload,
      };
    case Actions.SET_GENERAL_LEDGER_DETAILS:
      return {
        ...state,
        generalLedgerDetails: action.payload,
      };
    case Actions.SET_GENERAL_LEDGER_SUMMARY:
      return {
        ...state,
        generalLedgerSummary: action.payload,
      };
    case Actions.SET_GST_R1_SUMMARY:
      return {
        ...state,
        gstR1Summary: action.payload,
      };
    case Actions.SET_GST_R2_SUMMARY:
      return {
        ...state,
        gstR2Summary: action.payload,
      };
    case Actions.SET_GST_R2_VERIFICATION_REPORT:
      return {
        ...state,
        gstR2VerificationReport: action.payload,
      };
    case Actions.SET_SALES_BY_ITEMS:
      return {
        ...state,
        salesByItems: action.payload,
      };
    case Actions.SET_PROFIT_AND_LOSS:
      return {
        ...state,
        profitAndLoss: action.payload,
      };
    case Actions.SET_BALANCE_SHEET:
      return {
        ...state,
        balanceSheet: action.payload,
      };
    case Actions.CLEAR_REPORTS_DATA:
      return initialState;
    case Actions.SET_WAREHOUSE_PRODUCT_STOCK:
      return {
        ...state,
        warehouseProductStock: action.payload,
      };
    case Actions.SET_SALES_BY_CONTACT:
      return {
        ...state,
        salesByContact: action.payload,
      };
    case Actions.SET_SALES_BY_PRODUCT:
      return {
        ...state,
        salesByProduct: action.payload,
      };
    default:
      return state;
  }
};
