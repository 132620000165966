import OneSignal from 'react-onesignal';
import { clearResponseMessage } from '../actions/messageActions';
import {
  APP_INIT_RESPONSE_TYPE,
  RoleBanking,
  RoleContact,
  RoleDashboard,
  RoleInventory,
  RoleOnlineOrders,
  RoleOrders,
  RolePurchase,
  RoleReports,
  RoleSales,
  RoleUserAndRoles,
  RoleWarehouse,
  RoleInventoryTransaction,
  RoleLockingPeriod,
  RolePrintBarcode,
} from '../global/constants';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import { clearSalesData } from '../actions/salesActions';
import { clearPurchaseData } from '../actions/purchaseActions';
import { clearSalesOrderData } from '../actions/salesOrderActions';
import { clearCreditNotesData } from '../actions/creditNotesAction';
import { clearDebitNoteData } from '../actions/debitNotesActions';
import { clearProductPricingData } from '../actions/productPricingActions';
import { clearContactData } from '../actions/contactAction';
import { clearInventoryData } from '../actions/InventoryActions';
import { clearOrderListData } from '../actions/orderListActions';
// import { getUserProfile } from './authService';
import { clearPurchaseChallanData } from '../actions/purchaseChallanActions';
import { getDateDifference, isDev } from '../helper/common';
import { REACT_APP_ONESIGNLE_APP_ID } from '../global/environment';
import { clearMarketplaceList, clearUserAndRoles } from '../actions/settingActions';
import { clearBankAccountData } from '../actions/accountsActions';
import { clearActivityData } from '../actions/activityActions';
import { clearDashboard } from '../actions/dashboardActions';
import { clearInventoryTransactionData } from '../actions/inventoryTransactionActions';
import { clearJournalTransactionsData } from '../actions/journalTransactionsActions';
import { clearManifestData } from '../actions/manifestActions';
import { clearMembershipError } from '../actions/membershipActions';
import { clearNotificationData } from '../actions/notificationActions';
import { clearPaginationData } from '../actions/paginationActions';
import { clearReportsData } from '../actions/reportsActions';
import { clearRtoManifestData } from '../actions/rtoManifestActions';
import { clearWorkbookData } from '../actions/workbookActions';
import { setupToken } from '../helper/authTokenHelpers';
import { getCompanyDetails, getCompanyList } from './companyService';
import { getUsersAndRolesList } from './settingService';
import RealtimeService from './realtimeService';
import OnlineOrdersCacheService from './onlineOrderCacheServices';
import OfflineOrdersCacheService from './offlineOrderCacheService';
import InventoryTransactionCacheService from './inventoryTransactionCacheServices';
import PurchaseChallanCacheService from './purchaseChallanCacheService';
import SalesInvoiceCacheService from './salesInvoiceCacheServices';
import PurchaseInvoiceCacheService from './purchaseInvoiceCacheService';
import WorkbookCacheServices from './workbookCacheServices';
import { getNotificationList } from './notificationServices';
import { setAnlayticUserAndCompany } from './analyticsService';

/**
 * @desc  Load required data
 */
export const loadRequiredData = () => async (dispatch, getState) => {
  try {
    const token = setupToken();
    if (token) {
      dispatch(clearResponseMessage());
      let state = getState();
      const user = state.auth.user || {};
      await dispatch(getCompanyList());
      const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
      if (companyId) {
        await dispatch(getCompanyDetails(companyId));
        if (!document.hidden) await dispatch(RealtimeService.getInstance().init(user.id, companyId));
        await dispatch(getUsersAndRolesList(companyId));
        clearOlderThanThreeMonthsLocalData();
        dispatch(initPushNotification());
        dispatch(getNotificationList(companyId));

        state = getState();
        const organizationDetails = state.company.companyDetails || {};
        setAnlayticUserAndCompany(user, organizationDetails);
        return { type: APP_INIT_RESPONSE_TYPE.SUCCESS };
      } else {
        setAnlayticUserAndCompany(user);
      }
    }
    return { type: APP_INIT_RESPONSE_TYPE.REDIRECT, path: '/addOrganisation' };
  } catch (e) {
    return { type: APP_INIT_RESPONSE_TYPE.FAILED };
  }
};

/**
 * @desc  Is user has the particular role access
 */
export const isRoleAccess = (roleName) => (dispatch, getState) => {
  const state = getState();
  const { userRoles } = state.setting;
  if (userRoles && userRoles.roles && userRoles.roles.length > 0) {
    let role = userRoles.roles.find((x) => x.name === roleName);
    switch (roleName) {
      case RoleDashboard:
      case RoleBanking:
      case RoleContact:
      case RoleInventory:
      case RolePurchase:
      case RoleSales:
      case RoleReports:
      case RoleOrders:
      case RoleOnlineOrders:
      case RoleWarehouse:
        return {
          is_write_access: role?.is_write_access,
          is_read_access: role?.is_read_access,
          is_delete_access: role?.is_delete_access,
        };
      case RoleLockingPeriod:
        return {
          is_write_access: role?.is_write_access,
          is_read_access: role?.is_read_access,
          is_delete_access: role?.is_delete_access,
        };
      case RoleInventoryTransaction:
        return {
          is_write_access: role?.is_write_access,
          is_read_access: role?.is_read_access,
          is_delete_access: role?.is_delete_access,
        };
      case RolePrintBarcode:
        return {
          is_write_access: role?.is_write_access,
          is_read_access: role?.is_read_access,
          is_delete_access: role?.is_delete_access,
        };
      case RoleUserAndRoles:
        if (userRoles?.is_owner || userRoles?.is_admin) {
          return { is_write_access: true, is_read_access: true, is_delete_access: true };
        }
        break;
      default:
        break;
    }
  }
  return { is_write_access: false, is_read_access: false, is_delete_access: false };
};

export const clearAllItemData = () => async (dispatch) => {
  dispatch(clearInventoryData());
  dispatch(clearContactData());
  dispatch(clearSalesData());
  dispatch(clearPurchaseData());
  dispatch(clearPurchaseChallanData());
  dispatch(clearSalesOrderData());
  dispatch(clearCreditNotesData());
  dispatch(clearDebitNoteData());
  dispatch(clearProductPricingData());
  dispatch(clearOrderListData());
  dispatch(clearMarketplaceList());
  dispatch(clearUserAndRoles());
  dispatch(clearBankAccountData());
  dispatch(clearActivityData());
  dispatch(clearDashboard());
  dispatch(clearInventoryTransactionData());
  dispatch(clearJournalTransactionsData());
  dispatch(clearManifestData());
  dispatch(clearMembershipError());
  dispatch(clearNotificationData());
  dispatch(clearPaginationData());
  dispatch(clearReportsData());
  dispatch(clearRtoManifestData());
  dispatch(clearWorkbookData());
};

const clearOlderThanThreeMonthsLocalData = () => {
  const daysIntervalToCleanLocalData = 7;
  const lastCleanedLocalDataDate = new Date(UserPreferenceSingleton.getInstance().getlastCleanedLocalDataDate());
  const todayDate = new Date(new Date().toDateString());

  const isAllowCleanData = getDateDifference(lastCleanedLocalDataDate, todayDate) > daysIntervalToCleanLocalData;
  if (!isAllowCleanData) return;
  UserPreferenceSingleton.getInstance().setlastCleanedLocalDataDate(new Date().toDateString());

  OnlineOrdersCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([5, 6, 7, 8, 9, 10, 11]);
  OfflineOrdersCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([4, 5, 6, 7, 8, 9, 10, 11]);
  InventoryTransactionCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([]);
  PurchaseChallanCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([2]);
  PurchaseInvoiceCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([5]);
  SalesInvoiceCacheService.getInstance()?.clearOlderThanThreeMonthsLocalData([5]);
  WorkbookCacheServices.getInstance()?.clearOlderThanThreeMonthsLocalData([4]);
};

const initPushNotification = () => async (dispatch, getState) => {
  try {
    if (!OneSignal) return;

    if (!REACT_APP_ONESIGNLE_APP_ID) return;

    await OneSignal.init({
      appId: REACT_APP_ONESIGNLE_APP_ID,
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.showSlidedownPrompt();

    const user = getState().auth.user;

    const userTagPayload = {
      email: user.email,
      userid: user.id,
    };

    if (!OneSignal.sendTags) return;

    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (!companyId) return;

    try {
      OneSignal.sendTags(userTagPayload, function (tagsSent) {
        // Callback called when tags have finished sending
      });
    } catch (e) {
      console.log('Error in OneSignal-sendTags', e);
    }

    try {
      if (!isDev()) OneSignal.setDefaultNotificationUrl('https://erp.aryadesigns.co.in/');
    } catch (e) {
      console.log('Error in OneSignal-GetPushNotificationPermission', e);
    }

    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      if (isEnabled) console.log('Push notifications are enabled!');
      else console.log('Push notifications are not enabled yet.');
    });

    OneSignal.on('notificationDisplay', function (event) {
      console.log('New notification Received', event);
      dispatch(getNotificationList(companyId));
    });
  } catch (e) {
    console.log('ERROR', e);
  }
};
