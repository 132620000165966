import * as Actions from './../actions/types';
const initialState = {
  companyList: [],
  selectedCompany: {},
  loading: false,
  companyDetails: {},
  organisation: {
    name: '',
    gst_number: '',
    pan_number: '',
    address_line1: '',
    city: '',
    pincode: '',
    state: '',
    state_code: '',
  },
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_COMPANY_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload,
      };
    case Actions.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case Actions.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload,
      };
    case Actions.ADD_ORGANISATION_ITEM:
      return {
        ...state,
        organisation: action.payload,
      };
    case Actions.SET_ORGANISATION_ITEM:
      return {
        ...state,
        organisation: action.payload,
      };
    case Actions.UPDATE_ORGANISATION_ITEM:
      return {
        ...state,
        organisation: { ...state.organisation, ...action.payload },
      };
    case Actions.CLEAR_ORGANISATION_ITEM:
      const initialOrganisation = {
        name: '',
        gst_number: '',
        pan_number: '',
        address_line1: '',
        city: '',
        pincode: '',
        state: '',
        state_code: '',
      };
      return {
        ...state,
        organisation: initialOrganisation,
      };
    case Actions.CLEAR_COMPANY_DATA:
      return initialState;
    default:
      return state;
  }
};
