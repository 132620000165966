import moment from 'moment';
import _ from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DATE_FORMAT, INVENTORY_TYPE } from '../global/constants';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
/**
 * @desc Check if given value is string
 * @param {*} value // Accepts string
 */
export function isStirng(value) {
  var myRegEx = /^[a-zA-Z\s]*$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks if given value is Number
 * @param {*} value // Accepts string
 */
export function isNumber(value) {
  var myRegEx = /^(\s*[0-9]+\s*)+$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for valid email
 * @param {*} value // Accepts string
 */
export function isEmail(value) {
  // eslint-disable-next-line max-len
  var myRegEx =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for Empty string
 * @param {*} value // Accepts string, object
 */
export function isEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * @desc: Check valid date
 */
export function isValidDate(d) {
  return d instanceof Date;
}

/**
 * @desc it return unique GUID string
 */
export const getUniqueId = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
};

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
export const isDev = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

/**
 * @desc get query params
 */
export const getUrlParams = (queryParams) => {
  if (!queryParams) return new URLSearchParams();
  return new URLSearchParams(queryParams);
};

/**
 * @desc get query param by name
 */
export const getUrlParam = (query, name) => {
  let queryParams = new URLSearchParams();
  if (query) queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

/**
 * @desc get user friendly string from the given value
 * @param {*} value
 * @param {*} replaceChar
 */
export const UserFriendlyString = (value, replaceChar) => {
  if (!value) return '';
  value = value.trim();

  if (!replaceChar) replaceChar = '_';
  return value === undefined
    ? ''
    : value
        .replace(/[^a-z0-9_]+/gi, replaceChar)
        .replace(/^-|-$/g, '')
        .toLowerCase();
};

export const stringToBoolean = (value) => {
  if (!value) return false;

  switch (value.toString().toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

export const appEnvironments = () => {
  if (isProduction()) return 'inDevlopment';
  return null;
};

export const isProduction = () => {
  try {
    var url = window.location.href;
    if (url && url.includes('beta.mailcanvas.app')) return false;
  } catch (ex) {}

  if (!isDev()) return true;
  return false;
};

export function mathRound(number, digit = 3) {
  try {
    if (Number(number) < 1) digit = 3;
    if (number) return Number(_.round(Number(number), digit));
  } catch (e) {}
  return Number(Number(0).toFixed(digit));
}

/**
 * @desc load java script async from code
 */
export const loadJavaScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

/**
 * @desc get formatted date
 */
export const getFormattedDate = (date) => {
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  return day + '/' + month + '/' + year;
};

/**
 * @desc format date from dd/mm/yyyy to dd/mmm/yyyy
 */
export const getDDMMMYYYYDateFromDDMMYYYY = (date) => {
  if (!date) return '';
  let formattedDate = date;
  if (typeof date !== 'string' && typeof date === 'object') {
    formattedDate = moment(date).format('DD/MMM/YYYY');
  }
  formattedDate = formattedDate?.replaceAll('-', '/');
  // Check if date is of DD/MM/YYYY format
  let isDDMMYYYYFormat = true;
  var date_regex = /^([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])\/([1-9]|0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  if (!date_regex.test(formattedDate)) {
    isDDMMYYYYFormat = false;
  }
  if (!isDDMMYYYYFormat) return moment(formattedDate).format('DD-MMM-YYYY');
  // Convert DD/MM/YYYY format to DD-MMM-YYYY format
  const dateArr = formattedDate?.split('/');
  [dateArr[0], dateArr[1]] = [dateArr[1], dateArr[0]];
  const mmddyyyyDateString = dateArr?.join('-');
  return moment(mmddyyyyDateString).format('DD-MMM-YYYY');
};

/**
 * @desc init webflow ready
 */
export const initWebflowReady = () => {
  let _interval;
  _interval = setInterval(function () {
    if (window.Webflow) {
      clearInterval(_interval);
      window.Webflow.ready();
    }
  }, 100);
};

/**
 * @desc split user name into firstname and lastname
 */
export const getFirstAndLastName = (userName) => {
  let firstname, lastname;
  const args = (userName || '').split(' ');
  if (args && args.length >= 2) {
    firstname = args[0];
    lastname = args[1];
  }
  return { firstname, lastname };
};

export const getFormattedTime = (date) => {
  if (!date) date = new Date();
  else date = new Date(date);
  var hour = date.getHours();
  var minutes = date.getMinutes();
  const time = String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
  return String(time);
};

export const getNameById = (array, id) => {
  if (!array || array.length === 0 || !id) return;
  const item = array.find((x) => x.id === id);
  if (item) return item.name;
};

export const removeWhiteSpaceRegex = (str) => {
  return str.replace(/ +/g, '');
};

export const replaceWhiteSpaceWithDash = (str) => {
  return str.replace(/\s+/g, '-');
};

export const replaceWhiteSpaceWithUnderscore = (str) => {
  return str.replace(/\s+/g, '_');
};

export const getAPIResponseError = (e) => {
  if (e) {
    // console.log('ERROR', e);
    if (e.response && e.response.data) {
      if (e.response.data.message) {
        return e.response.data.message;
      }
    }
  }
  return;
};

// $(this).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');;
// /*For US number system (millions & billions)*/

// $(this).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
// /*For Indian number system (lakhs & crores)*/

export const formatCurrency = (num) => {
  try {
    if (num) return num.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  } catch (e) {}
  return num;
};

export const currencyWithDecimal = (num, digit = 2) => {
  let returnValue = num;
  try {
    if (num) {
      if (Number(num) < 1) digit = 3;
      const num2 = Number(num).toFixed(digit);
      returnValue = formatCurrency(num2);
    } else {
      returnValue = Number(0).toFixed(digit);
    }
  } catch (e) {}
  return returnValue;
};

export const numToWords = (num) => {
  let a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ];
  let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if ((num = num.toString()).length > 9) return 'overflow';
  let n = num.split('');
  n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return '';
  var str = '';
  str += n[1] !== '00' ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += n[2] !== '00' ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += n[3] !== '00' ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += n[4] !== '00' ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += n[5] !== '00' ? (str !== '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
  return str;
};

export const currencyInWords = (value) => {
  if (isEmpty(value)) return '';
  var number = parseFloat(value);
  if (number === undefined) return '';
  let num = value.toString().split('.');

  var Rs = numToWords(num[0]).toUpperCase();
  if (num.length === 1) return Rs + ' RUPEES ONLY';

  //Get two digit decimal
  var num2 = (num[1] + '0').substring(0, 2);
  if (num2[0] === '0') num2 = num2[1];

  var Paisa = numToWords(num2).toUpperCase();
  return Rs + ' RUPEES AND ' + Paisa + ' PAISA ONLY';
};
export const capitalizeString = (string) => {
  if (string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
};
export const getDateDifference = (startDate, endDate) => {
  try {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays >= 0) return diffDays;
  } catch (e) {}
};

export const getTimeDiffrence = (startDate, endDate) => {
  try {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    let diffrenceTime = (date2 - date1) / (1000 * 3600 * 24);
    return diffrenceTime;
  } catch (e) {}
};

export const isPastDate = (date) => {
  if (!date) {
    return '';
  }
  let diff = moment().diff(date, 'days');
  return diff > 0;
};

export const displayRelativeDate = (date) => {
  if (!date) {
    return '';
  }
  let diff = moment().diff(date, 'days');
  if (diff === 0) {
    return 'Today';
  } else if (diff === -1) {
    return 'Tomorrow';
  } else {
    return moment(date).fromNow();
  }
};

export const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

export const getFormattedAddress = (item) => {
  if (!item) return '';
  let formattedAdd = '';
  let address = [];
  if (!isEmpty(item.address_line1) || !isEmpty(item.addressLine1)) {
    address.push(item.address_line1 || item.addressLine1);
  }
  if (!isEmpty(item.address_line2) || !isEmpty(item.addressLine2)) {
    address.push(item.address_line2 || item.addressLine2);
  }
  if (!isEmpty(item.city)) {
    address.push(item.city);
  }
  if (!isEmpty(item.state)) {
    address.push(item.state);
  }
  if (!isEmpty(item.country)) address.push(item.country);
  if (!isEmpty(item.pincode)) {
    address.push(item.pincode);
  }
  formattedAdd = address.join(', ');
  return formattedAdd;
};

export const getDateString = (date) => {
  //return yyyyMMdd
  if (date) {
    date = new Date(date);
    return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2);
  }
  return;
};

export const getDateFromFormatedDate = (date) => {
  //parse yyyyMMdd and return date object
  if (date) {
    let year = Number(date.substr(0, 4));
    let month = Number(date.substr(4, 2));
    let day = Number(date.substr(6, 2));
    //here do -1 becuase month is always +1
    let result = new Date(year, month - 1, day);
    return result;
  }
  return;
};

export const getUTCDate = (date) => {
  if (date) date = new Date(date);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getDate = (date) => {
  if (!date) date = new Date();
  date = moment(date).format(DATE_FORMAT);
  return date;
};

export const removeDuplicates = (data, key) => {
  return [...new Map(data.map((x) => [key(x), x])).values()];
};

export const makePdf = (
  reportName,
  companyUserName,
  displayDate,
  address,
  columns = [],
  items,
  action,
  totalRowData = {},
) => {
  const headerColums = columns.map((item) => {
    return { text: item.label, ...(item.headerStyle || { style: 'tableHeader' }) };
  });

  let dataRows = items.map((item) => {
    return columns.map((col) => {
      return {
        text: item[col.field] === 0 ? '0' : item[col.field] || '',
        ...(item?.dataRowStyle?.[col?.cellStyleField] || col.dataRowStyle || { fontSize: 11 }),
      };
    });
  });

  let totalRowFields = [];
  if (!isEmpty(totalRowData)) {
    totalRowFields = columns.map((col) => {
      return { text: totalRowData[col.field] || '', ...(totalRowData.footerRowStyle || col.footerRowStyle || {}) };
    });
  }

  var documentDefinition = {
    content: [
      {
        text: [
          { text: companyUserName + '\n', style: 'header' },
          { text: address + '\n', style: 'address' },
          { text: `${reportName}\n`, style: 'header' },
          { text: displayDate + '\n', style: 'address' },
        ],
      },
      {
        columns: [
          {
            table: {
              widths: columns.map((col) => col.width || '10%'),
              headerRows: 1,
              body: [headerColums, ...dataRows],
            },
          },
        ],
      },
    ],
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 10, 0, 10], alignment: 'center', lineHeight: 1.2 },
      address: { fontSize: 11, color: '#4f566b', alignment: 'center', lineHeight: 1.5 },
      tableHeader: { color: '#4f566b', fontSize: 10 },
      styleRight: { alignment: 'right', fontSize: 11 },
    },
  };
  if (totalRowFields && totalRowFields.length > 0) {
    documentDefinition.content[1].columns[0].table.body.push(totalRowFields);
  }
  if (action === 'download') {
    pdfMake.createPdf(documentDefinition).download(replaceWhiteSpaceWithUnderscore(reportName));
  } else {
    pdfMake.createPdf(documentDefinition).print();
  }
};

export const getDetailPDF = (
  companyUserName,
  address,
  reportName,
  displayDate,
  columns = [],
  groupItems = [],
  action,
) => {
  const customerTables = [];
  const headerColums = columns.map((item) => {
    return { text: item.label, ...(item.headerStyle || { style: 'tableHeader' }) };
  });

  groupItems.forEach((cutomerData) => {
    const customerBody = [];
    customerBody.push(JSON.parse(JSON.stringify(headerColums)));

    // for first row after header
    if (cutomerData.firstItem) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.firstItem[col.field] === 0 ? '0' : cutomerData.firstItem[col.field] || '',
            ...(col.dataFooterStyle?.firstItem || { fontSize: 12 }),
          };
        }),
      );
    }

    // for items
    cutomerData.items.forEach((item) => {
      customerBody.push(
        columns.map((col) => {
          return {
            text: item[col.field] === 0 ? '0' : item[col.field] || '',
            ...(col.dataRowStyle || { fontSize: 11 }),
          };
        }),
      );
    });

    //for footer
    if (cutomerData.footerItem) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem[col.field] === 0 ? '0' : cutomerData.footerItem[col.field] || '',
            ...(col.dataFooterStyle?.footerItem || { fontSize: 12 }),
          };
        }),
      );
    }
    if (cutomerData.footerItem1) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem1[col.field] === 0 ? '0' : cutomerData.footerItem1[col.field] || '',
            ...(col.dataFooterStyle?.footerItem1 || { fontSize: 12 }),
          };
        }),
      );
    }
    if (cutomerData.footerItem2) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem2[col.field] === 0 ? '0' : cutomerData.footerItem2[col.field] || '',
            ...(col.dataFooterStyle?.footerItem2 || { fontSize: 12 }),
          };
        }),
      );
    }

    const customerHeader = {
      text: [
        {
          text: `${cutomerData.contact_name || cutomerData.company_name || cutomerData.account_name} - ${
            cutomerData?.gst_no ? cutomerData?.gst_no : ''
          } \n`,
          style: 'customerHeader',
        },
      ],
    };
    customerTables.push(customerHeader);

    const customerTable = {
      columns: [
        {
          table: {
            widths: columns.map((col) => col.width || '10%'),
            headerRows: 1,
            body: customerBody,
          },
        },
      ],
    };
    customerTables.push(customerTable);
  });

  var documentDefinition = {
    content: [
      {
        text: [
          { text: companyUserName + '\n', style: 'header' },
          { text: address + '\n', style: 'address' },
          { text: `${reportName}\n`, style: 'header' },
          { text: displayDate + '\n', style: 'address' },
        ],
      },
      ...customerTables,
    ],
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 10, 0, 10], alignment: 'center', lineHeight: 1.2 },
      customerHeader: { fontSize: 18, bold: true, margin: [0, 10, 0, 10], alignment: 'left', lineHeight: 1.2 },
      address: { fontSize: 11, color: '#4f566b', alignment: 'center', lineHeight: 1.5 },
      tableHeader: { color: '#4f566b', fontSize: 10 },
      styleRight: { alignment: 'right', fontSize: 11 },
    },
  };
  if (action === 'download')
    pdfMake.createPdf(documentDefinition).download(replaceWhiteSpaceWithUnderscore(reportName));
  else pdfMake.createPdf(documentDefinition).print();
};

export const groupBy = (collection, iteratee) => {
  const groupResult = _.groupBy(collection, iteratee);
  return Object.keys(groupResult).map((key) => {
    return { id: key, orderItems: groupResult[key] };
  });
};

export const downloadFile = (data, filename, mime) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

export const hasProduction = () => {
  if (window.location.host.startsWith('erp.aryadesigns.co.in')) {
    return true;
  }
  return false;
};

export const getFilterFromQueryString = (locationSearch) => {
  const urlParams = getUrlParams(locationSearch);
  const filterValue = {};
  let contact, contact_name, vendor, vendor_name;
  urlParams.forEach((value, key) => {
    if (key.startsWith('f_date_')) filterValue[key.replace('f_date_', '')] = new Date(value);
    else if (key.startsWith('f_contact_name')) {
      contact_name = value;
    } else if (key.startsWith('f_contact')) {
      contact = value;
    } else if (key.startsWith('f_vendor_name')) {
      vendor_name = value;
    } else if (key.startsWith('f_vendor')) {
      vendor = value;
    } else if (key.startsWith('f_tags')) {
      const tagsLabels = value?.split(',');
      const tagsArray = tagsLabels?.map((item) => {
        return { label: item, value: item };
      });
      filterValue['tags'] = tagsArray;
    } else if (key.startsWith('f_')) {
      filterValue[key.replace('f_', '')] = value;
    }
  });
  if (contact && contact_name) {
    filterValue['contact'] = { id: contact, company_name: contact_name, label: contact_name };
    filterValue['contact_name'] = contact_name;
  }
  if (vendor && vendor_name) {
    filterValue['vendor'] = { id: vendor, company_name: vendor_name, label: vendor_name };
    filterValue['vendor_name'] = vendor_name;
  }
  if (Object.keys(filterValue).length > 0) return filterValue;
  return undefined;
};

export const getFilterQueryString = (filterData = {}) => {
  //Remove non value field to clear object if not any filter apply
  Object.keys(filterData).forEach((key) => {
    if (!filterData[key]) delete filterData[key];
    else if (_.isArray(filterData[key]) && filterData[key].length === 0) delete filterData[key];
    else if (_.isObject(filterData[key]) && Object.keys(filterData[key]).length === 0) {
      if (!_.isDate(filterData[key])) {
        delete filterData[key];
      }
    }
  });
  let queryParams = {};
  Object.keys(filterData).forEach((key) => {
    // if (key === 'contact' || key === 'vendor') {
    //   queryParams[`f_${key}`] = JSON.stringify({
    //     id: filterData[key]?.id,
    //     company_name: filterData[key]?.company_name,
    //   });
    // } else
    if (_.isDate(filterData[key])) queryParams[`f_date_${key}`] = moment(filterData[key]).format('YYYY-MMM-DD');
    else if (key === 'contact') {
      queryParams[`f_contact_name`] = filterData[key]?.company_name;
      queryParams[`f_${key}`] = filterData[key]?.id;
    } else if (key === 'vendor') {
      queryParams[`f_vendor_name`] = filterData[key]?.company_name;
      queryParams[`f_${key}`] = filterData[key]?.id;
    } else if (key === 'tags') {
      let tagsString = '';
      filterData[key]?.forEach((tag, index) => {
        if (index !== 0) tagsString = tagsString + ',';
        tagsString = tagsString + tag?.label;
      });
      queryParams[`f_tags`] = tagsString;
    } else queryParams[`f_${key}`] = filterData[key];
  });

  const qs = require('querystring');
  return qs.stringify(queryParams);
};

export const getTaxInclusiveRate = (rate, taxRate) => {
  if (!rate && !taxRate) return 0;
  const taxInclusiveRate = mathRound((Number(rate) * (100 + Number(taxRate))) / 100);
  return taxInclusiveRate;
};

export const getTaxExclusiveRate = (taxInclusiveRate, taxRate) => {
  if (!taxInclusiveRate && !taxRate) return 0;
  const taxExclusiveRate = mathRound((Number(taxInclusiveRate) * 100) / (100 + Number(taxRate)));
  return taxExclusiveRate;
};

export const downloadCSVFromJson = (arrayOfJson, filename) => {
  if (!arrayOfJson) return;
  // convert JSON to CSV
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0]);
  let csv = arrayOfJson.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Create link and download
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getArrDataFromCsvString = (csvString) => {
  if (!csvString) return;
  let refinedCsvString = csvString.replace(/"=""/g, '"').replace(/"""/g, '"');
  const regExp = /\r\n|\n\r|\n|\r|\\n|\\r/;
  const rows = refinedCsvString?.split(regExp);
  const rowsArr = rows?.map((row) => {
    //Check comma inside cell value, if comma found replacec with string before split by comma
    //and then replace string with comma back
    const matches = row.match(/"([^"]*)"/g);
    if (matches && matches.length > 0) {
      matches.forEach((match) => {
        const newText = match.replace(/,/g, '~;~');
        row = row.replace(match, newText);
      });
    }
    let rowArr = row?.split(',');
    if (matches && matches.length > 0) {
      const fieldList = [];
      rowArr.forEach((field) => {
        field = field.replace(/~;~/g, ',').replace(/"/g, '');
        fieldList.push(field);
      });
      rowArr = fieldList;
    }
    return rowArr;
  });
  return rowsArr;
};

export const getTitleCaseFromSnackCase = (str) => {
  let output = str
    .replace(/(^\w)/g, (g) => g[0].toUpperCase())
    .replace(/([-_]\w)/g, (g) => ' ' + g[1].toUpperCase())
    .trim();
  return output;
};

export const getWorkbookDueInDaysOptions = (str) => {
  const workbookDueInDaysOptions = [];
  for (let i = 1; i <= 100; i++) {
    const option = { value: i, label: `${i} Days` };
    workbookDueInDaysOptions.push(option);
  }
  return workbookDueInDaysOptions;
};

export const isValidImgDimension = (src, widthSize, heightSize) => {
  if (!src) return false;
  return new Promise((resolve, reject) => {
    let isValidDimensions = true;
    let img = new Image();
    img.src = src;

    const isValidImg = (img) => {
      var height = img.height;
      var width = img.width;
      if (height > heightSize || width > widthSize) {
        isValidDimensions = false;
      }
      return isValidDimensions;
    };
    img.onload = () => resolve(isValidImg(img));
    img.onerror = reject;
  });
};

export const getFirstDateOfTheMonth = (date) => {
  if (date) date = new Date(date);
  else date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const addDaysToDate = (date, number) => {
  if (!date) date = new Date();
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + number);
  return newDate;
};

export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const createCookie = (name, value, days = 1, path = '/') => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + ';path=' + path;
};

export const makePdfWithMultipleTable = (
  reportName,
  companyUserName,
  displayDate,
  address,
  tableHeading1,
  columns1 = [],
  items,
  columns,
  groupItems,
  action,
  toDate,
  closing_balance,
) => {
  if (items?.length > 0) {
    var headerColums = columns1.map((item) => {
      return { text: item.label, ...(item.headerStyle || { style: 'tableHeader' }) };
    });

    var dataRows = items.map((item) => {
      return columns1.map((col) => {
        return {
          text: item[col.field] === 0 ? '0' : item[col.field] || '',
          ...(item?.dataRowStyle?.[col?.cellStyleField] || col.dataRowStyle || { fontSize: 11 }),
        };
      });
    });

    if (dataRows?.length > 0) {
      var dataRowFooter = JSON.parse(JSON.stringify(dataRows[0]));
      dataRowFooter[0].text = `As On ${moment(toDate).format('DD/MM/YYYY')}`;
      dataRowFooter[1].text = 'Closing Balance';
      dataRowFooter[2].text = '';
      dataRowFooter[3].text = currencyWithDecimal(closing_balance);
      dataRowFooter = [dataRowFooter];
    }
  }
  //For second table
  const customerTables = [];
  const headerColums2 = columns.map((item) => {
    return { text: item.label, ...(item.headerStyle || { style: 'tableHeader' }) };
  });

  groupItems.forEach((cutomerData) => {
    const customerBody = [];
    customerBody.push(JSON.parse(JSON.stringify(headerColums2)));

    // for first row after header
    if (cutomerData.firstItem) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.firstItem[col.field] === 0 ? '0' : cutomerData.firstItem[col.field] || '',
            ...(col.dataFooterStyle?.firstItem || { fontSize: 12 }),
          };
        }),
      );
    }

    // for items
    cutomerData.items.forEach((item) => {
      customerBody.push(
        columns.map((col) => {
          return {
            text: item[col.field] === 0 ? '0' : item[col.field] || '',
            ...(col.dataRowStyle || { fontSize: 11 }),
          };
        }),
      );
    });

    //for footer
    if (cutomerData.footerItem) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem[col.field] === 0 ? '0' : cutomerData.footerItem[col.field] || '',
            ...(col.dataFooterStyle?.footerItem || { fontSize: 12 }),
          };
        }),
      );
    }
    if (cutomerData.footerItem1) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem1[col.field] === 0 ? '0' : cutomerData.footerItem1[col.field] || '',
            ...(col.dataFooterStyle?.footerItem1 || { fontSize: 12 }),
          };
        }),
      );
    }
    if (cutomerData.footerItem2) {
      customerBody.push(
        columns.map((col) => {
          return {
            text: cutomerData.footerItem2[col.field] === 0 ? '0' : cutomerData.footerItem2[col.field] || '',
            ...(col.dataFooterStyle?.footerItem2 || { fontSize: 12 }),
          };
        }),
      );
    }

    const customerTable = {
      columns: [
        {
          table: {
            widths: columns.map((col) => col.width || '10%'),
            headerRows: 1,
            body: customerBody,
          },
        },
      ],
    };
    customerTables.push(customerTable);
  });

  //Display data in page
  var documentDefinition = {
    content: [
      {
        text: [
          { text: companyUserName + '\n', style: 'header' },
          { text: address + '\n', style: 'address' },
          { text: `${reportName}\n`, style: 'header' },
          { text: displayDate + '\n\n', style: 'address' },
          { text: tableHeading1 + '\n', style: 'tableHeading' },
        ],
      },
      dataRows && {
        columns: [
          {
            table: {
              widths: columns1.map((col) => col.width || '10%'),
              headerRows: 1,
              body: [headerColums, ...dataRows],
            },
          },
        ],
      },
      {
        text: [{ text: '\nTransactions\n', style: 'tableHeading' }],
      },
      ...customerTables,
      {
        text: [{ text: '\n' }],
      },
      dataRows && {
        columns: [
          {
            table: {
              widths: columns1.map((col) => col.width || '10%'),
              headerRows: 1,
              body: [...dataRowFooter],
            },
          },
        ],
      },
    ],
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 10, 0, 10], alignment: 'center', lineHeight: 1.2 },
      address: { fontSize: 11, color: '#4f566b', alignment: 'center', lineHeight: 1.5 },
      tableHeader: { color: '#4f566b', fontSize: 10 },
      tableHeading: { bold: true },
      styleRight: { alignment: 'right', fontSize: 11 },
    },
  };
  if (action === 'download') {
    pdfMake.createPdf(documentDefinition).download(replaceWhiteSpaceWithUnderscore(reportName));
  } else {
    pdfMake.createPdf(documentDefinition).print();
  }
};

export const checkIfTransactionInType = (type) => {
  let isTransactionIn;
  if (
    type === INVENTORY_TYPE.TRANSACTION_IN ||
    type === INVENTORY_TYPE.PURCHASE_INVOICE ||
    type === INVENTORY_TYPE.PURCHASE_CHALLAN ||
    type === INVENTORY_TYPE.CREDIT_NOTE
  ) {
    isTransactionIn = true;
  } else if (
    type === INVENTORY_TYPE.TRANSACTION_OUT ||
    type === INVENTORY_TYPE.SALES_INVOICE ||
    type === INVENTORY_TYPE.DEBIT_NOTE ||
    type === INVENTORY_TYPE.CHALLAN
  ) {
    isTransactionIn = false;
  }
  return isTransactionIn;
};
