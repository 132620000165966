import * as Actions from './types';

/**
 * @desc Set Rto Manifest Loader
 */
export const setRtoManifestLoader = (payload) => {
  return {
    type: Actions.SET_RTO_MANIFEST_LOADER,
    payload,
  };
};

/**
 * @desc Set Rto Manifest List
 */
export const setRtoManifestList = (payload) => {
  return {
    type: Actions.SET_RTO_MANIFEST_LIST,
    payload,
  };
};

/**
 * @desc Update Manifest Filtered List
 */
export const updateRtoManifestFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_RTO_MANIFEST_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set Rto Manifest Item
 */
export const setRtoManifestItem = (payload) => {
  return {
    type: Actions.SET_RTO_MANIFEST_ITEM,
    payload,
  };
};

/**
 * @desc Set Rto Manifest Put Up List
 */
export const setRtoManifestPutUpList = (payload) => {
  return {
    type: Actions.SET_RTO_MANIFEST_PUT_UP_LIST,
    payload,
  };
};

/**
 * @desc Clear Rto Manifest Data
 */
export const clearRtoManifestData = () => {
  return {
    type: Actions.CLEAR_RTO_MANIFEST_DATA,
  };
};
