import axios from 'axios';
import {
  setCompanyList,
  setCompanyLoader,
  setSelectedCompany,
  setCompanyDetails,
  addOrganisationItem,
} from '../actions/companyActions';
import {
  clearResponseMessage,
  setErrorMessage,
  setItemErrorMessage,
  setSuccessMessage,
} from '../actions/messageActions';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import { REACT_APP_APIURL } from '../global/environment';
import { getAPIResponseError } from '../helper/common';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import store from '../store/store';

/**
 * @desc company - Get company List
 * @param {*} obj Data Obj
 */
export const getCompanyList = (signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/company`, { signal });
    const { data } = response.data;
    if (data.length > 0) {
      const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
      let selectedCompany = {};
      if (!companyId) selectedCompany = data[0];
      else selectedCompany = data.find((x) => x.id === companyId);
      dispatch(setSelectedCompany(selectedCompany));
      UserPreferenceSingleton.getInstance().setCompanyId(selectedCompany.id);
    }
    dispatch(setCompanyList(data));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchCompanyError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc company - create organization
 * @param {*} obj Data Obj
 */
export const createOrganization = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/company`, payload);
    const { data } = response.data;
    if (response.data.status === 'ok') {
      addOrganisationItem(data);
      dispatchCompanysucess(data.message, dispatch);
    }
    const selectedCompany = data || {};
    dispatch(setSelectedCompany(selectedCompany));
    UserPreferenceSingleton.getInstance().setCompanyId(selectedCompany.id);
    if (UserPreferenceSingleton.getInstance().getCompanyId() === undefined) {
      dispatch(setCompanyList(data));
    }

    return true;
  } catch (e) {
    dispatchCompanyError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc  Get Company Details
 * @param {*} obj CompanyId , Data Obj
 */
export const getCompanyDetails = (company_id, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setCompanyLoader(true));

    const state = store.getState();
    const { companyDetails } = state.company;
    if (companyDetails && companyDetails?.id === company_id) return true;
    const response = await axios.get(`${REACT_APP_APIURL}/company/${company_id}`, { signal });
    const { data } = response.data;
    const users = data && data.length !== 0 && { ...data, company_users: data.users.map((item) => item.user) };
    if (users) dispatch(setCompanyDetails(users || {}));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchCompanyError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc company - update organization
 * @param {*} obj Data Obj
 */
export const updateOrganization = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!payload) {
      dispatchCompanyError('Organization Name is required', dispatch);
      return;
    } else if (!payload.name) {
      dispatchCompanyError('Organization Name is required', dispatch);
      return;
    } else if (!payload.state) {
      dispatchCompanyError('State is required', dispatch);
      return;
    } else if (!payload.state_code) {
      dispatchCompanyError('state_code is required', dispatch);
      return;
    }
    dispatch(setCompanyLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/company/${company_id}`, payload);
    const { data } = response.data;
    if (response.data.status === 'ok') {
      dispatchCompanysucess(data.message, dispatch);
    }
    return true;
  } catch (e) {
    dispatchCompanyError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc Create logo url
 * @param {*} payload Data Obj
 */
export const uploadImage = (payload, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/media/upload`, payload);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchCompanyError(getAPIResponseError(e) || 'Unable to upload this Image', dispatch, isModalError);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

function dispatchCompanyError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
function dispatchCompanysucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
