import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  debitNotesList: undefined,
  invoiceProducts: undefined,
  filteredDataList: undefined,
  debitNoteInvoice: {
    id: undefined,
    contact: undefined,
    number: '',
    date: new Date(),
    billing_address: undefined,
    shipping_address: undefined,
    reason: undefined,
    invoice: undefined,
    items: [],
    category_items: [],
    total_qty: 0,
    net_amount: 0,
    total_discount: 0,
    additional_fields: [],
    total_amount: 0,
    notes: '',
    total_tax: 0,
    tax_list: undefined,
  },
  invoicePreview: undefined,
  dueDebitNoteList: undefined,
  debitTagOption: undefined,
  dueDebitNotesList: undefined,
};

export const debitNotesReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_DEBIT_NOTES_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_DEBIT_NOTES_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_DEBIT_NOTES_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        debitNotesList: action.payload,
      };
    case Actions.SET_INVOICE_PRODUCT_LIST:
      return {
        ...state,
        invoiceProducts: action.payload,
      };
    case Actions.SET_DEBIT_NOTES_ITEM:
      return {
        ...state,
        debitNoteInvoice: action.payload,
      };
    case Actions.SET_SELECTED_PURCHASE_INVOICE_ITEM:
      return {
        ...state,
        purchaseInvoiceItem: action.payload,
      };
    case Actions.UPDATE_DEBIT_NOTES_ITEM:
      return {
        ...state,
        debitNoteInvoice: { ...state.debitNoteInvoice, ...action.payload },
      };
    case Actions.UPDATE_DEBIT_NOTES_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_DEBIT_NOTES_NEW_NUMBER:
      return {
        ...state,
        debitNoteInvoice: { ...state.debitNoteInvoice, number: action.payload },
      };
    case Actions.CLEAR_DEBIT_NOTES_INVOICE_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.debitNoteInvoice));
      return {
        ...state,
        debitNoteInvoice: invoiceData,
      };
    case Actions.SET_DEBIT_NOTES_INVOICE_PREVIEW:
      return {
        ...state,
        invoicePreview: action.payload,
      };
    case Actions.SET_DUE_DEBIT_NOTES:
      return {
        ...state,
        dueDebitNoteList: action.payload,
      };
    case Actions.SET_DEBIT_NOTE_TAG_OPTIONS:
      return {
        ...state,
        debitTagOption: action.payload,
      };
    case Actions.SET_DUE_DEBIT_NOTES_LIST:
      return {
        ...state,
        dueDebitNotesList: action.payload,
      };
    case Actions.CLEAR_DEBIT_NOTES_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
