import { lazy } from 'react';
const Home = lazy(() => import('../pages/Home/index'));
const Login = lazy(() => import('../pages/Membership/Login/Login'));
const AddOrganisation = lazy(() => import('../pages/AddOrganisation/AddOrganisation'));
const SwitchOrganisation = lazy(() => import('../pages/AddOrganisation/SwitchOrganisation'));
const Email = lazy(() => import('../pages/Membership/Email/Email'));
const Forgotpassword = lazy(() => import('../pages/Membership/Forgotpassword/Forgotpassword'));
const ResetPassword = lazy(() => import('../pages/Membership/ResetPassword/ResetPassword'));
const Signup = lazy(() => import('../pages/Membership/Signup/Signup'));

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false,
  },
  {
    path: '/signup',
    exact: true,
    component: Signup,
    private: false,
  },
  {
    path: '/invite/:inviteId',
    exact: true,
    component: Signup,
    private: false,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: Forgotpassword,
    private: false,
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    private: false,
  },
  {
    path: '/email',
    exact: true,
    component: Email,
    private: false,
  },
  {
    path: '/addOrganisation',
    exact: true,
    component: AddOrganisation,
    private: true,
  },
  {
    path: '/select-organisation',
    exact: true,
    component: SwitchOrganisation,
    private: true,
  },
  {
    path: '/',
    component: Home,
    private: true,
  },
];

export default routes;
