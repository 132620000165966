export const BANK_ACCOUNTS_TYPE_CASH = 1;
export const BANK_ACCOUNTS_TYPE_BANK = 2;
export const BANK_ACCOUNTS_TYPE_CREDITCARD = 3;
export const BANK_ACCOUNTS_TYPE_WALLET = 4;
export const DEFAULT_BANK_ACCOUNTS_BALANCE = 0;

export const CONTACT_TYPE_ALL = 0;
export const CONTACT_TYPE_CUSTOMERS = 1;
export const CONTACT_TYPE_SUPPLIERS = 2;
export const CONTACT_TYPE_SALES_PERSON = 3;
export const CONTACT_TYPE_EMPLOYEE = 4;
export const CONTACT_TYPE_ONLINE_ORDER_USERS = 5;

export const SIDEBAR_MENU_CHART_OF_ACCOUNT = 'chartOfAccount';
export const SIDEBAR_MENU_ORGANIZATION_PROFILE = 'organizationProfile';
export const SIDEBAR_MENU_USER_AND_ROLE = 'userAndRole';
export const SIDEBAR_MENU_SALES = 'sales';
export const SIDEBAR_MENU_OPENING_BALANCE = 'openingBalance';
export const SIDEBAR_MENU_EMAIL_SETTING = 'emailSetting';
export const SIDEBAR_MENU_INVENTORY_REASONS = 'inventoryReasons';
export const SIDEBAR_MENU_CATALOGUE = 'catalogue';
export const SIDEBAR_MENU_ANNOUNCEMENT = 'announcement';
export const SIDEBAR_MENU_CHANNEL = 'channel';
export const SIDEBAR_MENU_RETURN_FLOW = 'returnFlow';
export const SIDEBAR_MENU_CATEGORY = 'category';
export const SIDEBAR_MENU_COURIER = 'courier';
export const SIDEBAR_MENU_BRAND = 'brand';
export const SIDEBAR_MENU_PAYMENT_GATWAY = 'payment-gatway';
export const SIDEBAR_MENU_LOCK_DATA = 'lock-data';
export const SIDEBAR_MENU_BENCHMARK = 'benchmark';
export const SIDEBAR_MENU_INTEGRATION = 'Integration';
export const SIDEBAR_MENU_MARKETPLACES = 'marketplaces';
export const SIDEBAR_MENU_EMAIL_RECIPIENTS = 'emailRecipients';

export const SIDEBAR_MENU_DASHBOARD = 'dashboard';
export const SIDEBAR_MENU_INVENTORY = 'inventory';
export const SIDEBAR_MENU_CONTACT = 'contact';
export const SIDEBAR_MENU_ACCOUNTS = 'accounts';
export const SIDEBAR_MENU_ORDER_LIST = 'orderList';
export const SIDEBAR_MENU_CHATS = 'chats';
export const SIDEBAR_MENU_REPORTS = 'reports';

export const ADDRESS_TYPE_DEFAULT = 'Default Address';
export const ADDRESS_TYPE_BILLING = 'Billing Address';
export const ADDRESS_TYPE_SHIPING = 'Shiping Address';

export const BANK_TRANSACTION_TYPE_CUSTOMER_PAYMENT = 1;
export const BANK_TRANSACTION_TYPE_VENDOR_PAYMENT = 3;
export const BANK_TRANSACTION_TYPE_INCOME = 5;
export const BANK_TRANSACTION_TYPE_EXPENSE = 4;
export const BANK_TRANSACTION_TYPE_INTERNAL_TRANSFER = 2;

export const SALES_VOID = '/sales/void';
export const CREDITNOTES_VOID = '/creditnotes/void';
export const ITEM_VOID = '/item/void';
export const CONTACT_VOID = '/contact/void';
export const INVENTORY_MAPPING = '/inventory-mapping';

export const REQUEST_CANCEL_MESSAGE = 'canceled';

export const LOCATION = {
  DOMESTIC: 'DOMESTIC',
  INTERNATIONAL: 'INTERNATIONAL',
};

export const APP_INIT_RESPONSE_TYPE = {
  SUCCESS: 'sucess',
  FAILED: 'failed',
  REDIRECT: 'redirect',
};

export const INVOICE_STATUS_ID = {
  NONE: 0,
  DRAFT: 1,
  AWAITING_APPROVAL: 2,
  PENDING: 3,
  PARTIALLY_PAID: 4,
  PAID: 5,
};
export const INVOICE_STATUS = {
  OVERDUE: 'Over Due',
  1: 'Draft',
  2: 'Awaiting Approval',
  3: 'Pending',
  4: 'Partially Paid',
  5: 'Paid',
};
export const INVOICE_PAYMENT_STATUS_ID = {
  NONE: 0,
  DUE: 1,
  LATE: 2,
  PAID: 3,
};

export const INVOICE_TEMPLATE_TYPE = {
  NONE: 0,
  DEFAULT: 1,
  CLASSIC: 2,
  THERMAL: 3,
};

export const INVENTORY_TRANSACTIONS_TYPE = {
  NONE: 0,
  IN: 1,
  OUT: 2,
  TRANSFER: 3,
};

export const RoleDashboard = 'DASHBOARD';
export const RoleBanking = 'BANKING';
export const RoleContact = 'CONTACTS';
export const RoleInventory = 'INVENTORY';
export const RolePurchase = 'PURCHASE';
export const RoleReports = 'REPORTS';
export const RoleSales = 'SALES';
export const RoleOrders = 'ORDERS';
export const RoleOnlineOrders = 'ONLINE ORDERS';
export const RoleUserAndRoles = 'USERANDROLES';
export const RolePrintBarcode = 'PRINT BARCODE';
export const RoleWarehouse = 'WAREHOUSE';
export const RoleLockingPeriod = 'LOCKING PERIOD';
export const RoleInventoryTransaction = 'INVENTORY TRANSACTION';

export const INVOICE_TYPE = {
  NONE: 0,
  SALE_INVOICE: 1,
  PURCHASE_BILL: 2,
  CREDIT_NOTES: 3,
  DEBIT_NOTES: 4,
  CHALLAN: 5,
  PURCHASE_CHALLAN: 6,
  CATEGORY_EXPENSE: 7,
  JOURNAL_TRANSACTION: 8,
  BANK_TRANSACTION: 9,
  OFFLINE_ORDER_LIST: 10,
  ONLINE_ORDER_LIST: 11,
};

export const INVOICE_TYPE_NAME = {
  0: 'None',
  1: 'Invoice',
  2: 'Purchase Invoice',
  3: 'Credit Note',
  4: 'Debit Note',
  5: 'Challan',
  6: 'Purchase Challan',
  7: 'Category Expense',
  8: 'Journal Transaction',
  9: 'Bank Transaction',
  10: 'Offline Order',
  11: 'Online Order',
};

export const TDS_TYPE = {
  NONE: 0,
  APPLICABLE: 1,
  NON_APPLICABLE: 2,
};

export const TCS_TYPE = {
  NONE: 0,
  APPLICABLE: 1,
  NON_APPLICABLE: 2,
};

export const TDS_TYPE_NAME = {
  0: 'None',
  1: 'Applicable',
  2: 'Not Applicable',
};

export const CREDIT_DEBIT_NOTES_STATUS = {
  0: 'None',
  1: 'Draft',
  2: 'Open',
  3: 'Awaiting_Payment',
  4: 'Partially_Paid',
  5: 'Closed',
};

export const CREDIT_DEBIT_NOTES_STATUS_ID = {
  NONE: 0,
  DRAFT: 1,
  OPEN: 2,
  AWAITING_PAYMENT: 3,
  PARTIALLY_PAID: 4,
  CLOSED: 5,
};

export const SALES_ORDER_TYPE = {
  NONE: 0,
  SALES: 1,
  MANUFACTURE: 2,
};

export const MANIFEST_STATUS = {
  0: 'Draft',
  1: 'Open',
  2: 'Cancelled',
};

export const MANIFEST_STATUS_ID = {
  DRAFT: 0,
  OPEN: 1,
  CANCELLED: 2,
};

export const ORDER_STATUS = {
  0: 'Queue',
  1: 'Open',
  1.1: 'Ivr Confirmed',
  1.2: 'Ivr Canceled',
  2: 'Confirmed',
  3: 'Partial',
  4: 'Processed',
  5: 'Ready To Ship',
  5.1: 'Manifest',
  5.2: 'RTO Manifest',
  6: 'Picked Up',
  7: 'Shipped',
  8: 'Delivered',
  9: 'Closed',
  10: 'Cancelled',
  11: 'RTO Acknowledge',
  12: 'Claimed',
  13: 'Claim Approved',
  14: 'Claim Rejected',
  15: 'Partially Cancelled',
};

export const PRODUCT_TRACK_STATUS = {
  TRACKABLE: 1,
  NOT_TRACKABLE: 2,
};

export const ROUNDING = 'Rounding';
export const ORDER_STATUS_ID = {
  QUEUE: 0,
  OPEN: 1,
  IVR_APPROVED: 1.1,
  IVR_CANCELED: 1.2,
  MARK_AS_CONFIRMED: 2,
  PARTIALLY_PROCESSED: 3,
  PROCESSED: 4,
  READY_TO_SHIP: 5,
  MANIFEST: 5.1,
  RTO_MANIFEST: 5.2,
  PICKED_UP: 6,
  SHIPPED: 7,
  DELIVERED: 8,
  CLOSED: 9,
  CANCELLED: 10,
  RTO_ACKNOWLEDGE: 11,
  CLAIMED: 12,
  CLAIM_APPROVED: 13,
  CLAIM_REJECTED: 14,
  PARTIALLY_CANCELLED: 15,
};

export const ACTIVITY_ACTION_STATUS = {
  0: 'None',
  1: 'Created',
  2: 'Updated',
  3: 'Deleted',
};

export const HISTORY_TYPE = {
  NONE: 0,
  ITEM: 1,
  SALE_INVOICE: 2,
  PURCHASE_BILL: 3,
  CREDIT_NOTES: 4,
  DEBIT_NOTES: 5,
  CHALLAN: 6,
  PURCHASE_CHALLAN: 7,
  SALES_ORDER: 8,
  WORKBOOK: 9,
  ONLINE_ORDER: 10,
  OFFLINE_ORDER: 11,
  CONTACT: 12,
  JOURNAL_TRANSACTION: 13,
  BANK_TRANSACTION: 14,
};

export const HISTORY_TYPE_NAME = {
  0: 'None',
  1: 'Item',
  2: 'Invoice',
  3: 'Purchase Invoice',
  4: 'Credit Note',
  5: 'Debit Note',
  6: 'Challan',
  7: 'Purchase Challan',
  8: 'Sales Order',
  9: 'WorkBook',
  10: 'Online Order',
  11: 'Offline Order',
  12: 'Contact',
  13: 'Journal Transaction',
  14: 'Bank Transaction',
};

export const COMMENT_TYPE_URL_NAME = {
  2: 'sales',
  3: 'purchase',
  4: 'credit-notes',
  5: 'debit-notes',
  6: 'sales',
  7: 'purchase-challan',
  8: 'sales-orders',
  9: 'workBook',
  13: 'journal-transactions',
  14: 'bank-transactions',
};

export const CHART_OF_ACCOUNT_TYPES = {
  NONE: 0,
  INCOME: 1,
  EXPENSE: 2,
  ASSET: 3,
  LIABILITY: 4,
  EQUITY: 5,
  BANK: 6,
  CONTACTS: 7,
};

export const ACCOUNT_TYPES_NAME = {
  0: 'None',
  1: 'Income',
  2: 'Expense',
  3: 'Asset',
  4: 'Liability',
  5: 'Equity',
  6: 'Bank',
  7: 'Contacts',
};

export const DATE_FORMAT = 'YYYY-MMM-DD';
export const LEDGER_REPORT_TYPES = {
  CUSTOMER: 1,
  SUPPLIER: 2,
};

export const INVOICE_ORDER_TYPE = {
  NONE: 0,
  SALES_ORDER: 1,
  OFFLINE_ORDER_LIST: 2,
  ONLINE_ORDER_LIST: 3,
  PURCHASE_CHALLAN: 4,
  DIRECT: 5,
  WORKBOOK: 6,
};

export const LINK_LABEL_BY_INVOICE_ORDER_TYPE = {
  0: '',
  1: 'sales-order',
  2: 'offline-orders',
  3: 'online-orders',
  4: 'purchase-challan',
  6: 'workbook',
};

export const ORDER_TAB = {
  0: 'pending',
  1: 'confirmed',
  2: 'processed',
  3: 'ready-to-ship',
  4: 'manifest',
  5: 'rto-manifest',
  6: 'rto-acknowledge',
  7: 'all',
};

export const ORDER_TAB_ID = {
  PENDING: 0,
  CONFIRMED: 1,
  PROCESSED: 2,
  READY_TO_SHIP: 3,
  MANIFEST: 4,
  RTO_MANIFEST: 5,
  RTO_ACKNOWLEDGE: 6,
  ALL: 7,
};

export const SALES_ORDER_TAB = {
  0: 'pending',
  1: 'processed',
};

export const SALES_ORDER_TAB_ID = {
  PENDING: 0,
  PROCESSED: 1,
};

export const ITEM_DETAIL_TAB = {
  0: 'Overview',
  1: 'Transactions',
  2: 'History',
};
export const ITEM_DETAIL_TAB_ID = {
  Overview: 0,
  Transactions: 1,
  History: 2,
};

export const CASH_AND_BANK_TAB_ID = {
  UNCATEGORIZED: 0,
  CATEGORIZED: 1,
  EXCLUDED: 2,
};

export const BANK_TRANSACTION_STATUS = {
  UNCATEGORIZED: 1,
  CATEGORIZED: 2,
  EXCLUDED: 3,
};

export const CASH_AND_BANK_TAB = {
  0: 'Uncategorized',
  1: 'Categorized',
  2: 'Excluded',
};

export const OFFLINE_ORDER_TAB_ID = {
  PENDING: 0,
  PROCESSED: 1,
  CANCELLED: 2,
  ALL: 3,
};

export const OFFLINE_ORDER_TAB = {
  0: 'Pending',
  1: 'Processed',
  2: 'Cancelled',
  3: 'All',
};

export const ONLINE_ORDERS_PRIORITY = {
  NONE: 0,
  MOST_IMPORTANT: 1,
  REGULAR: 2,
};

export const ONLINE_ORDERS_PRIORITY_LABEL_BY_ID = {
  1: 'Most Important',
  2: 'Regular',
};

export const ORDERS_KIND = {
  REPEATED: 'Repeated',
  DUPLICATE: 'Duplicate',
};

export const ALERT_KIND = {
  DUPLICATE: 'Duplicate',
  TO_BE_DUE: 'To_be_due',
  OVERDUE: 'Overdue',
};

export const WORKBOOK_STATUS_ID = {
  NONE: 0,
  OPEN: 1,
  PARTIAL: 2,
  HOLD: 3,
  COMPLETED: 4,
  CANCELLED: 5,
};

export const WORKBOOK_STATUS = {
  0: 'None',
  1: 'Open',
  2: 'Partial',
  3: 'Hold',
  4: 'Completed',
  5: 'Cancelled',
};

export const WORKBOOK_TAB = {
  0: 'Pending',
  1: 'Processed',
};
export const WORKBOOK_TAB_ID = {
  PENDING: 0,
  PROCESSED: 1,
};

export const CONFIRM_DIALOGUE_BOX_TYPE = {
  NONE: 0,
  DELETE: 1,
  OTHER: 2,
};

export const INVENTORY_TYPE = {
  NONE: 0,
  TRANSACTION_IN: 1,
  TRANSACTION_OUT: 2,
  SALES_INVOICE: 3,
  PURCHASE_INVOICE: 4,
  CREDIT_NOTE: 5,
  DEBIT_NOTE: 6,
  CHALLAN: 7,
  PURCHASE_CHALLAN: 8,
};

export const INVENTORY_TYPE_LABEL_FROM_ID = {
  0: 'None',
  1: 'T. In',
  2: 'T. Out',
  3: 'S. I.',
  4: 'P. I.',
  5: 'C. N.',
  6: 'D. N.',
  7: 'S. Challan',
  8: 'P. Challan',
};
export const LINK_LABEL_BY_INVOICE_TYPE = {
  3: 'sales',
  4: 'purchase',
  5: 'creditnotes',
  6: 'debitnotes',
  8: 'purchase-challan',
};

export const PAYMENT_MODE = {
  CASH_ON_DELIVERY: 1,
  PREPAID: 2,
};

export const PAYMENT_MODE_LABEL = {
  1: 'COD',
  2: 'Prepaid',
};

export const TAX_TYPE = {
  INCLUSIVE: 1,
  EXCLUSIVE: 2,
};

export const ORDER_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
};

export const PRODUCT_STATUS = {
  NONE: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const PRODUCT_STATUS_ID = {
  1: 'Active',
  2: 'InActive',
};

export const COURIER_COMPANY = {
  DELHIVERY: 'Delhivery',
};

export const ONLINE_ORDER_COLUMN_TYPES = {
  STATUS: 5,
  BUTTONS: 8,
};

export const DISCOUNT_TYPE = {
  NONE: 0,
  FLAT: 1,
  PERCENTAGE: 2,
};

export const DISCOUNT_TYPE_LABEL = {
  1: '₹',
  2: '%',
};

export const PURCHASE_CHALLAN_STATUS = {
  NONE: 0,
  OPEN: 1,
  PROCESSED: 2,
};

export const PURCHASE_CHALLAN_STATUS_ID = {
  0: 'None',
  1: 'Open',
  2: 'Processed',
};

export const INVOICE_CONFIRMATION_TYPE = {
  NONE: 0,
  PARTIALLY_CONFIRMED: 1,
  FULLY_CONFIRMED: 2,
};

export const GST_TYPE = {
  UNREGISTERD_BUSINESS: 3,
};

export const UNCATEGORIZED_TRANSACTION_ACTION_SCREEN = {
  CATEGORIZE: 0,
  MATCH: 1,
  CONTRA_ENTRY: 2,
};

export const excel_csv_header_options = {
  id: ['No.', 'Sr. No.', 'SI.No.', 'id', 'Sl. No.'],
  date: ['Value Date', 'Date', 'date'],
  description: ['Description', 'Detail', 'Details', 'description'],
  reference: ['Chq / Ref number', 'Transaction ID', 'reference'],
  cr_dr: ['Cr/Dr', 'Dr / Cr', 'cr_dr'],
  amount: ['Amount', 'Transaction Amount(INR)', 'amount'],
  debit: ['debit', 'Debit'],
  credit: ['credit', 'Credit'],
};

export const GST_R2_VERIFICATION_HEADER_OPTIONS = {
  id: 'SN',
  name: 'Name',
  gst_no: 'GST No.',
  type: 'Section',
  invoice_number: 'Invoice Number',
  invoice_date: 'Invoice Date',
  note_number: 'Note No.',
  note_date: 'Note Date',
  state: 'POS',
  taxable_amount: 'Taxable Value',
  IGST: 'IGST',
  CGST: 'CGST',
  SGST: 'SGST',
  total_tax: 'Total Tax',
  total_amount: 'Invoice Value',
};

export const PAYMENT_SOURCES_CSV_HEADER_OPTIONS = {
  TRANSACTION_TYPE: ['Requested Action', 'transaction_entity'],
  PAYMENT_REF_ID: ['PayU ID', 'entity_id'],
  ORDER_REF_ID: ['Merchant Txn ID', 'payment_notes'],
  FEE: ['fee (exclusive tax)', 'Payment Processing Fee'],
  TAX: ['tax', 'Service Tax'],
  CREDIT: ['credit', 'Net Amount'],
};

export const PAYMENT_SOURCES = {
  RAZOR_PAY: 'razorpay',
  PAY_U_MONEY: 'payumoney',
};

export const LEDGER_TYPE_IDS = {
  NONE: 0,
  OPENING_BALANCE: 1,
  INVOICE: 2,
  BILL: 3,
  SALES_PAYMENTS: 4,
  PURCHASE_PAYMENTS: 5,
  JOURNAL_TRANSACTION: 6,
  EXPENSE_PAYMENT: 7,
  TRANSFER_FUND: 8,
  ADVANCE_PAYMENT: 9,
  CREDIT_NOTES: 10,
  DEBIT_NOTES: 11,
  CASHBANK_PAYMENT: 12,
  INCOME_PAYMENT: 13,
  INVENTORY_TRANSACTION: 14,
  CHALLAN: 15,
  CATEGORY_EXPENSE: 16,
};

export const LEDGER_TYPE = {
  0: 'None',
  1: 'Opening Balance',
  2: 'Invoice',
  3: 'Bill',
  4: 'Sales/D.Note Payments',
  5: 'Purchase/C.Note Payments',
  6: 'Journal Transaction',
  7: 'Expense Payment',
  8: 'Transfer Fund',
  9: 'Advance Payment',
  10: 'Credit Notes',
  11: 'Debit Notes',
  12: 'Cashbank Payment',
  13: 'Income Payment',
  14: 'Inventory Transaction',
  15: 'Challan',
  16: 'Category Expense',
};

export const OPENING_BALANCE_TYPE = {
  1: 'Payable',
  2: 'Receivable',
};

export const PAYMENT_TERMS = {
  0: 'Today',
  1: 'Tomorrow',
  2: '2 Days Later',
  3: '3 Days Later',
  4: '4 Days Later',
  5: '5 Days Later',
  6: '6 Days Later',
  7: '7 Days Later',
  10: '10 Days Later',
  15: '15 Days Later',
  30: '30 Days Later',
  35: '35 Days Later',
  45: '45 Days Later',
  60: '60 Days Later',
  70: '70 Days Later',
  80: '80 Days Later',
  90: '90 Days Later',
  100: '100 Days Later',
};
export const SALES_VOLUME_TOOLTIP = {
  Online: 'Online',
  Offline: 'Offline',
  Sales: 'Sales Order',
  Invoice: 'Invoice',
};

export const LANGUAGE_IDS = {
  ENG: 1,
  GUJ: 2,
};

export const LANGUAGES = {
  ENG: 'eng',
  GUJ: 'guj',
};

export const ANNOUNCEMENT_TYPE = {
  0: '',
  1: 'ERP',
  2: 'Customer Panel',
};

export const LOCKING_PERIOD_MODULE_TYPE = {
  1: 'Sales Invoice',
  2: 'Purchase Invoice',
  3: 'Credit Note',
  4: 'Debit Note',
};

export const ONLINE_ORDERS_SORTING_FIELD_IDS = {
  DATE: 0,
  RACKS: 1,
  MANUFACTURER: 2,
  PACKING_BAG_SIZE: 3,
};

export const PRICE_TYPES = {
  SALES_PRICE: 1,
  PURCHASE_PRICE: 2,
};

export const ACTIVITY_TYPE = {
  NONE: 0,
  SALES_INVOICE: 1,
  PURCHASE_BILL: 2,
  BANK: 3,
  BANK_TRANSACTION: 4,
  PRODUCT: 5,
  INVENTORY: 6,
  INVENTORY_TRANSACTION: 7,
  SALES_ORDERS: 8,
  CONTACTS: 9,
  BUSINESS_DETAILS: 10,
  USERS_AND_ROLES: 11,
  SETTINGS: 12,
  CHART_OF_ACCOUNTS: 13,
  CREDIT_NOTES: 14,
  DEBIT_NOTES: 15,
  INCOME_PAYMENT: 16,
  CHALLAN: 17,
  OPENING_BALANCE: 18,
  PROFILE: 19,
  CHANGE_PASSWORD: 20,
  RESET_PASSWORD: 21,
  JOURNAL_TRANSACTION: 23,
  ONLINE_ORDER_LIST: 24,
  OFFLINE_ORDER_LIST: 25,
  WORKBOOK: 26,
  MANIFEST: 27,
  PURCHASE_ORDER: 28,
  CHANNEL: 29,
  COURIER: 30,
  PAYMENT_GATEWAY: 31,
  PURCHASE_CHALLAN: 32,
  CATEGORY_EXPENSE: 33,
  WAREHOUSE: 34,
  ANNOUNCEMENT: 35,
  LOCKING_PERIOD: 36,
  RTO_MANIFEST: 37,
};

export const NOTIFICATION_TYPE = {
  TAGGED_USER: 1,
  REPLY_COMMENT: 2,
};

export const WAREHOUSE_ACTION = {
  ENABLE: 1,
  DISABLE: 2,
};

export const ONLINE_ORDER_DETAILS_TABS = {
  0: 'Order',
  1: 'History',
  2: 'Shipping-history',
  3: 'Comments',
  4: 'Call-history',
};

export const OFFLINE_ORDER_DETAILS_TABS = {
  0: 'Order',
  1: 'History',
  2: 'Comments',
};

export const VIEW_INVOICE_TABS = {
  0: 'overview',
  1: 'history',
  2: 'comments',
};

export const JOURNAL_TRANSACTION_DETAILS_TABS = {
  0: 'overview',
  1: 'comments',
};

export const CONTACT_DETAILS_TABS = {
  0: 'details',
  1: 'history',
};
