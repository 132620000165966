// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const GET_AUTH_VALIDATION_ERRORS = 'GET_AUTH_VALIDATION_ERRORS';
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const SET_RES_WARNING_MSG = 'SET_RES_WARNING_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';
export const SET_ITEM_ERROR_MSG = 'SET_ITEM_ERROR_MSG';
export const SET_ITEM_SUCESS_MSG = 'SET_ITEM_SUCESS_MSG';

//Company
export const SET_COMPANY_LOADER = 'SET_COMPANY_LOADER';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const ADD_ORGANISATION_ITEM = 'ADD_ORGANISATION_ITEM';
export const SET_ORGANISATION_ITEM = 'SET_ORGANISATION_ITEM';
export const UPDATE_ORGANISATION_ITEM = 'UPDATE_ORGANISATION_ITEM';
export const CLEAR_ORGANISATION_ITEM = 'CLEAR_ORGANISATION_ITEM';
export const CLEAR_COMPANY_DATA = 'CLEAR_COMPANY_DATA';

//Inventory
export const SET_INVENTORY_LOADER = 'SET_INVENTORY_LOADER';
export const SET_INVENTORY_ITEMS_LIST = 'SET_INVENTORY_ITEMS_LIST';
export const SET_INVENTORY_ITEM = 'SET_INVENTORY_ITEM';
export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';
export const CLEAR_INVENTORY_ITEM_DATA = 'CLEAR_INVENTORY_ITEM_DATA';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';
export const SET_INVENTORY_WAREHOUSE_LIST = 'SET_INVENTORY_WAREHOUSE_LIST';
export const SET_INVENTORY_WAREHOUSE_LIST_WITH_DISABLE = 'SET_INVENTORY_WAREHOUSE_LIST_WITH_DISABLE';
export const SET_WAREHOUSE_ITEM = 'SET_WAREHOUSE_ITEM';
export const UPDATE_WAREHOUSE_ITEM = 'UPDATE_WAREHOUSE_ITEM';
export const CLEAR_WAREHOUSE_ITEM_DATA = 'CLEAR_WAREHOUSE_ITEM_DATA';
export const SET_INVENTORY_PRODUCT_DETAILS = 'SET_INVENTORY_PRODUCT_DETAILS';
export const SET_BULK_INVENTORY_PRODUCT_DETAILS = 'SET_BULK_INVENTORY_PRODUCT_DETAILS';
export const SET_INVENTORY_PRODUCT_CATEGORY_OPTION = 'SET_INVENTORY_PRODUCT_CATEGORY_OPTION';
export const SET_INVENTORY_PRODUCT_CATALOGUE_OPTION = 'SET_INVENTORY_PRODUCT_CATALOGUE_OPTION';
export const SET_INVENTORY_PRODUCT_TAG_OPTION = 'SET_INVENTORY_PRODUCT_TAG_OPTION';
export const SET_INVENTORY_PRODUCT_PACKING_BAG_SIZES = 'SET_INVENTORY_PRODUCT_PACKING_BAG_SIZES';
export const UPDATE_INVENTORY_ITEM_FILTERED_LIST = 'UPDATE_INVENTORY_ITEM_FILTERED_LIST';
export const SET_INVENTORY_HISTORY_LIST = 'SET_INVENTORY_HISTORY_LIST';
export const SET_MAPPED_PRODUCT_LIST = 'SET_MAPPED_PRODUCT_LIST';
export const SET_FILTERED_MAPPED_PRODUCT_LIST = 'SET_FILTERED_MAPPED_PRODUCT_LIST';
export const SET_INVENTORY_ITEM_LIST_UPDATED_FLAG = 'SET_INVENTORY_ITEM_LIST_UPDATED_FLAG';
export const UPDATE_INVENTORY_LIST_ITEM = 'UPDATE_INVENTORY_LIST_ITEM';

//Inventory Transaction
export const SET_INVENTORY_TRANSACTION_LOADER = 'SET_INVENTORY_TRANSACTION_LOADER';
export const SET_INVENTORY_TRANSACTION_LIST = 'SET_INVENTORY_TRANSACTION_LIST';
export const SET_INVENTORY_TRANSACTION_DETAILS = 'SET_INVENTORY_TRANSACTION_DETAILS';
export const CLEAR_INVENTORY_TRANSACTION_DATA = 'CLEAR_INVENTORY_TRANSACTION_DATA';
export const SET_INVENTORY_TRANSACTION_BY_ITEM = 'SET_INVENTORY_TRANSACTION_BY_ITEM';
export const UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST = 'UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST';
export const SET_INVENTORY_TRANSACTION_LIST_UPDATED_FLAG = 'SET_INVENTORY_TRANSACTION_LIST_UPDATED_FLAG';

//Product Pricing
export const SET_PRODUCT_PRICING_LOADER = 'SET_PRODUCT_PRICING_LOADER';
export const SET_PRODUCT_PRICING_LIST = 'SET_PRODUCT_PRICING_LIST';
export const SET_PRODUCT_PRICING_ITEM = 'SET_PRODUCT_PRICING_ITEM';
export const UPDATE_PRODUCT_PRICING_ITEM = 'UPDATE_PRODUCT_PRICING_ITEM';
export const CLEAR_PRODUCT_PRICING_ITEM = 'CLEAR_PRODUCT_PRICING_ITEM';
export const SET_PRODUCT_PRICING_PREVIEW = 'SET_PRODUCT_PRICING_PREVIEW';
export const CLEAR_PRODUCT_PRICING_DATA = 'CLEAR_PRODUCT_PRICING_DATA';
export const UPDATE_PRODUCT_PRICING_FILTERED_LIST = 'UPDATE_PRODUCT_PRICING_FILTERED_LIST';
export const SET_PRODUCT_PRICING_VALUES = 'SET_PRODUCT_PRICING_VALUES';

//contact
export const SET_CONTACT_LOADER = 'SET_CONTACT_LOADER';
export const SET_ALL_CONTACT_LIST = 'SET_ALL_CONTACT_LIST';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const ADD_CONTACT_ITEM = 'ADD_CONTACT_ITEM';
export const SET_CONTACT_TAGS_OPTION = 'SET_CONTACT_TAGS_OPTION';
export const SET_CONTACT_ITEM = 'SET_CONTACT_ITEM';
export const UPDATE_CONTACT_ITEM = 'UPDATE_CONTACT_ITEM';
export const CLEAR_CONTACT_ITEM = 'CLEAR_CONTACT_ITEM';
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS';
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST';
export const SET_ACCOUNT_MANAGER_LIST = 'SET_ACCOUNT_MANAGER_LIST';
export const SET_SALES_PERSON_LIST = 'SET_SALES_PERSON_LIST';
export const SET_SALES_PERSON_ITEM = 'SET_SALES_PERSON_ITEM';
export const UPDATE_SALES_PERSON_ITEM = 'UPDATE_SALES_PERSON_ITEM';
export const CLEAR_SALES_PERSON_ITEM = 'CLEAR_SALES_PERSON_ITEM';
export const CLEAR_CONTACT_DATA = 'CLEAR_CONTACT_DATA';
export const UPDATE_CONTACT_FILTERED_DATA_LIST = 'UPDATE_CONTACT_FILTERED_DATA_LIST';
export const SET_CONTACT_LIST_UPDATED_FLAG = 'SET_CONTACT_LIST_UPDATED_FLAG';

//Accounts
export const SET_BANK_ACCOUNT_LOADER = 'SET_BANK_ACCOUNT_LOADER';
export const SET_BANK_ACCOUNT_LIST = 'SET_BANK_ACCOUNT_LIST';
export const SET_BANKS_ITEM = 'SET_BANKS_ITEM';
export const UPDATE_BANK_ITEM = 'UPDATE_BANK_ITEM';
export const CLEAR_BANK_ITEM = 'CLEAR_BANK_ITEM';
export const SET_BANKS_TRANSACTIONS_LIST = 'SET_BANKS_TRANSACTIONS_LIST';
export const SET_BANKS_TRANSACTIONS_DETAILS = 'SET_BANKS_TRANSACTIONS_DETAILS';
export const UPDATE_BANKS_TRANSACTIONS_DETAILS = 'UPDATE_BANKS_TRANSACTIONS_DETAILS';
export const CLEAR_BANKS_TRANSACTIONS_DETAILS = 'CLEAR_BANKS_TRANSACTIONS_DETAILS';
export const UPDATE_BANK_TRANSACTIONS_ITEM = 'UPDATE_BANK_TRANSACTIONS_ITEM';
export const CLEAR_BANK_TRANSACTIONS_ITEM = 'CLEAR_BANK_TRANSACTIONS_ITEM';
export const UPDATE_TRANSFER_FUND_ITEM = 'UPDATE_TRANSFER_FUND_ITEM';
export const CLEAR_TRANSFER_FUND_ITEM = 'CLEAR_TRANSFER_FUND_ITEM';
export const SET_TAX_LIST = 'SET_TAX_LIST';
export const SET_TDS_LIST = 'SET_TDS_LIST';
export const SET_TCS_LIST = 'SET_TCS_LIST';
export const UPDATE_FILTER_DATA_LIST = 'UPDATE_FILTER_DATA_LIST';
export const CLEAR_BANK_ACCOUNT_DATA = 'CLEAR_BANK_ACCOUNT_DATA';
export const SET_MATCH_TRANSACTION_INVOICE_LIST = 'SET_MATCH_TRANSACTION_INVOICE_LIST';
export const UPDATE_MATCH_TRANSACTION_INVOICE_LIST = 'UPDATE_MATCH_TRANSACTION_INVOICE_LIST';
export const CLEAR_MATCH_TRANSACTION_INVOICE_LIST = 'CLEAR_MATCH_TRANSACTION_INVOICE_LIST';
export const SET_BANK_TRANSACTIONS_UNCATEGORIZED_BENCHMARK = 'SET_BANK_TRANSACTIONS_UNCATEGORIZED_BENCHMARK';

//Setting
export const SET_SETTING_LOADER = 'SET_SETTING_LOADER';
export const SET_USERS_AND_ROLES_LIST = 'SET_USERS_AND_ROLES_LIST';
export const SET_CHART_OF_ACCOUNTS_LIST = 'SET_CHART_OF_ACCOUNTS_LIST';
export const SET_CHART_OF_ACCOUNTS_DETAILS = 'SET_CHART_OF_ACCOUNTS_DETAILS';
export const SET_CHART_OF_ACCOUNTS_CATEGORY_LIST = 'SET_CHART_OF_ACCOUNTS_CATEGORY_LIST';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const SET_COMPANY_E_INVOICE_SETTINGS = 'SET_COMPANY_E_INVOICE_SETTINGS';
export const SET_COMPANY_EXOTEL_SETTINGS = 'SET_COMPANY_EXOTEL_SETTINGS';
export const SET_COMPANY_SHIPROCKET_SETTINGS = 'SET_COMPANY_SHIPROCKET_SETTINGS';
export const SET_COMPANY_DELHIVERY_SETTINGS = 'SET_COMPANY_DELHIVERY_SETTINGS';
export const SET_OPENING_BALANCE = 'SET_OPENING_BALANCE';
export const SET_CATALOGUE_LIST = 'SET_CATALOGUE_LIST';
export const SET_FILTERED_CATALOGUE_LIST = 'SET_FILTERED_CATALOGUE_LIST';
export const SET_CATALOGUE_ITEM = 'SET_CATALOGUE_ITEM';
export const CLEAR_CATALOGUE_ITEM = 'CLEAR_CATALOGUE_ITEM';
export const UPDATE_OPENING_BALANCE = 'UPDATE_OPENING_BALANCE';
export const CLEAR_USERS_AND_ROLES_DATA = 'CLEAR_USERS_AND_ROLES_DATA';
export const SET_ANNOUNCEMENT_LIST = 'SET_ANNOUNCEMENT_LIST';
export const SET_ANNOUNCEMENT_ITEM = 'SET_ANNOUNCEMENT_ITEM';
export const CLEAR_ANNOUNCEMENT_ITEM = 'CLEAR_ANNOUNCEMENT_ITEM';
export const SET_ANNOUNCEMENT_FILTERED_LIST = 'SET_ANNOUNCEMENT_FILTERED_LIST';
export const SET_LOCKING_PERIOD_LIST = 'SET_LOCKING_PERIOD_LIST';
export const SET_LOCKING_PERIOD_FILTERED_LIST = 'SET_LOCKING_PERIOD_FILTERED_LIST';
export const SET_LOCKING_PERIOD_ITEM = 'SET_LOCKING_PERIOD_ITEM';
export const CLEAR_LOCKING_PERIOD_ITEM = 'CLEAR_LOCKING_PERIOD_ITEM';
export const SET_MARKETPLACE_LIST = 'SET_MARKETPLACE_LIST';
export const SET_MARKETPLACE_ITEM = 'SET_MARKETPLACE_ITEM';
export const UPDATE_MARKETPLACE_ITEM = 'UPDATE_MARKETPLACE_ITEM';
export const CLEAR_MARKETPLACE_ITEM = 'CLEAR_MARKETPLACE_ITEM';
export const CLEAR_MARKETPLACE_LIST = 'CLEAR_MARKETPLACE_LIST';
export const SET_BRAND_LIST = 'SET_BRAND_LIST';
export const CLEAR_BRAND_ITEM = 'CLEAR_BRAND_ITEM';
export const UPDATE_BRAND_ITEM = 'UPDATE_BRAND_ITEM';
export const SET_BRAND_ITEM = 'SET_BRAND_ITEM';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_CATEGORY_ITEM = 'SET_CATEGORY_ITEM';
export const UPDATE_CATEGORY_ITEM = 'UPDATE_CATEGORY_ITEM';
export const CLEAR_CATEGORY_ITEM = 'CLEAR_CATEGORY_ITEM';

//sales
export const SET_SALES_LOADER = 'SET_SALES_LOADER';
export const SET_INVOICE_PRODUCT_LIST = 'SET_INVOICE_PRODUCT_LIST';
export const SET_SALES_INVOICE_LIST = 'SET_SALES_INVOICE_LIST';
export const SET_SALES_INVOICE_ITEM = 'SET_SALES_INVOICE_ITEM';
export const SET_SALES_INVOICE_ITEMS = 'SET_SALES_INVOICE_ITEMS';
export const SET_SALES_INVOICE_ITEMS_ROW = 'SET_SALES_INVOICE_ITEMS_ROW';
export const UPDATE_SALES_INVOICE_ITEM = 'UPDATE_SALES_INVOICE_ITEM';
export const SET_SALES_INVOICE = 'SET_SALES_INVOICE';
export const CLEAR_SALES_INVOICE_ITEM = 'CLEAR_SALES_INVOICE_ITEM';
export const SET_SALES_INVOICE_PREVIEW = 'SET_SALES_INVOICE_PREVIEW';
export const CLEAR_SALES_DATA = 'CLEAR_SALES_DATA';
export const SET_DUE_INVOICE_LIST = 'SET_DUE_INVOICE_LIST';
export const UPDATE_DUE_INVOICE_LIST = 'UPDATE_DUE_INVOICE_LIST';
export const SET_INVOICE_NUMBER = 'SET_INVOICE_NUMBER';
export const SET_SALES_INVOICE_NUMBER_LIST = 'SET_SALES_INVOICE_NUMBER_LIST';
export const UPDATE_FILTERED_DATA_LIST = 'UPDATE_FILTERED_DATA_LIST';
export const SET_SALES_LIST_UPDATED_FLAG = 'SET_SALES_LIST_UPDATED_FLAG';
export const UPDATE_INVOICE_PRODUCT_LIST_ITEM = 'UPDATE_INVOICE_PRODUCT_LIST_ITEM';
export const SET_INVOICE_PAYMENT_ITEM = 'SET_INVOICE_PAYMENT_ITEM';
export const UPDATE_INVOICE_PAYMENT_ITEM = 'UPDATE_INVOICE_PAYMENT_ITEM';
export const CLEAR_INVOICE_PAYMENT_ITEM = 'CLEAR_INVOICE_PAYMENT_ITEM';
export const SET_SALES_INVOICE_TAG_OPTIONS = 'SET_SALES_INVOICE_TAG_OPTIONS';
export const SET_EXPORT_LIST = 'SET_EXPORT_LIST';

//Pagination
export const SET_PAGINATED_LIST = 'SET_PAGINATED_LIST';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_TOTAL_PAGE = 'SET_TOTAL_PAGE';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';
export const CLEAR_PAGINATION_DATA = 'CLEAR_PAGINATION_DATA';

//Purchase
export const SET_PURCHASE_LOADER = 'SET_PURCHASE_LOADER';
export const SET_PURCHASE_INVOICE_LIST = 'SET_PURCHASE_INVOICE_LIST';
export const CLEAR_PURCHASE_DATA = 'CLEAR_PURCHASE_DATA';
export const SET_PURCHASE_INVOICE_ITEM = 'SET_PURCHASE_INVOICE_ITEM';
export const UPDATE_PURCHASE_INVOICE_ITEM = 'UPDATE_PURCHASE_INVOICE_ITEM';
export const CLEAR_PURCHASE_INVOICE_ITEM = 'CLEAR_PURCHASE_INVOICE_ITEM';
export const SET_PURCHASE_INVOICE_PREVIEW = 'SET_PURCHASE_INVOICE_PREVIEW';
export const SET_DUE_BILL_LIST = 'SET_DUE_BILL_LIST';
export const UPDATE_DUE_BILL_LIST = 'UPDATE_DUE_BILL_LIST';
export const SET_PURCHASE_NUMBER_LIST = 'SET_PURCHASE_NUMBER_LIST';
export const UPDATE_PURCHASE_FILTERED_LIST = 'UPDATE_PURCHASE_FILTERED_LIST';
export const SET_PURCHASE_LIST_UPDATED_FLAG = 'SET_PURCHASE_LIST_UPDATED_FLAG';
export const SET_PURCHASE_INVOICE_TAG_OPTIONS = 'SET_PURCHASE_INVOICE_TAG_OPTIONS';
export const SET_PURCHASE_ITEMS_ROW = 'SET_PURCHASE_ITEMS_ROW';
export const SET_PURCHASE_ITEMS = 'SET_PURCHASE_ITEMS';

//Purchase Challan
export const SET_PURCHASE_CHALLAN_LOADER = 'SET_PURCHASE_CHALLAN_LOADER';
export const SET_PURCHASE_CHALLAN_LIST = 'SET_PURCHASE_CHALLAN_LIST';
export const CLEAR_PURCHASE_CHALLAN_DATA = 'CLEAR_PURCHASE_CHALLAN_DATA';
export const SET_PURCHASE_CHALLAN_ITEM = 'SET_PURCHASE_CHALLAN_ITEM';
export const UPDATE_PURCHASE_CHALLAN_ITEM = 'UPDATE_PURCHASE_CHALLAN_ITEM';
export const CLEAR_PURCHASE_CHALLAN_ITEM = 'CLEAR_PURCHASE_CHALLAN_ITEM';
export const SET_PURCHASE_CHALLAN_PREVIEW = 'SET_PURCHASE_CHALLAN_PREVIEW';
export const SET_PURCHASE_CHALLAN_FILTERED_LIST = 'SET_PURCHASE_CHALLAN_FILTERED_LIST';
export const SET_PURCHASE_CHALLAN_LIST_UPDATED_FLAG = 'SET_PURCHASE_CHALLAN_LIST_UPDATED_FLAG';
export const SET_PURCHASE_CHALLAN_TAG_OPTIONS = 'SET_PURCHASE_CHALLAN_TAG_OPTIONS';
export const SET_PURCHASE_CHALLAN_ITEMS = 'SET_PURCHASE_CHALLAN_ITEMS';
export const SET_PURCHASE_CHALLAN_ITEMS_ROW = 'SET_PURCHASE_CHALLAN_ITEMS_ROW';

//Sales Order
export const SET_SALES_ORDER_LOADER = 'SET_SALES_ORDER_LOADER';
export const SET_SALES_ORDER_LIST = 'SET_SALES_ORDER_LIST';
export const SET_SALES_ORDER = 'SET_SALES_ORDER';
export const UPDATE_SALES_ORDER = 'UPDATE_SALES_ORDER';
export const CLEAR_SALES_ORDER = 'CLEAR_SALES_ORDER';
export const SET_SALES_ORDER_NUMBER = 'SET_SALES_ORDER_NUMBER';
export const SET_SALES_ORDER_PREVIEW = 'SET_SALES_ORDER_PREVIEW';
export const SET_LOADED_ENTRY_DATA = 'SET_LOADED_ENTRY_DATA';
export const SET_CHANNEL_LIST = 'SET_CHANNEL_LIST';
export const SET_CHANNEL_ITEM = 'SET_CHANNEL_ITEM';
export const UPDATE_CHANNEL_ITEM = 'UPDATE_CHANNEL_ITEM';
export const CLEAR_CHANNEL_ITEM = 'CLEAR_CHANNEL_ITEM';
export const SET_NEXT_ORDER_NUMBER = 'SET_NEXT_ORDER_NUMBER';
export const SET_COURIERS_LIST = 'SET_COURIERS_LIST';
export const SET_COURIER_ITEM = 'SET_COURIER_ITEM';
export const UPDATE_COURIER_ITEM = 'UPDATE_COURIER_ITEM';
export const CLEAR_COURIER_ITEM = 'CLEAR_COURIER_ITEM';
export const SET_PAYMENT_GATEWAY_LIST = 'SET_PAYMENT_GATEWAY_LIST';
export const CLEAR_SALES_ORDER_DATA = 'CLEAR_SALES_ORDER_DATA';
export const UPDATE_SALES_ORDER_FILTERED_LIST = 'UPDATE_SALES_ORDER_FILTERED_LIST';

//Order List
export const SET_ORDER_LIST_LOADER = 'SET_ORDER_LIST_LOADER';
export const SET_FILTERED_ORDER_LIST = 'SET_FILTERED_ORDER_LIST';
export const SET_FILTERED_OFFLINE_ORDER_LIST = 'SET_FILTERED_OFFLINE_ORDER_LIST';
export const SET_OFFLINE_ORDER_LIST = 'SET_OFFLINE_ORDER_LIST';
export const SET_ONLINE_ORDER_LIST = 'SET_ONLINE_ORDER_LIST';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const CLEAR_ORDER_ITEM = 'CLEAR_ORDER_ITEM';
export const CLEAR_ORDER_LIST_DATA = 'CLEAR_ORDER_LIST_DATA';
export const SET_NEXT_OFFLINE_ORDER_NUMBER = 'SET_NEXT_OFFLINE_ORDER_NUMBER';
export const UPDATE_ORDER_LIST_FILTERED_LIST = 'UPDATE_ORDER_LIST_FILTERED_LIST';
export const SET_ONLINE_ORDER_PREVIEW = 'SET_ONLINE_ORDER_PREVIEW';
export const SET_ONLINE_ORDER_DETAIL = 'SET_ONLINE_ORDER_DETAIL';
export const SET_ONLINE_PICKUP_LIST_HISTORY = 'SET_ONLINE_PICKUP_LIST_HISTORY';
export const CLEAR_ONLINE_ORDER_ITEM = 'CLEAR_ONLINE_ORDER_ITEM';
export const UPDATE_ONLINE_ORDER_ITEM = 'UPDATE_ONLINE_ORDER_ITEM';
export const SET_OFFLINE_ORDER_PREVIEW = 'SET_OFFLINE_ORDER_PREVIEW';
export const SET_ONLINE_ORDER_TAG_OPTION = 'SET_ONLINE_ORDER_TAG_OPTION';
export const SET_ONLINE_ORDER_LIST_UPDATED_FLAG = 'SET_ONLINE_ORDER_LIST_UPDATED_FLAG';
export const SET_OFFLINE_ORDER_LIST_UPDATED_FLAG = 'SET_OFFLINE_ORDER_LIST_UPDATED_FLAG';
export const SET_OFFLINE_ORDER_DETAIL = 'SET_OFFLINE_ORDER_DETAIL';
export const SET_OFFLINE_ORDER_ITEMS = 'SET_OFFLINE_ORDER_ITEMS';
export const SET_OFFLINE_ORDER_ITEMS_ROW = 'SET_OFFLINE_ORDER_ITEMS_ROW';
export const SET_PENDING_BENCHMARK_PERCENTAGE = 'SET_PENDING_BENCHMARK_PERCENTAGE';
export const SET_CONFIRMED_BENCHMARK_PERCENTAGE = 'SET_CONFIRMED_BENCHMARK_PERCENTAGE';
export const SET_PROCESSED_BENCHMARK_PERCENTAGE = 'SET_PROCESSED_BENCHMARK_PERCENTAGE';
export const SET_OFFLINE_PENDING_BENCHMARK_PERCENTAGE = 'SET_OFFLINE_PENDING_BENCHMARK_PERCENTAGE';
export const SET_ONLINE_ORDER_CALL_HISTORY = 'SET_ONLINE_ORDER_CALL_HISTORY';

//credit Notes
export const SET_CREDIT_NOTES_LIST = 'SET_CREDIT_NOTES_LIST';
export const SET_CREDIT_NOTES_LOADER = 'SET_CREDIT_NOTES_LOADER';
export const CLEAR_CREDIT_NOTES_DATA = 'CLEAR_CREDIT_NOTES_DATA';
export const CLEAR_CREDIT_NOTES_INVOICE_ITEM = 'CLEAR_CREDIT_NOTES_INVOICE_ITEM';
export const UPDATE_CREDIT_NOTES_INVOICE_ITEM = 'UPDATE_CREDIT_NOTES_INVOICE_ITEM';
export const SET_CREDIT_NOTES_INVOICE_ITEM = 'SET_CREDIT_NOTES_INVOICE_ITEM';
export const SET_CREDIT_NOTES_INVOICE = 'SET_CREDIT_NOTES_INVOICE';
export const SET_CREDIT_NOTES_INVOICE_PREVIEW = 'SET_CREDIT_NOTES_INVOICE_PREVIEW';
export const SET_CREDIT_NOTES_INVOICE_NUMBER = 'SET_CREDIT_NOTES_INVOICE_NUMBER';
export const UPDATE_CREDIT_NOTES_FILTERED_LIST = 'UPDATE_CREDIT_NOTES_FILTERED_LIST';
export const SET_CREDIT_NOTES_LIST_UPDATED_FLAG = 'SET_CREDIT_NOTES_LIST_UPDATED_FLAG';
export const SET_DUE_CREDIT_NOTES = 'SET_DUE_CREDIT_NOTES';
export const SET_CREDIT_NOTE_TAG_OPTIONS = 'SET_CREDIT_NOTE_TAG_OPTIONS';
export const SET_CREDIT_NOTES_ITEMS_ROW = 'SET_CREDIT_NOTES_ITEMS_ROW';
export const SET_CREDIT_NOTES_ITEMS = 'SET_CREDIT_NOTES_ITEMS';
export const SET_DUE_CREDIT_NOTES_LIST = 'SET_DUE_CREDIT_NOTES_LIST';

//Debit Notes
export const SET_DEBIT_NOTES_LOADER = 'SET_DEBIT_NOTE_LOADER';
export const SET_DEBIT_NOTES_LIST = 'SET_DEBIT_NOTE_LIST';
export const CLEAR_DEBIT_NOTES_DATA = 'CLEAR_DEBIT_NOTE_DATA';
export const UPDATE_DEBIT_NOTES_ITEM = 'UPDATE_DEBIT_NOTES_ITEM';
export const SET_SELECTED_PURCHASE_INVOICE_ITEM = 'SET_SELECTED_PURCHASE_INVOICE_ITEM';
export const SET_DEBIT_NOTES_ITEM = 'SET_DEBIT_NOTES_ITEM';
export const SET_DEBIT_NOTES_INVOICE_PREVIEW = 'SET_DEBIT_NOTES_INVOICE_PREVIEW';
export const CLEAR_DEBIT_NOTES_INVOICE_ITEM = 'CLEAR_DEBIT_NOTES_INVOICE_ITEM';
export const UPDATE_DEBIT_NOTES_FILTERED_LIST = 'UPDATE_DEBIT_NOTES_FILTERED_LIST';
export const SET_DEBIT_NOTES_NEW_NUMBER = 'SET_DEBIT_NOTES_NEW_NUMBER';
export const SET_DEBIT_NOTES_LIST_UPDATED_FLAG = 'SET_DEBIT_NOTES_LIST_UPDATED_FLAG';
export const SET_DUE_DEBIT_NOTES = 'SET_DUE_DEBIT_NOTES';
export const SET_DEBIT_NOTE_TAG_OPTIONS = 'SET_DEBIT_NOTE_TAG_OPTIONS';
export const SET_DUE_DEBIT_NOTES_LIST = 'SET_DUE_DEBIT_NOTES_LIST';

//Reports
export const SET_REPORTS_LOADER = 'SET_REPORTS_LOADER';
export const SET_TRIAL_BALANCE = 'SET_TRIAL_BALANCE';
export const SET_GENERAL_EXPENSE = 'SET_GENERAL_EXPENSE';
export const SET_INVENTORY_ITEMS_SUMMARY = 'SET_INVENTORY_ITEMS_SUMMARY';
export const SET_INVENTORY_ITEM_DETAILS = 'SET_INVENTORY_ITEM_DETAILS';
export const SET_INVENTORY_TRANSACTIONS = 'SET_INVENTORY_TRANSACTIONS';
export const SET_SALES_BY_PRODUCT_DETAILS = 'SET_SALES_BY_PRODUCT_DETAILS';
export const SET_CASH_AND_BANK_DETAILS = 'SET_CASH_AND_BANK_DETAILS';
export const SET_OUTSTANDING_RECEIVABLES = 'SET_OUTSTANDING_RECEIVABLES';
export const SET_OUTSTANDING_RECEIVABLES_DETAILS = 'SET_OUTSTANDING_RECEIVABLES_DETAILS';
export const SET_OUTSTANDING_INVOICE_SUMMARY = 'SET_OUTSTANDING_INVOICE_SUMMARY';
export const SET_INCOME_BY_CUSTOMER = 'SET_INCOME_BY_CUSTOMER';
export const SET_SALES_BY_SALESPERSON = 'SET_SALES_BY_SALESPERSON';
export const SET_SALES_BY_SALESPERSON_DETAILS = 'SET_SALES_BY_SALESPERSON_DETAILS';
export const SET_SALES_INVOICE_SUMMARY = 'SET_SALES_INVOICE_SUMMARY';
export const SET_PURCHASE_REPORT = 'SET_PURCHASE_REPORT';
export const SET_OUTSTANDING_PAYABLES = 'SET_OUTSTANDING_PAYABLES';
export const SET_OUTSTANDING_PAYABLES_DETAILS = 'SET_OUTSTANDING_PAYABLES_DETAILS';
export const SET_PAYABLE_INVOICE_SUMMARY = 'SET_PAYABLE_INVOICE_SUMMARY';
export const SET_OUTCOME_BY_SUPPLIER = 'SET_OUTCOME_BY_SUPPLIER';
export const SET_LEDGER_SUMMARY = 'SET_LEDGER_SUMMARY';
export const SET_LEDGER_DETAILS = 'SET_LEDGER_DETAILS';
export const SET_PURCHASE_INVOICE_SUMMARY = 'SET_PURCHASE_INVOICE_SUMMARY';
export const SET_JOURNAL_REPORT = 'SET_JOURNAL_REPORT';
export const CLEAR_REPORTS_DATA = 'CLEAR_REPORTS_DATA';
export const SET_TDS_PAYABLE_DETAILS = 'SET_TDS_PAYABLE_DETAILS';
export const SET_TCS_PAYABLE_DETAILS = 'SET_TCS_PAYABLE_DETAILS';
export const SET_GENERAL_LEDGER_DETAILS = 'SET_GENERAL_LEDGER_DETAILS';
export const SET_GENERAL_LEDGER_SUMMARY = 'SET_GENERAL_LEDGER_SUMMARY';
export const SET_GST_R1_SUMMARY = 'SET_GST_R1_SUMMARY';
export const SET_GST_R2_SUMMARY = 'SET_GST_R2_SUMMARY';
export const SET_GST_R2_VERIFICATION_REPORT = 'SET_GST_R2_VERIFICATION_REPORT';
export const SET_SALES_BY_ITEMS = 'SET_SALES_BY_ITEMS';
export const SET_PROFIT_AND_LOSS = 'SET_PROFIT_AND_LOSS';
export const SET_BALANCE_SHEET = 'SET_BALANCE_SHEET';
export const SET_WAREHOUSE_PRODUCT_STOCK = 'SET_WAREHOUSE_PRODUCT_STOCK';
export const SET_SALES_BY_CONTACT = 'SET_SALES_BY_CONTACT';
export const SET_SALES_BY_PRODUCT = 'SET_SALES_BY_PRODUCT';

//Journal Transactions
export const SET_JOURNAL_TRANSACTION_LOADER = 'SET_JOURNAL_TRANSACTION_LOADER';
export const SET_JOURNAL_TRANSACTION_LIST = 'SET_JOURNAL_TRANSACTION_LIST';
export const SET_JOURNAL_TRANSACTION_FILTERED_LIST = 'SET_JOURNAL_TRANSACTION_FILTERED_LIST';
export const SET_JOURNAL_TRANSACTION_ITEM = 'SET_JOURNAL_TRANSACTION_ITEM';
export const UPDATE_JOURNAL_TRANSACTION_ITEM = 'UPDATE_JOURNAL_TRANSACTION_ITEM';
export const CLEAR_JOURNAL_TRANSACTION_ITEM = 'CLEAR_JOURNAL_TRANSACTION_ITEM';
export const CLEAR_JOURNAL_TRANSACTION_DATA = 'CLEAR_JOURNAL_TRANSACTION_DATA';
export const SET_JOURNAL_TRANSACTION_ITEM_DETAILS = 'SET_JOURNAL_TRANSACTION_ITEM_DETAILS';
export const SET_JOURNAL_TRANSACTION_ITEMS = 'SET_JOURNAL_TRANSACTION_ITEMS';

//Activity
export const SET_ACTIVITY_LOADER = 'SET_ACTIVITY_LOADER';
export const SET_ACTIVITY_HISTORY_LIST = 'SET_ACTIVITY_HISTORY_LIST';
export const UPDATE_HISTORY_FILTERED_LIST = 'UPDATE_HISTORY_FILTERED_LIST';
export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA';

//Workbook
export const SET_WORKBOOK_LOADER = 'SET_WORKBOOK_LOADER';
export const SET_WORKBOOK_LIST = 'SET_WORKBOOK_LIST';
export const SET_WORKBOOK_ITEM = 'SET_WORKBOOK_ITEM';
export const UPDATE_WORKBOOK_FILTERED_LIST = 'UPDATE_WORKBOOK_FILTERED_LIST';
export const CLEAR_WORKBOOK_ITEM = 'CLEAR_WORKBOOK_ITEM';
export const CLEAR_WORKBOOK_DATA = 'CLEAR_WORKBOOK_DATA';
export const UPDATE_WORKBOOK_ITEM = 'UPDATE_WORKBOOK_ITEM';
export const SET_NEXT_WORKBOOK_NUMBER = 'SET_NEXT_WORKBOOK_NUMBER';
export const SET_WORKBOOK_SUMMARY_LIST = 'SET_WORKBOOK_SUMMARY_LIST';
export const SET_WORKBOOK_ITEMS = 'SET_WORKBOOK_ITEMS';

//manifest
export const SET_MANIFEST_LOADER = 'SET_MANIFEST_LOADER';
export const SET_MANIFEST_LIST = 'SET_MANIFEST_LIST';
export const UPDATE_MANIFEST_FILTERED_LIST = 'UPDATE_MANIFEST_FILTERED_LIST';
// export const SET_MANIFEST_PREVIEW = 'SET_MANIFEST_PREVIEW';
export const CLEAR_MANIFEST_DATA = 'CLEAR_MANIFEST_DATA';

// RTO-Manifest
export const SET_RTO_MANIFEST_LOADER = 'SET_RTO_MANIFEST_LOADER';
export const SET_RTO_MANIFEST_LIST = 'SET_RTO_MANIFEST_LIST';
export const UPDATE_RTO_MANIFEST_FILTERED_LIST = 'UPDATE_RTO_MANIFEST_FILTERED_LIST';
export const SET_RTO_MANIFEST_ITEM = 'SET_RTO_MANIFEST_ITEM';
export const SET_RTO_MANIFEST_PUT_UP_LIST = 'SET_RTO_MANIFEST_PUT_UP_LIST';
export const CLEAR_RTO_MANIFEST_DATA = 'CLEAR_RTO_MANIFEST_DATA';

// Dashboard
export const SET_DASHBOARD_LOADER = 'SET_DASHBOARD_LOADER';
export const SET_BEST_SALES_PRODUCTS_LIST = 'SET_BEST_SALES_PRODUCTS_LIST';
export const SET_TOP_VENDOR_LIST = 'SET_TOP_VENDOR_LIST';
export const SET_CHANNEL_WISE_SALES_LIST = 'SET_CHANNEL_WISE_SALES_LIST';
export const SET_RTO_COUNT_LIST = 'SET_RTO_COUNT_LIST';
export const SET_SALES_VOLUME_LIST = 'SET_SALES_VOLUME_LIST';
export const SET_ACTIVE_ANNOUNCEMENT_LIST = 'SET_ACTIVE_ANNOUNCEMENT_LIST';
export const SET_PROFIT_AND_LOSS_OVERVIEW = 'SET_PROFIT_AND_LOSS_OVERVIEW';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

// Notification
export const SET_NOTIFICATION_LOADER = 'SET_NOTIFICATION_LOADER';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export const CLEAR_NOTIFICATION_DATA = 'CLEAR_NOTIFICATION_DATA';

// Membership
export const UPDATE_MEMBERSHIP_ERROR = 'UPDATE_MEMBERSHIP_ERROR';
export const CLEAR_MEMBERSHIP_ERROR = 'CLEAR_MEMBERSHIP_ERROR';
