import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  purchaseList: undefined,
  invoiceProducts: undefined,
  purchaseNumber: undefined,
  filteredDataList: undefined,
  invoice: {
    id: undefined,
    contact: undefined,
    number: '',
    date: new Date(),
    due_date: 0,
    items: [],
    category_items: [],
    total_qty: 0,
    net_amount: 0,
    total_discount: 0,
    additional_fields: [],
    total_amount: 0,
    notes: '',
    sales_order_id: undefined,
    total_tax: 0,
    tax_list: undefined,
  },
  purchaseItems: [],
  invoicePreview: undefined,
  dueBillList: undefined,
  purchaseInvoiceTagOptions: undefined,
};

export const purchaseReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_PURCHASE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_PURCHASE_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_PURCHASE_INVOICE_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        purchaseList: action.payload,
      };
    case Actions.SET_INVOICE_PRODUCT_LIST:
      return {
        ...state,
        invoiceProducts: action.payload,
      };
    case Actions.SET_PURCHASE_INVOICE_ITEM:
      return {
        ...state,
        invoice: action.payload,
      };
    case Actions.UPDATE_PURCHASE_INVOICE_ITEM:
      return {
        ...state,
        invoice: { ...state.invoice, ...action.payload },
      };
    case Actions.UPDATE_PURCHASE_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_PURCHASE_INVOICE_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.invoice));
      const invoiceItemsData = JSON.parse(JSON.stringify(initialState.purchaseItems));
      return {
        ...state,
        purchaseItems: invoiceItemsData,
        invoice: invoiceData,
      };
    case Actions.SET_PURCHASE_INVOICE_PREVIEW:
      return {
        ...state,
        invoicePreview: action.payload,
      };
    case Actions.SET_DUE_BILL_LIST:
      return {
        ...state,
        dueBillList: action.payload,
      };
    case Actions.UPDATE_DUE_BILL_LIST:
      return {
        ...state,
        dueBillList: [...state.dueBillList, ...action.payload],
      };
    case Actions.SET_PURCHASE_NUMBER_LIST:
      return {
        ...state,
        purchaseNumber: action.payload,
      };
    case Actions.SET_PURCHASE_INVOICE_TAG_OPTIONS:
      return {
        ...state,
        purchaseInvoiceTagOptions: action.payload,
      };
    case Actions.SET_PURCHASE_ITEMS:
      return {
        ...state,
        purchaseItems: action.payload,
      };
    case Actions.SET_PURCHASE_ITEMS_ROW:
      return {
        ...state,
        purchaseItems: state.purchaseItems.map((x, i) => {
          if (i === action.payload.index) x = action.payload.item;
          return x;
        }),
      };
    case Actions.CLEAR_PURCHASE_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
