import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import moment from 'moment';
import {
  clearResponseMessage,
  setErrorMessage,
  setItemErrorMessage,
  setItemSucessMessage,
  setSuccessMessage,
} from '../actions/messageActions';
import {
  setChannelList,
  setCouriersList,
  setLoadedEntryData,
  setNextOrderNumber,
  setOrderListLoader,
  setPaymentGatewayList,
  setNextOfflineOrderNumber,
  setOfflineOrderList,
  setOnlineOrderList,
  setOnlineOrdersPreview,
  setOnlineOrderDetail,
  setOfflineOrderDetail,
  setOfflineOrdersPreview,
  setOnlineOrderTagOption,
  setOnlinePickUpListHistory,
  setFilteredOfflineOrderList,
  setOnlineOrderCallHistory,
  setFilteredSearchResult,
  setOfflineOrderItems,
} from '../actions/orderListActions';
import { ORDER_STATUS_ID, PAYMENT_MODE, REQUEST_CANCEL_MESSAGE } from '../global/constants';
import { REACT_APP_APIURL } from '../global/environment';
import { getAPIResponseError, isEmpty } from '../helper/common';
import store from '../store/store';
import OnlineOrdersCacheService from './onlineOrderCacheServices';
import OfflineOrdersCacheService from './offlineOrderCacheService';

/**
 * @desc Get Offline Order list
 * @param {*} company_id company id
 */
export const getOfflineOrderList = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    //Set list from local cache
    const local_data = await OfflineOrdersCacheService.getInstance()?.getItems();
    if (local_data && local_data.length > 0) {
      const sorted_data = process(local_data, {
        sort: [
          { field: 'date', dir: 'desc' },
          { field: 'number', dir: 'desc' },
        ],
      });
      let list = sorted_data?.data;
      dispatch(setOfflineOrderList(list || []));
    }

    const lastUpdatedTimeFromLocalStorage = await OfflineOrdersCacheService.getInstance()?.getLastUpdatedTime();
    const lastUpdatedTime = lastUpdatedTimeFromLocalStorage || 0;
    const response = await axios.get(
      `${REACT_APP_APIURL}/order-list/${company_id}/last-updated-list/${lastUpdatedTime}`,
      { signal },
    );
    const { updated, archived, lastUpdatedOn } = response.data.data;
    let hasUpdatedData = false;
    if (lastUpdatedTime === 0) {
      await OfflineOrdersCacheService.getInstance()?.setItems(updated, company_id);
      hasUpdatedData = true;
    } else {
      await OfflineOrdersCacheService.getInstance()?.updateItems(updated, archived, company_id);
      if ((updated && updated.length > 0) || (archived && archived.length > 0)) {
        hasUpdatedData = true;
      }
    }
    await OfflineOrdersCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn, company_id);

    if (hasUpdatedData) {
      //Set list from local cache
      const local_data = await OfflineOrdersCacheService.getInstance()?.getItems();
      if (local_data && local_data.length > 0) {
        const sorted_data = process(local_data, {
          sort: [
            { field: 'date', dir: 'desc' },
            { field: 'number', dir: 'desc' },
          ],
        });
        let list = sorted_data?.data;
        dispatch(setOfflineOrderList(list || []));
      }
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

const getDuplicateProductNames = (items) => {
  if (!items || items?.length === 0) return '';
  let productIds = items?.map((x) => x?.product?.id);
  const duplicateIds = productIds?.filter((item, index) => productIds?.indexOf(item) !== index);
  if (duplicateIds?.length > 0) {
    let duplicateProductNames = [];
    items?.forEach((x) =>
      duplicateIds?.includes(x?.product?.id) ? duplicateProductNames?.push(x?.product?.name) : null,
    );
    duplicateProductNames = duplicateProductNames?.filter((v, i, a) => a.indexOf(v) === i)?.join(', ');
    return duplicateProductNames;
  }
};

/**
 * @desc company - create Order
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createOrder = (company_id, obj) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    if (!obj || !obj.number) {
      dispatchOrderListError('Order Number is required', dispatch, isModalError);
      return;
    } else if (!obj || !obj.date) {
      dispatchOrderListError('Date is required', dispatch, isModalError);
      return;
    } else if (!obj.items || obj.items.length === 0) {
      dispatchOrderListError('Select atleast one item', dispatch, isModalError);
      return;
    } else if (obj.items && getIsCustomerRequiredMsg(obj)) {
      dispatchOrderListError('Customer is required', dispatch, isModalError);
      return;
    } else if (obj?.items?.length > 0) {
      const duplicateProducts = getDuplicateProductNames(obj?.items);
      if (duplicateProducts) {
        dispatchOrderListError(`Same product not allowed multiple times: ${duplicateProducts}`, dispatch, isModalError);
        return;
      }
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/${company_id}`, obj);
    const { data } = response.data;
    if (data) {
      return data;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Delete offline Order
 * @param {*} company_id CompanyId
 * @param {*} item_id sales order ItemId
 */
export const deleteOfflineOrder = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/order-list/${company_id}/item/${item_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    console.log('ERROR', e);
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get offline Order list by contact
 * @param {*} company_id company id
 */
export const getOfflineOrderListByContact = (company_id, contactId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/${company_id}/pending/${contactId}`, { signal });
    const { data } = response.data;
    return data || [];
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get online Order list by contact
 * @param {*} company_id company id
 */
export const getOnlineOrderListByContact = (company_id, contactId, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/pending/${contactId}`, {
      signal,
    });
    const { data } = response.data;
    return data || [];
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Single Partial Order
 * @param {*} company_id company id
 */
export const getSinglePartialOrder = (company_id, orderId, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/${company_id}/partial-order/${orderId}`);
    const { data } = response.data;
    if (data) return data || [];
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to get Partial Order, please try again later',
      dispatch,
      isModalError,
    );
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

function getIsCustomerRequiredMsg(obj) {
  if (!obj) return;

  let isMsg = false;
  if (obj.items && obj.items.length > 0) {
    obj.items.forEach((x) => {
      if (isEmpty(x.contact)) {
        isMsg = true;
      }
    });
  }
  return isMsg;
}

/**
 * @desc Online Order - Load Entry
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const loadEntry = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (!obj) {
      dispatchOrderListError('Data is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/load-data-into-form`, obj);
    const { data } = response.data;
    if (data) {
      dispatch(setLoadedEntryData(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Online Order - Get Contact By Email and Phone Number
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const getContactByEmailPhone = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    if (!payload.email && payload.phone_number.length === 0) {
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/contacts/${company_id}/email-phone`, payload);
    const { data } = response.data;
    if (data) {
      return data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Online Order - Get Channel List
 * @param {*} company_id company id
 */
export const getChannelList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const state = store.getState();
    const { channelList } = state.orderList;
    if (channelList && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/channels`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setChannelList(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Online Order - Get Next Number
 * @param {*} company_id company id
 */
export const getNextOrderNumber = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/next-number`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setNextOrderNumber(data.number));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Online Order - Get Couriers List
 * @param {*} company_id company id
 */
export const getCouriersList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    const state = store.getState();
    const { couriersList } = state.orderList;
    if (couriersList && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/couriers`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setCouriersList(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Online Order - Get Payment Gateway List
 * @param {*} company_id company id
 */
export const getPaymentGatewayList = (company_id, isRefresh, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    const state = store.getState();
    const { paymentGatewayList } = state.orderList;
    if (paymentGatewayList && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/payment-gateways`, {
      signal,
    });
    const { data } = response.data;
    if (data) {
      dispatch(setPaymentGatewayList(data));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc company - create New Online Order
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createNewOnlineOrder = (company_id, obj) => async (dispatch) => {
  const isModalError = false;
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    const isPrepaid = obj?.payment_mode === PAYMENT_MODE.PREPAID;

    if (!obj) {
      dispatchOrderListError('Fill The Required Data', dispatch, isModalError);
      return;
    } else if (!obj.number) {
      dispatchOrderListError('Order Number is Required', dispatch, isModalError);
      return;
    } else if (!obj.contact.first_name) {
      dispatchOrderListError('First Name is Required', dispatch, isModalError);
      return;
    } else if (
      !obj.contact.mobile_numbers ||
      !obj.contact.mobile_numbers?.length === 0 ||
      obj.contact.mobile_numbers[0].number.length !== 10
    ) {
      dispatchOrderListError('Please enter valid mobile number(s)', dispatch, isModalError);
      return;
    } else if (!obj.channel) {
      dispatchOrderListError('Channel Name is Required', dispatch, isModalError);
      return;
    } else if (obj.tracking_number && !obj.courier) {
      dispatchOrderListError('Courier is Required', dispatch, isModalError);
      return;
    } else if (!obj.items || obj.items.length === 0) {
      dispatchOrderListError('Select Atleast One Product', dispatch, isModalError);
      return;
    } else if (!obj.priority) {
      dispatchOrderListError('priority is Required', dispatch, isModalError);
      return;
    } else if (isPrepaid && !obj.payment_gateway) {
      dispatchOrderListError('Payment Gateway is Required', dispatch);
      return;
    } else if (isPrepaid && !obj.payment_reference) {
      dispatchOrderListError('Payment Reference is Required', dispatch);
      return;
    } else if (obj.contact.contact_type === 0) {
      for (let i = 0; i < obj.contact.addresses.length; i++) {
        const { addressLine1, city, state, pincode, country } = obj.contact.addresses[i];
        if (isEmpty(pincode)) {
          dispatchOrderListError('Pincode is Required', dispatch, isModalError);
          return;
        } else if (isEmpty(city)) {
          dispatchOrderListError('City is Required', dispatch, isModalError);
          return;
        } else if (isEmpty(addressLine1)) {
          dispatchOrderListError('Address(es) is Required', dispatch, isModalError);
          return;
        } else if (isEmpty(country)) {
          dispatchOrderListError('Country is Required', dispatch, isModalError);
          return;
        } else if (country === 'IN' && isEmpty(state)) {
          dispatchOrderListError('State is Required', dispatch, isModalError);
          return;
        }
      }
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}`, obj);
    const { data } = response.data;
    if (data) {
      return data;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Print Online Pickup List
 * @param {*} company_id company id
 */
export const saveOnlinePickupList = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/save-pickup-list`, payload);
    let { data } = response.data;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to generate Pick up list pdf, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Get Online Pickup List History
 * @param {*} company_id company id
 */
export const getOnlineSavedPickUpListHistory = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/pickup-lists`, { signal });
    let { data } = response.data;
    if (data) {
      dispatch(setOnlinePickUpListHistory(data.reverse() || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to fetch Pickup List history, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Print Selected Pickup List History
 * @param {*} company_id company id
 */
export const printSelectedpickupList = (company_id, pickUpListId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/pickup-list/${pickUpListId}`);
    let { data } = response.data;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to fetch Pickup List, please try again later', dispatch);
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc offline order update Status
 * @param {*} company_id company id
 */
export const updateOfflineOrderStatus = (company_id, order_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/order-list/${company_id}/status/${order_id}`, obj);
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc next offline Order No
 * @param {*} company_id CompanyId
 */
export const getNextOfflineOrderNumber = (company_id, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/${company_id}/next-number`, { signal });
    const { data } = response.data;
    dispatch(setNextOfflineOrderNumber(data.number));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Online Order list
 * @param {*} company_id company id
 */
export const getOnlineOrderList =
  (company_id, isUpdateRedux = true, signal) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearResponseMessage());
      dispatch(setOrderListLoader(true));

      const onlineAllOrders = getState()?.orderList?.onlineAllOrders || [];

      //Set list from local cache
      if ((!onlineAllOrders || onlineAllOrders?.length === 0) && isUpdateRedux) {
        const local_data = await OnlineOrdersCacheService.getInstance()?.getItems();
        if (local_data && local_data.length > 0) {
          const sorted_data = process(local_data, {
            sort: [
              { field: 'date', dir: 'desc' },
              { field: 'number', dir: 'desc' },
            ],
          });
          let list = sorted_data?.data;
          dispatch(setOnlineOrderList(list || []));
        }
      }

      const lastUpdatedTimeFromLocalStorage = await OnlineOrdersCacheService.getInstance()?.getLastUpdatedTime();
      const lastUpdatedTime = lastUpdatedTimeFromLocalStorage || 0;

      const response = await axios.get(
        `${REACT_APP_APIURL}/order-list/online/${company_id}/last-updated-list/${lastUpdatedTime}`,
        { signal },
      );
      const { updated, archived, lastUpdatedOn } = response.data.data;
      let hasUpdatedData = false;
      if (lastUpdatedTime === 0) {
        await OnlineOrdersCacheService.getInstance()?.setItems(updated, company_id);
        hasUpdatedData = true;
      } else {
        await OnlineOrdersCacheService.getInstance()?.updateItems(updated, archived, company_id);
        if ((updated && updated.length > 0) || (archived && archived.length > 0)) {
          hasUpdatedData = true;
        }
      }
      await OnlineOrdersCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn, company_id);

      if (hasUpdatedData) {
        //Set list from local cache
        const local_data = await OnlineOrdersCacheService.getInstance()?.getItems();
        if (local_data && local_data.length > 0) {
          const sorted_data = process(local_data, {
            sort: [
              { field: 'date', dir: 'desc' },
              { field: 'number', dir: 'desc' },
            ],
          });
          let list = sorted_data?.data;
          dispatch(setOnlineOrderList(list || []));
        }
      }

      // if (ajaxRequest) {
      //   ajaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
      // }
      // ajaxRequest = axios.CancelToken.source();
      // const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}`, {
      //   cancelToken: ajaxRequest.token,
      // });
      // const { data } = response.data;
      // dispatch(setOnlineOrderList(data || []));
      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
      return false;
    } finally {
      dispatch(setOrderListLoader(false));
    }
  };

/**
 * @desc online update order Status
 * @param {*} company_id company id
 */
export const updateOnlineOrderStatus = (company_id, order_id, obj) => async (dispatch) => {
  let isModalError = false;
  if (obj.status === ORDER_STATUS_ID.CANCELLED) {
    isModalError = true;
  }
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (!obj || !obj.status) {
      dispatchOrderListError('Status is required', dispatch, isModalError);
      return;
    } else if (obj.status === ORDER_STATUS_ID.CANCELLED && !obj.reason) {
      dispatchOrderListError('Reason is required', dispatch, isModalError);
      return;
    }
    const response = await axios.put(`${REACT_APP_APIURL}/order-list/online/${company_id}/status/${order_id}`, obj);
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Add Tracking Number for Online Orders
 * @param {*} company_id company id
 */
export const addTrackingNumber = (company_id, order_id, obj, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/tracking-number/${order_id}`,
      obj,
    );
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Print Shipping Label
 * @param {*} company_id company id
 */
export const printShippingLabel = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (payload?.orders?.length === 0) {
      dispatchOrderListError('Order Id is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/packing-slip`, payload);
    const { data } = response.data;
    if (data) {
      if (data?.packages?.length === 0) dispatchOrderListError('Packaging label not found', dispatch);
      return data;
    } else {
      return null;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Print Bulk Shipping Invoice
 * @param {*} company_id company id
 */
export const printBulkShippingInvoice = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (payload?.invoiceIds?.length === 0) {
      dispatchOrderListError('Invoice Id is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/sales/${company_id}/pdf-preview/bulk`, payload, {
      responseType: 'blob',
    });
    const { data } = response;
    if (data) {
      var blob = new Blob([data], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      window.open(url);
      return data;
    }
    return null;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc online update bulk order Status
 * @param {*} company_id company id
 */
export const updateBulkOrderStatus = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (!obj || !obj.status) {
      dispatchOrderListError('Status is required', dispatch);
      return;
    }
    if (!obj.orders || !obj.orders.length === 0) {
      dispatchOrderListError('Select Atleast One Order', dispatch);
      return;
    } else if (obj.status === ORDER_STATUS_ID.CANCELLED && !obj.reason) {
      dispatchOrderListError('Reason is required', dispatch);
      return;
    }
    const response = await axios.put(`${REACT_APP_APIURL}/order-list/online/${company_id}/status-bulk`, obj);
    const { data, message } = response.data;
    if (data) {
      dispatchOrderListSuccess(message, dispatch);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Online Order preview
 * @param {*} company_id company id
 * @param {*} orderId order id
 */
export const getOnlineOrdersDetails = (company_id, orderId, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/preview/${orderId}`, {
      signal,
    });
    const { data } = response.data;
    dispatch(setOnlineOrdersPreview(data || {}));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Create online order's COD advance payment
 * @param {*} company_id company id
 */
export const createOnlineOrderPayment = (company_id, order_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/add-advance-payment/${order_id}`,
      obj,
    );
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc delete online order's COD advance payment
 * @param {*} company_id company id
 */
export const deleteOnlineOrderPayment = (company_id, payment_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    await axios.delete(`${REACT_APP_APIURL}/order-list/online/${company_id}/remove-advance-payment/${payment_id}`);
    return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Offline Order preview
 * @param {*} company_id company id
 * @param {*} orderId order id
 */
export const getOfflineOrdersDetails = (company_id, orderId, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/${company_id}/preview/${orderId}`, { signal });
    const { data } = response.data;
    let ordersDetails = data;
    if (ordersDetails) {
      const total_amount = ordersDetails.invoice.items
        ?.filter((x) => x.product !== undefined)
        .reduce((sum, val) => sum + Number(val.qty * val.product.price_sales), 0);
      ordersDetails.invoice.total_amount = total_amount;
      ordersDetails.invoice.items =
        ordersDetails.invoice.items &&
        ordersDetails.invoice.items.length > 0 &&
        ordersDetails.invoice.items.map((x) => {
          return {
            ...x,
            rate: x.product.price_sales,
            amount: x.qty * x.product.price_sales,
          };
        });
    }
    dispatch(setOfflineOrdersPreview(ordersDetails || {}));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Delete online Order
 * @param {*} company_id CompanyId
 * @param {*} item_id sales order ItemId
 */
export const deleteOnlineOrder = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/order-list/online/${company_id}/item/${item_id}`);
    if (response.data) return true;
    return false;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

let createBulkInvoiceLastRequestStarted;
/**
 * @desc company - create bulk invoice
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createOnlineOrderBulkInvoice = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    //Don't allow user to call two request at time
    const onlineOrderLastRequestedKey = 'online-orders-create-invoice-requested-on';
    let lastRequestStarted = createBulkInvoiceLastRequestStarted || localStorage.getItem(onlineOrderLastRequestedKey);
    let isRequestRunning = false;
    if (lastRequestStarted && lastRequestStarted !== null) {
      const lastRequestDateTime = moment(lastRequestStarted);
      const diff = moment().diff(lastRequestDateTime, 'seconds');
      if (diff < 60) isRequestRunning = true;
    }
    if (isRequestRunning) {
      dispatchOrderListError('Please wait till last request completed, Try again after 1 minute', dispatch);
      return false;
    }
    createBulkInvoiceLastRequestStarted = new Date();
    localStorage.setItem(onlineOrderLastRequestedKey, new Date().toISOString());

    if (!obj || !obj?.orders?.length === 0) {
      dispatchOrderListError('Orders is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/bulk`, obj);
    const { data, message } = response.data;
    if (data) {
      dispatchOrderListSuccess(message, dispatch);
      return data;
    } else {
      return false;
    }
  } catch (e) {
    let orderNumbers = [];
    if (e.response?.data?.data) {
      e.response?.data?.data?.length > 0 &&
        e.response?.data?.data?.forEach((x) => {
          orderNumbers.push(x.number);
          return x.number;
        });
    }
    let errorMsg = e.response.data.message + ' ' + orderNumbers.join(',');
    dispatchOrderListError(errorMsg || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc get single order detail
 * @param {*} company_id CompanyId
 */
export const getOrderDetail = (company_id, orderId, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/item/${orderId}`, { signal });
    const { data } = response.data;
    dispatch(setOnlineOrderDetail(data));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc online order update
 * @param {*} company_id company id
 */
export const updateOrderDetail = (company_id, order_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    const isPrepaid = obj?.payment_mode === PAYMENT_MODE.PREPAID;

    if (!obj) {
      dispatchOrderListError('Fill The Required Data', dispatch);
      return;
    } else if (!obj.number) {
      dispatchOrderListError('Order Number is Required', dispatch);
      return;
    } else if (!obj.contact.first_name) {
      dispatchOrderListError('First Name is Required', dispatch);
      return;
    } else if (obj.contact.mobile_numbers[0].number.length !== 10) {
      dispatchOrderListError('Please enter valid mobile number(s)', dispatch);
      return;
    } else if (!obj.channel) {
      dispatchOrderListError('Channel Name is Required', dispatch);
      return;
    } else if (!obj.items || obj.items.length === 0) {
      dispatchOrderListError('Select Atleast One Product', dispatch);
      return;
    } else if (!obj.priority) {
      dispatchOrderListError('priority is Required', dispatch);
      return;
    } else if (isPrepaid && !obj.payment_gateway) {
      dispatchOrderListError('Payment Gateway is Required', dispatch);
      return;
    } else if (isPrepaid && !obj.payment_reference) {
      dispatchOrderListError('Payment Reference is Required', dispatch);
      return;
    } else if (obj.contact.contact_type === 0) {
      for (let i = 0; i < obj.contact.addresses.length; i++) {
        const { addressLine1, city, state, pincode, country } = obj.contact.addresses[i];
        if (isEmpty(pincode)) {
          dispatchOrderListError('Pincode is Required', dispatch);
          return;
        } else if (isEmpty(city)) {
          dispatchOrderListError('City is Required', dispatch);
          return;
        } else if (isEmpty(addressLine1)) {
          dispatchOrderListError('Address(es) is Required', dispatch);
          return;
        } else if (isEmpty(country)) {
          dispatchOrderListError('Country is Required', dispatch);
          return;
        } else if (country === 'IN' && isEmpty(state)) {
          dispatchOrderListError('State is Required', dispatch);
          return;
        }
      }
    }
    const response = await axios.put(`${REACT_APP_APIURL}/order-list/online/${company_id}/item/${order_id}`, obj);
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc get COD based on pincode
 * @param {*} company_id CompanyId
 * @param {*} pincode pincode
 */
export const getCodBasedOnPincode = (company_id, pincode) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/shipping/${company_id}/item/${pincode}`);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc company - offline create bulk invoice
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createOfflineOrderBulkInvoice = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    if (!obj || !obj.orders.length === 0) {
      dispatchOrderListError('Orders is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/${company_id}/bulk`, obj);
    const { data } = response.data;
    if (data) {
      return data;
    } else {
      return false;
    }
  } catch (e) {
    let orderNumbers = [];
    if (e.response?.data?.data) {
      e.response?.data?.data?.length > 0 &&
        e.response?.data?.data?.forEach((x) => {
          orderNumbers.push(x.number);
          return x.number;
        });
    }
    let errorMsg = e.response.data.message + ' ' + orderNumbers.join(',');
    dispatchOrderListError(errorMsg || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Online Order Tag Option
 * @param {*} CompanyId
 */
export const getOnlineOrderTagOption = (company_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setOrderListLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/order-list/online/${company_id}/tags`, { signal });
    const { data } = response.data;
    let itemWithLabel = [];
    itemWithLabel = data && data.map((item) => ({ label: item, value: item }));
    if (itemWithLabel) dispatch(setOnlineOrderTagOption(itemWithLabel || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc get single order detail
 * @param {*} company_id CompanyId
 */
export const getOfflineOrderDetail = (company_id, orderId, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/order-list/${company_id}/item/${orderId}`, { signal });
    const { data } = response.data;
    dispatch(setOfflineOrderDetail(data));
    dispatch(setOfflineOrderItems(data?.items || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to get this Order Detail, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc update offline order item
 * @param {*} company_id CompanyId
 */
export const updateOfflineOrder = (company_id, orderId, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    if (!payload || !payload.number) {
      dispatchOrderListError('Order Number is required', dispatch, isModalError);
      return;
    } else if (!payload || !payload?.date) {
      dispatchOrderListError('Date is required', dispatch, isModalError);
      return;
    } else if (!payload?.items || payload?.items?.length === 0) {
      dispatchOrderListError('Select atleast one item', dispatch, isModalError);
      return;
    } else if (payload?.items && getIsCustomerRequiredMsg(payload)) {
      dispatchOrderListError('Customer is required', dispatch, isModalError);
      return;
    } else if (payload?.items?.length > 0) {
      const duplicateProducts = getDuplicateProductNames(payload?.items);
      if (duplicateProducts) {
        dispatchOrderListError(`Same product not allowed multiple times: ${duplicateProducts}`, dispatch, isModalError);
        return;
      }
    }
    const response = await axios.put(`${REACT_APP_APIURL}/order-list/${company_id}/item/${orderId}`, payload);
    const { data } = response.data;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to update this order, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc search and filter Online Orders on Server
 * @param {*} company_id CompanyId
 */
export const searchFilterOnlineOrdersOnServer = (company_id, searchText, filterData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const payload = {
      search: searchText,
      filter: filterData,
    };
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/search`, payload);
    const { data } = response.data;
    if (data) {
      const sorted_data = process(data, {
        sort: [
          { field: 'date', dir: 'desc' },
          { field: 'number', dir: 'desc' },
        ],
      });
      let list = sorted_data?.data;
      dispatch(setFilteredSearchResult(list || []));
      return true;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to search online orders, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc search and filter Offline Orders on Server
 * @param {*} company_id CompanyId
 */
export const searchFilterOfflineOrdersOnServer = (company_id, searchText, filterData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const payload = {
      search: searchText,
      filter: filterData,
    };
    const response = await axios.post(`${REACT_APP_APIURL}/order-list/${company_id}/search`, payload);
    const { data } = response.data;
    if (data) {
      const sorted_data = process(data, {
        sort: [
          { field: 'date', dir: 'desc' },
          { field: 'number', dir: 'desc' },
        ],
      });
      let list = sorted_data?.data;
      dispatch(setFilteredOfflineOrderList(list || []));
      return true;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to search offline orders, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc create Online Comment
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createOnlineComment = (company_id, order_id, obj, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    if (!obj || !obj.comment) {
      dispatchOrderListError('Comment is required', dispatch, isModalError);
      return;
    }

    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/comment/${order_id}`, obj);
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to Add comment, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Update Online Comment
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const updateOnlineComment = (company_id, order_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    if (!payload || !payload.comment) {
      dispatchOrderListError('Comment is required', dispatch);
      return;
    }

    const response = await axios.put(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/comment/${order_id}/${payload?.id}`,
      payload,
    );
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to edit comment, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Delete Online Comment items
 * @param {*} obj CompanyId , ItemId
 */
export const deleteOnlineComment = (company_id, order_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    const response = await axios.delete(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/comment/${order_id}/${item_id}`,
    );
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to Delete comment, please try again later', dispatch);

    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc create Offline Comment
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const createOfflineComment = (company_id, order_id, obj, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    if (!obj || !obj.comment) {
      dispatchOrderListError('Comment is required', dispatch, isModalError);
      return;
    }

    const response = await axios.post(`${REACT_APP_APIURL}/order-list/${company_id}/comment/${order_id}`, obj);
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to Add comment, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Update Offline Comment
 * @param {*} company_id company id
 * @param {*} obj Data Obj
 */
export const updateOfflineComment = (company_id, order_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    if (!payload || !payload.comment) {
      dispatchOrderListError('Comment is required', dispatch);
      return;
    }

    const response = await axios.put(
      `${REACT_APP_APIURL}/order-list/${company_id}/comment/${order_id}/${payload?.id}`,
      payload,
    );
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to edit comment, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Delete Offline Comment items
 * @param {*} obj CompanyId , ItemId
 */
export const deleteOfflineComment = (company_id, order_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/order-list/${company_id}/comment/${order_id}/${item_id}`);
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Unable to Delete comment, please try again later', dispatch);

    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Make Confirmation Call
 * @param {*} company_id company id
 * @param {*} payload Data Obj
 */
export const makeConfirmationCall = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    if (!payload || !payload.order_id) {
      dispatchOrderListError('order_id is required', dispatch);
      return;
    }

    const response = await axios.post(`${REACT_APP_APIURL}/order-list/online/${company_id}/confirmation-call`, payload);
    const { data } = response;
    if (data) {
      dispatchOrderListSuccess(data.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to make confirmation call, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  Get Online Order Call History
 * @param {*} obj CompanyId , ItemId
 */
export const getOnlineOrderCallHistory = (company_id, order_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setOrderListLoader(true));

    const response = await axios.get(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/confirmation-call/${order_id}`,
      { signal },
    );
    const { data } = response.data;
    if (data) {
      dispatch(setOnlineOrderCallHistory(data));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchOrderListError(
      getAPIResponseError(e) || 'Unable to Get Online Order Call History, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc Update Lock Status Online Orders
 * @param {*} company_id selected_id payload
 */
export const updateLockStatus = (company_id, selected_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/order-list/online/${company_id}/update-lock-status/${selected_id}`,
      payload,
    );
    const { data } = response.data;
    if (data) return true;
  } catch (e) {
    dispatchOrderListError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

function dispatchOrderListError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}

function dispatchOrderListSuccess(msg, dispatch, isModalSuccess = false) {
  isModalSuccess ? dispatch(setItemSucessMessage(msg)) : dispatch(setSuccessMessage(msg));
  setTimeout(() => {
    isModalSuccess ? dispatch(setItemSucessMessage()) : dispatch(setSuccessMessage());
  }, 5000);
}
