import * as Actions from './types';

/**
 * @desc Set Purchase Challan Loader
 */
export const setPurchaseChallanLoader = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_LOADER,
    payload,
  };
};

/**
 * @desc Set Purchase Challan List Updated Flag
 */
export const setPurchaseChallanListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Purchase Challan List
 */
export const setPurchaseChallanList = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_LIST,
    payload,
  };
};

/**
 * @desc Clear Purchase challan data
 */
export const clearPurchaseChallanData = (payload = {}) => {
  return {
    type: Actions.CLEAR_PURCHASE_CHALLAN_DATA,
    payload,
  };
};

/**
 * @desc Set Purchase Challan item
 */
export const setPurchaseChallanItem = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_ITEM,
    payload,
  };
};

/**
 * @desc Update Purchase Challan item
 */
export const updatePurchaseChallanItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_PURCHASE_CHALLAN_ITEM,
    payload,
  };
};

/**
 * @desc Clear Purchase Challan item
 */
export const clearPurchaseChallanItem = () => {
  return {
    type: Actions.CLEAR_PURCHASE_CHALLAN_ITEM,
  };
};

/**
 * @desc Set Purchase challan preview
 */
export const setPurchaseChallanPreview = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_PREVIEW,
    payload,
  };
};

/**
 * @desc Set Invoice Items List(Product)
 */
export const setInvoiceProductsList = (payload) => {
  return {
    type: Actions.SET_INVOICE_PRODUCT_LIST,
    payload,
  };
};

/**
 * @desc Set Purchase Challan Filtered List
 */
export const setPurchaseChallanFilteredList = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Get Purchase Challan Tag Option
 */
export const setPurchaseChallanTagOption = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_TAG_OPTIONS,
    payload,
  };
};

/**
 * @desc Set Purchase Challan items
 */
export const setPurchaseChallanItems = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_ITEMS,
    payload,
  };
};

/**
 * @desc Set Purchase Challan items row
 */
export const setPurchaseChallanItemsRow = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_ITEMS_ROW,
    payload,
  };
};
