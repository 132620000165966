import * as Actions from './types';

/**
 * @desc Set Activity Loader
 */
export const setActivityLoader = (payload) => {
  return {
    type: Actions.SET_ACTIVITY_LOADER,
    payload,
  };
};

/**
 * @desc Update Activity Filtered List
 */
export const updateActivityFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_HISTORY_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Clear Activity Data
 */
export const clearActivityData = () => {
  return {
    type: Actions.CLEAR_ACTIVITY_DATA,
  };
};

/**
 * @desc set Activity History List
 */
export const setActivityHistoryList = (payload) => {
  return {
    type: Actions.SET_ACTIVITY_HISTORY_LIST,
    payload,
  };
};
