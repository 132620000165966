import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import LocalDbService, { INVENTORY_TRANSACTION_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton;
let organizationId;

export default class InventoryTransactionCacheService extends LocalDbService {
  constructor(props) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = INVENTORY_TRANSACTION_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      if (!singleton || organizationId !== companyId) {
        organizationId = companyId;
        singleton = new InventoryTransactionCacheService({ organizationId: companyId });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getItems() {
    const result = await this.get();
    return result;
  }

  async setItems(value, companyId) {
    if (!value || companyId !== organizationId) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateItems(newList, archivedList, companyId) {
    if (companyId !== organizationId) return false;
    await this.syncList(newList, archivedList);
  }

  async getLastUpdatedTime() {
    const result = await super.getLastUpdatedTime(UPDATE_ON_ID.INVENTORY_TRANSACTIONS);
    return result;
  }

  async setLastUpdatedTime(value, companyId) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(UPDATE_ON_ID.INVENTORY_TRANSACTIONS, value);
    return result;
  }
}
