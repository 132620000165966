import _ from 'lodash';
import * as Actions from './types';
/**
 * @desc Set Inventory Loader
 */
export const setInventoryLoader = (payload) => {
  return {
    type: Actions.SET_INVENTORY_LOADER,
    payload,
  };
};

/**
 * @desc Set Inventory List data update flag
 */
export const setInventoryItemsListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_INVENTORY_ITEM_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Inventory Items List
 */
export const setInventoryItemsList = (payload) => {
  return {
    type: Actions.SET_INVENTORY_ITEMS_LIST,
    payload,
  };
};

/**
 * @desc Set Inventory Item data
 */
export const setInventoryItemData = (payload) => {
  return {
    type: Actions.SET_INVENTORY_ITEM,
    payload,
  };
};

/**
 * @desc Set Warehouse Item data
 */
export const setWarehouseItemData = (payload) => {
  return {
    type: Actions.SET_WAREHOUSE_ITEM,
    payload,
  };
};

/**
 * @desc Update Warehouse item
 */
export const updateWarehouseItemData = (payload = {}) => {
  return {
    type: Actions.UPDATE_WAREHOUSE_ITEM,
    payload,
  };
};

/**
 * @desc Clear Warehouse Item data
 */
export const clearWarehouseItemData = () => {
  return {
    type: Actions.CLEAR_WAREHOUSE_ITEM_DATA,
  };
};

/**
 * @desc Update Inventory item
 */
export const updateInventoryItemData = (payload = {}) => {
  return {
    type: Actions.UPDATE_INVENTORY_ITEM,
    payload,
  };
};

/**
 * @desc Update Inventory List Item
 */
export const updateInventoryListItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_INVENTORY_LIST_ITEM,
    payload,
  };
};

/**
 * @desc Clear Inventory Item data
 */
export const clearInventoryItemData = () => {
  return {
    type: Actions.CLEAR_INVENTORY_ITEM_DATA,
  };
};

/**
 * @desc update Inventory item Filtered List
 */
export const updateInventoryItemFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_INVENTORY_ITEM_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Clear Inventory Data
 */
export const clearInventoryData = () => {
  return {
    type: Actions.CLEAR_INVENTORY_DATA,
  };
};

/**
 * @desc Set Inventory Product Data(for item detail page)
 */
export const setInventoryProductDetails = (payload) => {
  return {
    type: Actions.SET_INVENTORY_PRODUCT_DETAILS,
    payload,
  };
};

/**
 * @desc Set bulk Inventory Product detail
 */
export const setBulkProductDetail = (payload) => {
  return {
    type: Actions.SET_BULK_INVENTORY_PRODUCT_DETAILS,
    payload,
  };
};

/**
 * @desc Set Inventory History List
 */
export const setInventoryHistoryList = (payload) => {
  return {
    type: Actions.SET_INVENTORY_HISTORY_LIST,
    payload,
  };
};

/**
 * @desc Get Inventory Items Category Option
 */
export const setCategoryOption = (payload) => {
  return {
    type: Actions.SET_INVENTORY_PRODUCT_CATEGORY_OPTION,
    payload,
  };
};

/**
 * @desc Get Inventory Items Catalogue Option
 */
export const setCatalogueOption = (payload) => {
  return {
    type: Actions.SET_INVENTORY_PRODUCT_CATALOGUE_OPTION,
    payload,
  };
};

/**
 * @desc Get Inventory Items Packing Bag Size
 */
export const setPackingBagSizes = (payload) => {
  return {
    type: Actions.SET_INVENTORY_PRODUCT_PACKING_BAG_SIZES,
    payload,
  };
};

/**
 * @desc Get Inventory Items Tag Option
 */
export const setTagOption = (payload) => {
  return {
    type: Actions.SET_INVENTORY_PRODUCT_TAG_OPTION,
    payload,
  };
};

/**
 * @desc Set Inventory Mapped Product List
 */
export const setMappedProductList = (payload) => {
  return {
    type: Actions.SET_MAPPED_PRODUCT_LIST,
    payload,
  };
};

/**
 * @desc Set Inventory Mapped Product List
 */
export const setFilteredMappedProductList = (payload) => {
  return {
    type: Actions.SET_FILTERED_MAPPED_PRODUCT_LIST,
    payload,
  };
};

const setRacksChild = (list, item) => {
  if (list && item) {
    const childs = list.filter((x) => x.parent_id === item.id);
    item.childs = childs || [];
    item.childs = item?.childs?.map((x) => {
      return { ...x, priority: item?.priority };
    });
    item.childs.forEach((child) => {
      setRacksChild(list, child);
    });
  }
};

const setRacksBreadcrumb = (list, item, name, warehouse) => {
  if (list && item) {
    const itemObj = { ...item, label: name, warehouse };
    list.push(itemObj);
    item.childs.forEach((child) => {
      const child_name = `${name} | ${child.name}`;
      setRacksBreadcrumb(list, child, child_name, warehouse);
    });
  }
};

const setWarehouseList = (payload) => {
  let list = [];
  let childList = [];
  if (payload && payload.length > 0) {
    list = [...payload];

    const warehouse_list = list.filter((x) => !x.parent_id);

    warehouse_list.forEach((item) => {
      setRacksChild(list, item);
    });
    const listWithBreadcrumb = [];
    warehouse_list.forEach((item) => {
      setRacksBreadcrumb(listWithBreadcrumb, item, item.name, item);
    });
    list = [...listWithBreadcrumb];
    list = _.orderBy(list, ['priority'], ['asc']);
    childList = list?.filter((x) => x?.childs === undefined || x?.childs?.length === 0);
  }
  return {
    warehouse_list: list,
    child_warehouse_list: childList,
  };
};

/**
 * @desc Set Inventory Warehouse List
 */
export const setInventoryWarehouseList = (payload) => {
  const warehouseLists = setWarehouseList(payload);
  return {
    type: Actions.SET_INVENTORY_WAREHOUSE_LIST,
    payload: warehouseLists,
  };
};

/**
 * @desc Set Inventory Warehouse List With Disable
 */
export const setInventoryWarehouseListWithDisable = (payload) => {
  const warehouseLists = setWarehouseList(payload);
  return {
    type: Actions.SET_INVENTORY_WAREHOUSE_LIST_WITH_DISABLE,
    payload: warehouseLists,
  };
};
