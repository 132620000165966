import * as Actions from './types';

/**
 * @desc Set Sales Order Loader
 */
export const setSalesOrderLoader = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER_LOADER,
    payload,
  };
};

/**
 * @desc Set sales order List
 */
export const setSalesOrderList = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER_LIST,
    payload,
  };
};

/**
 * @desc update Sales Order Filtered List
 */
export const updateSalesOrderFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_SALES_ORDER_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set sales order
 */
export const setSalesOrderItem = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER,
    payload,
  };
};

/**
 * @desc Update sales order
 */
export const updateSalesOrderItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_SALES_ORDER,
    payload,
  };
};

/**
 * @desc Clear sales order item
 */
export const clearSalesOrderItem = () => {
  return {
    type: Actions.CLEAR_SALES_ORDER,
  };
};

/**
 * @desc Set Sales Order Number
 */
export const setSalesOrderNumber = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER_NUMBER,
    payload,
  };
};

/**
 * @desc Set sales order preview
 */
export const setSalesOrderPreview = (payload) => {
  return {
    type: Actions.SET_SALES_ORDER_PREVIEW,
    payload,
  };
};

/**
 * @desc Clear sales order data
 */
export const clearSalesOrderData = () => {
  return {
    type: Actions.CLEAR_SALES_ORDER_DATA,
  };
};
