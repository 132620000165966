import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  journalTransactionsList: undefined,
  filteredList: undefined,
  journalTransaction: {
    id: undefined,
    date: new Date(),
    description: '',
    items: [],
    total_credit: 0,
    total_debit: 0,
  },
  journalTransactionItems: [],
  journalTransactionDetails: undefined,
};

export const journalTransactionReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_JOURNAL_TRANSACTION_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_JOURNAL_TRANSACTION_LIST:
      return {
        ...state,
        journalTransactionsList: action.payload,
      };
    case Actions.SET_JOURNAL_TRANSACTION_FILTERED_LIST:
      return {
        ...state,
        filteredList: action.payload,
      };
    case Actions.SET_JOURNAL_TRANSACTION_ITEM:
      return {
        ...state,
        journalTransaction: action.payload,
      };
    case Actions.UPDATE_JOURNAL_TRANSACTION_ITEM:
      return {
        ...state,
        journalTransaction: { ...state.journalTransaction, ...action.payload },
      };
    case Actions.CLEAR_JOURNAL_TRANSACTION_ITEM:
      const journalTransactionData = JSON.parse(JSON.stringify(initialState.journalTransaction));
      const journalTransactionItemsData = JSON.parse(JSON.stringify(initialState.journalTransactionItems));
      return {
        ...state,
        journalTransactionItems: journalTransactionItemsData,
        journalTransaction: journalTransactionData,
      };
    case Actions.SET_JOURNAL_TRANSACTION_ITEM_DETAILS:
      return {
        ...state,
        journalTransactionDetails: action.payload,
      };
    case Actions.SET_JOURNAL_TRANSACTION_ITEMS:
      return {
        ...state,
        journalTransactionItems: action.payload,
      };
    case Actions.CLEAR_JOURNAL_TRANSACTION_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
