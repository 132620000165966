import { LANGUAGE_IDS, ONLINE_ORDERS_SORTING_FIELD_IDS } from '../global/constants';

let singleton;
const singletonEnforcer = Symbol();
const parseDataFile = (defaults) => {
  try {
    const settings = localStorage.getItem('UserPreferences');
    if (settings) return JSON.parse(settings);
    return {};
  } catch (error) {
    return defaults;
  }
};

const containsKey = (obj, key) => ({}.hasOwnProperty.call(obj || {}, key));

class UserPreferences {
  constructor(opts) {
    this.defaults = opts.defaults;
    this.data = parseDataFile(opts.defaults);
  }

  get(key, defaultValue) {
    if (containsKey(this.data, key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings) {
    localStorage.setItem('UserPreferences', JSON.stringify(settings));
  }

  set(key, value) {
    this.data = parseDataFile(this.defaults);
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key) {
    delete this.data[key];
    this.save(this.data);
  }

  parseDataFile() {
    this.data = parseDataFile(this.defaults);
  }

  contains(key) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }
}
export default class UserPreferenceSingleton {
  static get ACCESS_TOKEN() {
    return 'access_tokens';
  }
  static get EMAIL() {
    return 'email';
  }
  static get LANGUAGE() {
    return 'language';
  }
  static get DISPLAY_NAME() {
    return 'display_name';
  }
  static get COMPANY_ID() {
    return 'company_id';
  }
  static get PAGE_COUNT() {
    return 'page_count_per_page';
  }
  static get REPORT_DATE_SELECTION() {
    return 'reportDateSelection';
  }
  static get WORKBOOK_DUE_IN_DAYS() {
    return 'workbook_due_in_days';
  }
  static get LAST_CLEANED_LOCAL_DATA_DATE() {
    return 'last_cleaned_local_data_date';
  }
  static get SETTING() {
    return 'setting';
  }
  static get ONLINE_ORDER_SORTING_FIELD_ID() {
    return 'online_order_sorting_field_id';
  }

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.userPreferences = new UserPreferences({
      configName: 'user-preferences',
      defaults: {
        windowBounds: { width: 800, height: 600 },
      },
    });
  }

  static getInstance() {
    if (!singleton) {
      singleton = new UserPreferenceSingleton(singletonEnforcer);
    }
    return singleton;
  }

  getUserToken() {
    return this.userPreferences.get(UserPreferenceSingleton.ACCESS_TOKEN, undefined);
  }

  setUserToken(value) {
    return this.userPreferences.set(UserPreferenceSingleton.ACCESS_TOKEN, value);
  }

  getCompanyId() {
    return this.userPreferences.get(UserPreferenceSingleton.COMPANY_ID, undefined);
  }

  setCompanyId(value) {
    return this.userPreferences.set(UserPreferenceSingleton.COMPANY_ID, value);
  }

  getDisplayName() {
    return this.userPreferences.get(UserPreferenceSingleton.DISPLAY_NAME, undefined);
  }

  setDisplayName(value) {
    return this.userPreferences.set(UserPreferenceSingleton.DISPLAY_NAME, value);
  }

  get(key, defaultValue = null) {
    return this.userPreferences.get(key, defaultValue);
  }

  set(key, value) {
    this.userPreferences.set(key, value);
  }

  setEmail(value) {
    return this.userPreferences.set(UserPreferenceSingleton.EMAIL, value);
  }

  getEmail() {
    return this.userPreferences.get(UserPreferenceSingleton.EMAIL, undefined);
  }

  setLanguage(value) {
    return this.userPreferences.set(UserPreferenceSingleton.LANGUAGE, value);
  }

  getLanguage() {
    return this.userPreferences.get(UserPreferenceSingleton.LANGUAGE, LANGUAGE_IDS.ENG);
  }

  getServerUrl() {
    return this.userPreferences.get(UserPreferenceSingleton.SERVER_URL, null);
  }

  getPageCount() {
    return this.userPreferences.get(UserPreferenceSingleton.PAGE_COUNT, 10);
  }

  setPageCount(value) {
    return this.userPreferences.set(UserPreferenceSingleton.PAGE_COUNT, value);
  }

  getSettingValue(tableId, propName) {
    const settings = this.orgPreferences.get(UserPreferenceSingleton.SETTING, {});
    if (settings[tableId]) {
      return settings[tableId][propName];
    }
    return undefined;
  }

  setSettingValue(tableId, propName, value) {
    const settings = this.orgPreferences.get(UserPreferenceSingleton.SETTING, {});
    if (!settings[tableId]) settings[tableId] = {};
    settings[tableId][propName] = value;
    this.orgPreferences.set(UserPreferenceSingleton.SETTING, settings);
  }

  setReportDateSelection(value) {
    return this.userPreferences.set(UserPreferenceSingleton.REPORT_DATE_SELECTION, value);
  }
  getReportDateSelection() {
    return this.userPreferences.get(UserPreferenceSingleton.REPORT_DATE_SELECTION, undefined);
  }

  getWorkbookDueInDays() {
    return this.userPreferences.get(UserPreferenceSingleton.WORKBOOK_DUE_IN_DAYS, 3);
  }

  setWorkbookDueInDays(value) {
    return this.userPreferences.set(UserPreferenceSingleton.WORKBOOK_DUE_IN_DAYS, value);
  }

  getlastCleanedLocalDataDate() {
    return this.userPreferences.get(
      UserPreferenceSingleton.LAST_CLEANED_LOCAL_DATA_DATE,
      new Date('2022-01-01').toDateString(),
    );
  }

  setlastCleanedLocalDataDate(value) {
    return this.userPreferences.set(UserPreferenceSingleton.LAST_CLEANED_LOCAL_DATA_DATE, value);
  }

  getOnlineOrderSortingFieldId() {
    return this.userPreferences.get(
      UserPreferenceSingleton.ONLINE_ORDER_SORTING_FIELD_ID,
      ONLINE_ORDERS_SORTING_FIELD_IDS.DATE,
    );
  }

  setOnlineOrderSortingFieldId(value) {
    return this.userPreferences.set(UserPreferenceSingleton.ONLINE_ORDER_SORTING_FIELD_ID, value);
  }

  clearStoredUserData() {
    this.userPreferences.remove(UserPreferenceSingleton.ACCESS_TOKEN);
    this.userPreferences.remove(UserPreferenceSingleton.COMPANY_ID);
    this.userPreferences.remove(UserPreferenceSingleton.DISPLAY_NAME);
    this.userPreferences.remove(UserPreferenceSingleton.EMAIL);
    this.userPreferences.remove(UserPreferenceSingleton.SETTING);
    this.userPreferences.remove(UserPreferenceSingleton.PAGE_COUNT);
    this.userPreferences.remove(UserPreferenceSingleton.LANGUAGE);
    this.userPreferences.remove(UserPreferenceSingleton.WORKBOOK_DUE_IN_DAYS);
    this.userPreferences.remove(UserPreferenceSingleton.LAST_CLEANED_LOCAL_DATA_DATE);
    this.userPreferences.remove(UserPreferenceSingleton.ONLINE_ORDER_SORTING_FIELD_ID);
  }
}
