import * as Actions from './types';

/**
 * @desc Set Product Pricing Loader
 */
export const setProductPricingLoader = (payload) => {
  return {
    type: Actions.SET_PRODUCT_PRICING_LOADER,
    payload,
  };
};

/**
 * @desc Set Product Pricing List
 */
export const setProductPricingList = (payload) => {
  return {
    type: Actions.SET_PRODUCT_PRICING_LIST,
    payload,
  };
};

/**
 * @desc Set Product Pricing item
 */
export const setProductPricingItem = (payload) => {
  return {
    type: Actions.SET_PRODUCT_PRICING_ITEM,
    payload,
  };
};

/**
 * @desc Update Product Pricing item
 */
export const updateProductPricingItem = (payload) => {
  return {
    type: Actions.UPDATE_PRODUCT_PRICING_ITEM,
    payload,
  };
};

/**
 * @desc Clear Product Pricing Item data
 */
export const clearProductPricingItemData = () => {
  return {
    type: Actions.CLEAR_PRODUCT_PRICING_ITEM,
  };
};

/**
 * @desc Set Product Pricing preview
 */
export const setProductPricingPreview = (payload) => {
  return {
    type: Actions.SET_PRODUCT_PRICING_PREVIEW,
    payload,
  };
};

/**
 * @desc Clear Product Pricing data
 */
export const clearProductPricingData = (payload = {}) => {
  return {
    type: Actions.CLEAR_PRODUCT_PRICING_DATA,
    payload,
  };
};

/**
 * @desc update Product Pricing Filtered List
 */
export const setProductPricingFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_PRODUCT_PRICING_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Set Product Pricing Values
 */
export const setProductPricingValues = (payload) => {
  return {
    type: Actions.SET_PRODUCT_PRICING_VALUES,
    payload,
  };
};
