import { CONTACT_TYPE_ALL, CONTACT_TYPE_SALES_PERSON } from '../global/constants';
import * as Actions from './../actions/types';

const initialState = {
  loading: false,
  allContactList: undefined,
  customerList: undefined,
  supplierList: undefined,
  accountManagerList: [],
  filteredDataList: undefined,
  salesPersonList: undefined,
  contactDetails: undefined,
  tagOption: undefined,
  isListDataUpdated: false,
  customer: {
    contact_type: CONTACT_TYPE_ALL,
    tds_type: undefined,
    tds: undefined,
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    pan: '',
    gst_no: '',
    tds_rate: '',
    discount: '',
    opening_balance: '',
    credit_limit: '',
    gst_type: '',
    opening_balance_type: '',
    payment_terms: '',
    mobile_numbers: [{ code: '91', number: '' }],
    phone_numbers: [''],
    default_sales_person: undefined,
    addresses: [],
    tags: [],
    is_terminated: undefined,
    terminate_reason: undefined,
  },
  salesPerson: {
    contact_type: CONTACT_TYPE_SALES_PERSON,
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    mobile_numbers: [{ code: '91', number: '' }],
    phone_numbers: [''],
    addresses: [],
    tags: [],
  },
};

export const contactReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_CONTACT_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_CONTACT_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_ALL_CONTACT_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        allContactList: action.payload,
      };
    case Actions.SET_CUSTOMER_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        customerList: action.payload,
      };
    case Actions.SET_SUPPLIER_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        supplierList: action.payload,
      };
    case Actions.SET_ACCOUNT_MANAGER_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        accountManagerList: action.payload,
      };
    case Actions.UPDATE_CONTACT_FILTERED_DATA_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.ADD_CONTACT_ITEM:
      return {
        ...state,
        allContactList: [...state.allContactList, action.payload],
      };
    case Actions.SET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      };
    case Actions.SET_CONTACT_TAGS_OPTION:
      return {
        ...state,
        tagOption: action.payload,
      };
    case Actions.SET_CONTACT_ITEM:
      return {
        ...state,
        customer: action.payload,
      };
    case Actions.UPDATE_CONTACT_ITEM:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload },
      };
    case Actions.CLEAR_CONTACT_ITEM:
      const customerData = JSON.parse(JSON.stringify(initialState.customer));
      return {
        ...state,
        customer: customerData,
      };
    case Actions.SET_SALES_PERSON_LIST:
      return {
        ...state,
        salesPersonList: action.payload,
      };
    case Actions.SET_SALES_PERSON_ITEM:
      return {
        ...state,
        salesPerson: action.payload,
      };
    case Actions.UPDATE_SALES_PERSON_ITEM:
      return {
        ...state,
        salesPerson: { ...state.salesPerson, ...action.payload },
      };
    case Actions.CLEAR_SALES_PERSON_ITEM:
      const salesPersonData = JSON.parse(JSON.stringify(initialState.salesPerson));
      return {
        ...state,
        salesPerson: salesPersonData,
      };
    case Actions.CLEAR_CONTACT_DATA:
      return initialState;
    default:
      return state;
  }
};
