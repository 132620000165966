import * as Actions from './types';

/**
 * @desc Set Credit Notes List
 */
export const setCreditNotesList = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_LIST,
    payload,
  };
};

/**
 * @desc Set Credit Notes List updated Flag
 */
export const setCreditNotesListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Credit Notes Loader
 */
export const setCreditNotesLoader = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_LOADER,
    payload,
  };
};

/**
 * @desc Set Credit Notes invoice
 */
export const setCreditNotesInvoice = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_INVOICE,
    payload,
  };
};

/**
 * @desc Set Credit Notes invoice item
 */
export const setCreditNotesInvoiceItem = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc update Credit Notes Filtered List
 */
export const updateCreditNotesFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_CREDIT_NOTES_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Update Credit Notes invoice item
 */
export const updateCreditNotesInvoiceItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_CREDIT_NOTES_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Credit Notes Invoice Preview
 */
export const setCreditNotesInvoicePreview = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_INVOICE_PREVIEW,
    payload,
  };
};

/**
 * @desc Clear Credit Notes Invoice data
 */
export const clearCreditNotesInvoiceData = () => {
  return {
    type: Actions.CLEAR_CREDIT_NOTES_INVOICE_ITEM,
  };
};

/**
 * @desc Clear Credit Notes Data
 */
export const clearCreditNotesData = () => {
  return {
    type: Actions.CLEAR_CREDIT_NOTES_DATA,
  };
};

/**
 * @desc set Credit Notes Invoice Number
 */
export const setCreditNotesInvoiceNumber = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_INVOICE_NUMBER,
    payload,
  };
};

/**
 * @desc set Due Credit Note
 */
export const setDueCreditNote = (payload) => {
  return {
    type: Actions.SET_DUE_CREDIT_NOTES,
    payload,
  };
};

/**
 * @desc Get Credit Note Tag Option
 */
export const setCreditTagOption = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTE_TAG_OPTIONS,
    payload,
  };
};

/**
 * @desc Set Credit Notes Invoice items
 */
export const setCreditNotesInvoiceItems = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_ITEMS,
    payload,
  };
};

/**
 * @desc Set CreditNotes invoice items row
 */
export const setCreditNotesInvoiceItemsRow = (payload) => {
  return {
    type: Actions.SET_CREDIT_NOTES_ITEMS_ROW,
    payload,
  };
};

/**
 * @desc Set Credit Notes Invoice items
 */
export const setDueCreditNotesList = (payload) => {
  return {
    type: Actions.SET_DUE_CREDIT_NOTES_LIST,
    payload,
  };
};
