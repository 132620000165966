import * as Actions from '../actions/types';
import { ORDER_STATUS_ID, SALES_ORDER_TYPE } from '../global/constants';

const initialState = {
  loading: false,
  salesOrderList: undefined,
  filteredDataList: undefined,
  salesOrder: {
    status: ORDER_STATUS_ID.OPEN,
    type: SALES_ORDER_TYPE.SALES,
    id: undefined,
    contact: undefined,
    number: '',
    date: new Date(),
    sales_person: undefined,
    billing_address: undefined,
    items: [],
    total_qty: 0,
    total_amount: 0,
    notes: '',
  },
  salesOrderPreview: undefined,
};

export const salesOrderReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_SALES_ORDER_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_SALES_ORDER_LIST:
      return {
        ...state,
        salesOrderList: action.payload,
      };
    case Actions.SET_SALES_ORDER:
      return {
        ...state,
        salesOrder: action.payload,
      };
    case Actions.UPDATE_SALES_ORDER:
      return {
        ...state,
        salesOrder: { ...state.salesOrder, ...action.payload },
      };
    case Actions.UPDATE_SALES_ORDER_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_SALES_ORDER:
      const salesOrderData = JSON.parse(JSON.stringify(initialState.salesOrder));
      return {
        ...state,
        salesOrder: salesOrderData,
      };
    case Actions.SET_SALES_ORDER_NUMBER:
      return {
        ...state,
        salesOrder: { ...state.salesOrder, number: action.payload },
      };
    case Actions.SET_SALES_ORDER_PREVIEW:
      return {
        ...state,
        salesOrderPreview: action.payload,
      };
    case Actions.CLEAR_SALES_ORDER_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
