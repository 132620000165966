import * as Actions from '../actions/types';
import { WORKBOOK_STATUS_ID } from '../global/constants';

const initialState = {
  loading: false,
  workBookList: undefined,
  workBookSummaryList: undefined,
  filteredDataList: undefined,
  number: undefined,
  workbookItem: {
    id: undefined,
    date: new Date(),
    items: [],
    contact: undefined,
    number: '',
    notes: '',
    status: WORKBOOK_STATUS_ID.OPEN,
  },
  workbookItems: [],
};

export const workbookReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_WORKBOOK_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_WORKBOOK_LIST:
      return {
        ...state,
        workBookList: action.payload,
      };
    case Actions.SET_WORKBOOK_ITEM:
      return {
        ...state,
        workbookItem: action.payload,
      };
    case Actions.SET_NEXT_WORKBOOK_NUMBER:
      return {
        ...state,
        workbookItem: { ...state.workbookItem, number: action.payload },
      };
    case Actions.UPDATE_WORKBOOK_ITEM:
      return {
        ...state,
        workbookItem: { ...state.workbookItem, ...action.payload },
      };
    case Actions.UPDATE_WORKBOOK_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_WORKBOOK_SUMMARY_LIST:
      return {
        ...state,
        workBookSummaryList: action.payload,
      };
    case Actions.SET_WORKBOOK_ITEMS:
      return {
        ...state,
        workbookItems: action.payload,
      };
    case Actions.CLEAR_WORKBOOK_ITEM:
      const workbookData = JSON.parse(JSON.stringify(initialState.workbookItem));
      const workbookItemsData = JSON.parse(JSON.stringify(initialState.workbookItems));
      return {
        ...state,
        workbookItems: workbookItemsData,
        workbookItem: workbookData,
      };
    case Actions.CLEAR_WORKBOOK_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
