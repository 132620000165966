import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  isListDataUpdated: false,
  creditNotesList: undefined,
  filteredDataList: undefined,
  creditNotesInvoice: {
    id: undefined,
    contact: undefined,
    invoice: undefined,
    number: '',
    reason: '',
    date: new Date(),
    billing_address: undefined,
    shipping_address: undefined,
    sales_person: undefined,
    items: [],
    total_qty: 0,
    net_amount: 0,
    additional_fields: [],
    total_amount: 0,
    notes: '',
    sales_order_id: undefined,
    total_tax: 0,
    tax_list: undefined,
  },
  creditNotesItems: [],
  salesInvoiceItem: undefined,
  invoicePreview: undefined,
  dueCreditNoteList: undefined,
  creditTagOption: undefined,
  dueCreditNotesList: undefined,
};

export const creditNotesReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_CREDIT_NOTES_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        creditNotesList: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_INVOICE_ITEM:
      return {
        ...state,
        salesInvoiceItem: action.payload,
      };
    case Actions.UPDATE_CREDIT_NOTES_INVOICE_ITEM:
      return {
        ...state,
        creditNotesInvoice: { ...state.creditNotesInvoice, ...action.payload },
      };
    case Actions.UPDATE_CREDIT_NOTES_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_INVOICE:
      return {
        ...state,
        creditNotesInvoice: action.payload,
      };

    case Actions.SET_CREDIT_NOTES_INVOICE_PREVIEW:
      return {
        ...state,
        invoicePreview: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_INVOICE_NUMBER:
      return {
        ...state,
        creditNotesInvoice: { ...state.creditNotesInvoice, number: action.payload },
      };
    case Actions.CLEAR_CREDIT_NOTES_INVOICE_ITEM:
      const invoiceData = JSON.parse(JSON.stringify(initialState.creditNotesInvoice));
      const creditNotes_items = JSON.parse(JSON.stringify(initialState.creditNotesItems));
      return {
        ...state,
        creditNotesItems: creditNotes_items,
        creditNotesInvoice: invoiceData,
      };
    case Actions.SET_DUE_CREDIT_NOTES:
      return {
        ...state,
        dueCreditNoteList: action.payload,
      };
    case Actions.SET_CREDIT_NOTE_TAG_OPTIONS:
      return {
        ...state,
        creditTagOption: action.payload,
      };
    case Actions.CLEAR_CREDIT_NOTES_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;

    case Actions.SET_CREDIT_NOTES_ITEMS:
      return {
        ...state,
        creditNotesItems: action.payload,
      };
    case Actions.SET_CREDIT_NOTES_ITEMS_ROW:
      return {
        ...state,
        creditNotesItems: state.creditNotesItems.map((x, i) => {
          if (i === action.payload.index) x = action.payload.item;
          return x;
        }),
      };
    case Actions.SET_DUE_CREDIT_NOTES_LIST:
      return {
        ...state,
        dueCreditNotesList: action.payload,
      };
    default:
      return state;
  }
};
