import * as Actions from './../actions/types';

const initialState = {
  loading: false,
  inventoryItemList: undefined,
  isListDataUpdated: false,
  inventoryProductsDetail: undefined,
  mappedProductsList: undefined,
  filteredMappedProductList: undefined,
  BulkInventoryProductsDetail: undefined,
  categoryOption: undefined,
  catalogueOption: undefined,
  tagOption: undefined,
  packingBagSizeOption: undefined,
  filteredDataList: undefined,
  historyList: undefined,
  inventoryWarehouseList: undefined,
  inventoryWarehouseListWithDisable: undefined,
  inventoryWarehouseChildList: undefined,
  warehouseItem: {
    name: '',
    description: '',
    tags: [],
    capacity: '',
    priority: '',
  },
  inventoryItem: {
    name: '',
    hsn_code: '',
    sku: '',
    barcode_number: '',
    vendor: undefined,
    vendors: [],
    price_sales: '',
    price_purchase: '',
    tags: [],
    catalogue: '',
    tax_slab: '0',
    taxType: 2,
    gallery: [],
    category: '',
    low_inventory_qty: '',
    low_inventory_alert: false,
    is_track_inventory: true,
    weight: '',
    dimension: 'CM',
    length: '',
    breadth: '',
    height: '',
    style_id: '',
    art_no: '',
    van: '',
    brand: '',
    ean: '',
    qty: '',
    size: '',
    colour: '',
    MRP: 0,
  },
};

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_INVENTORY_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_INVENTORY_ITEM_LIST_UPDATED_FLAG:
      return {
        ...state,
        isListDataUpdated: action.payload,
      };
    case Actions.SET_INVENTORY_ITEMS_LIST:
      return {
        ...state,
        isListDataUpdated: true,
        inventoryItemList: action.payload,
      };
    case Actions.SET_INVENTORY_ITEM:
      return {
        ...state,
        inventoryItem: action.payload,
      };
    case Actions.SET_WAREHOUSE_ITEM:
      return {
        ...state,
        warehouseItem: action.payload,
      };
    case Actions.SET_INVENTORY_WAREHOUSE_LIST:
      return {
        ...state,
        inventoryWarehouseList: action.payload.warehouse_list,
        inventoryWarehouseChildList: action.payload.child_warehouse_list,
      };
    case Actions.SET_INVENTORY_WAREHOUSE_LIST_WITH_DISABLE:
      return {
        ...state,
        inventoryWarehouseListWithDisable: action.payload.warehouse_list,
      };
    case Actions.SET_INVENTORY_HISTORY_LIST:
      return {
        ...state,
        historyList: action.payload,
      };
    case Actions.SET_INVENTORY_PRODUCT_DETAILS:
      return {
        ...state,
        inventoryProductsDetail: action.payload,
      };
    case Actions.SET_BULK_INVENTORY_PRODUCT_DETAILS:
      return {
        ...state,
        BulkInventoryProductsDetail: action.payload,
      };
    case Actions.SET_INVENTORY_PRODUCT_TAG_OPTION:
      return {
        ...state,
        tagOption: action.payload,
      };
    case Actions.SET_INVENTORY_PRODUCT_CATEGORY_OPTION:
      return {
        ...state,
        categoryOption: action.payload,
      };
    case Actions.SET_INVENTORY_PRODUCT_CATALOGUE_OPTION:
      return {
        ...state,
        catalogueOption: action.payload,
      };
    case Actions.UPDATE_INVENTORY_ITEM_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.UPDATE_INVENTORY_ITEM:
      return {
        ...state,
        inventoryItem: { ...state.inventoryItem, ...action.payload },
      };
    case Actions.UPDATE_INVENTORY_LIST_ITEM:
      let { inventoryItemList = [] } = state;
      const newList = JSON.parse(JSON.stringify(inventoryItemList));
      const updatedItems = action.payload.updated;
      const archivedItems = action.payload.archived;
      if (updatedItems?.length > 0) {
        updatedItems?.forEach((item) => {
          const itemIndex = newList?.findIndex((x) => x?.id === item?.id);
          if (itemIndex !== -1 && itemIndex !== undefined) newList[itemIndex] = item;
          else newList?.push(item);
        });
      }
      if (archivedItems?.length > 0) {
        archivedItems?.forEach((itemId) => {
          const itemIndex = newList?.findIndex((x) => x?.id === itemId);
          if (itemIndex !== -1 && itemIndex !== undefined) newList?.splice(itemIndex, 1);
        });
      }
      return {
        ...state,
        inventoryItemList: newList,
      };
    case Actions.UPDATE_WAREHOUSE_ITEM:
      return {
        ...state,
        warehouseItem: { ...state.warehouseItem, ...action.payload },
      };
    case Actions.CLEAR_WAREHOUSE_ITEM_DATA:
      const warehouseItemData = JSON.parse(JSON.stringify(initialState.warehouseItem));
      return {
        ...state,
        warehouseItem: warehouseItemData,
      };
    case Actions.CLEAR_INVENTORY_ITEM_DATA:
      const inventoryItemData = JSON.parse(JSON.stringify(initialState.inventoryItem));
      return {
        ...state,
        inventoryItem: inventoryItemData,
      };
    case Actions.SET_MAPPED_PRODUCT_LIST:
      return {
        ...state,
        mappedProductsList: action.payload,
      };
    case Actions.SET_FILTERED_MAPPED_PRODUCT_LIST:
      return {
        ...state,
        filteredMappedProductList: action.payload,
      };
    case Actions.SET_INVENTORY_PRODUCT_PACKING_BAG_SIZES:
      return {
        ...state,
        packingBagSizeOption: action.payload,
      };
    case Actions.CLEAR_INVENTORY_DATA:
      return initialState;
    default:
      return state;
  }
};
