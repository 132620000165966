import * as Actions from './types';

/**
 * @desc Set Sales Loader
 */
export const setSalesLoader = (payload) => {
  return {
    type: Actions.SET_SALES_LOADER,
    payload,
  };
};

/**
 * @desc Set Sales Loader
 */
export const setSalesListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_SALES_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set sales List
 */
export const setSalesInvoiceList = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc Set sales invoice item
 */
export const setSalesInvoiceItem = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Set sales invoice items
 */
export const setSalesInvoiceItems = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_ITEMS,
    payload,
  };
};

/**
 * @desc Set sales invoice items row
 */
export const setSalesInvoiceItemsRow = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_ITEMS_ROW,
    payload,
  };
};

/**
 * @desc update Filtered Data List
 */
export const updateFilteredDataList = (payload) => {
  return {
    type: Actions.UPDATE_FILTERED_DATA_LIST,
    payload,
  };
};

/**
 * @desc Update invoice item
 */
export const updateInvoiceItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_SALES_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Clear sales Invoice data
 */
export const clearSalesInvoiceData = () => {
  return {
    type: Actions.CLEAR_SALES_INVOICE_ITEM,
  };
};

/**
 * @desc Set sales invoice preview
 */
export const setSalesInvoicePreview = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_PREVIEW,
    payload,
  };
};

/**
 * @desc Clear sales data
 */
export const clearSalesData = (payload = {}) => {
  return {
    type: Actions.CLEAR_SALES_DATA,
    payload,
  };
};

/**
 * @desc Set Invoice Items List(Product)
 */
export const setInvoiceProductsList = (payload) => {
  return {
    type: Actions.SET_INVOICE_PRODUCT_LIST,
    payload,
  };
};

/**
 * @desc Update Invoice Items List Item(Product)
 */
export const updateInvoiceProductListItem = (payload) => {
  return {
    type: Actions.UPDATE_INVOICE_PRODUCT_LIST_ITEM,
    payload,
  };
};

/**
 * @desc Set Inventory Number
 */
export const setInvoiceNumber = (payload) => {
  return {
    type: Actions.SET_INVOICE_NUMBER,
    payload,
  };
};

/**
 * @desc Set Due invoice List
 */
export const setDueInvoiceList = (payload) => {
  return {
    type: Actions.SET_DUE_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc update Due invoice List
 */
export const updateDueInvoiceList = (payload) => {
  return {
    type: Actions.UPDATE_DUE_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc Set Sales Invoice Number List
 */
export const setSalesInvoiceNumberList = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_NUMBER_LIST,
    payload,
  };
};
/**
 * @desc Set Invoice Payment Item
 */
export const setInvoicePaymentItem = (payload) => {
  return {
    type: Actions.SET_INVOICE_PAYMENT_ITEM,
    payload,
  };
};
/**
 * @desc Update Invoice Payment Item
 */
export const updateInvoicePaymentItem = (payload) => {
  return {
    type: Actions.UPDATE_INVOICE_PAYMENT_ITEM,
    payload,
  };
};
/**
 * @desc Clear Invoice Payment Item
 */
export const clearInvoicePaymentItem = (payload) => {
  return {
    type: Actions.CLEAR_INVOICE_PAYMENT_ITEM,
    payload,
  };
};
/**
 * @desc Get Sales Invoice Tag Option
 */
export const setSalesInvoiceTagOptions = (payload) => {
  return {
    type: Actions.SET_SALES_INVOICE_TAG_OPTIONS,
    payload,
  };
};

/**
 * @desc Set Export List
 */
export const setExportList = (payload) => {
  return {
    type: Actions.SET_EXPORT_LIST,
    payload,
  };
};
