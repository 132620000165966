import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setItemErrorMessage } from '../actions/messageActions';
import { setNotificationList, setNotificationLoader } from '../actions/notificationActions';
import { REACT_APP_APIURL } from '../global/environment';
import { getAPIResponseError } from '../helper/common';

/**
 * @desc Notification - Get Notification List
 * @param {*} organization_id
 */
export const getNotificationList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/notifications/${organization_id}`);
    const { data } = response.data;
    if (data) dispatch(setNotificationList(data));
    return true;
  } catch (e) {
    dispatchNotificationError(
      getAPIResponseError(e) || 'Unable to get notification list, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

/**
 * @desc Notification - Mark As Read
 * @param {*} organization_id
 */
export const markAsReadNotification = (organization_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/notifications/${organization_id}/read-single/${item_id}`);
    const { data } = response;
    if (data) await dispatch(getNotificationList(organization_id));
    return true;
  } catch (e) {
    dispatchNotificationError(
      getAPIResponseError(e) || 'Unable to mark as read notification, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

/**
 * @desc Notification - Mark As Read All Notifications
 * @param {*} organization_id
 */
export const markAsReadAllNotifications = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/notifications/${organization_id}/read-all`);
    const { data } = response;
    if (data) await dispatch(getNotificationList(organization_id));
    return true;
  } catch (e) {
    dispatchNotificationError(
      getAPIResponseError(e) || 'Unable to mark as read all notifications, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

function dispatchNotificationError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
