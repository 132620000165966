import * as Actions from './types';

/**
 * @desc Set Purchase Loader
 */
export const setPurchaseLoader = (payload) => {
  return {
    type: Actions.SET_PURCHASE_LOADER,
    payload,
  };
};

/**
 * @desc Set Purchase List Updated Flag
 */
export const setPurchaseListUpdatedFlag = (payload) => {
  return {
    type: Actions.SET_PURCHASE_LIST_UPDATED_FLAG,
    payload,
  };
};

/**
 * @desc Set Purchase List
 */
export const setPurchaseInvoiceList = (payload) => {
  return {
    type: Actions.SET_PURCHASE_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc Clear Purchase data
 */
export const clearPurchaseData = (payload = {}) => {
  return {
    type: Actions.CLEAR_PURCHASE_DATA,
    payload,
  };
};

/**
 * @desc Set Purchase invoice item
 */
export const setPurchaseInvoiceItem = (payload) => {
  return {
    type: Actions.SET_PURCHASE_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Update invoice item
 */
export const updateInvoiceItem = (payload = {}) => {
  return {
    type: Actions.UPDATE_PURCHASE_INVOICE_ITEM,
    payload,
  };
};

/**
 * @desc Clear Purchase Invoice data
 */
export const clearPurchaseInvoiceData = () => {
  return {
    type: Actions.CLEAR_PURCHASE_INVOICE_ITEM,
  };
};

/**
 * @desc Set Purchase invoice preview
 */
export const setPurchaseInvoicePreview = (payload) => {
  return {
    type: Actions.SET_PURCHASE_INVOICE_PREVIEW,
    payload,
  };
};

/**
 * @desc Set Invoice Items List(Product)
 */
export const setInvoiceProductsList = (payload) => {
  return {
    type: Actions.SET_INVOICE_PRODUCT_LIST,
    payload,
  };
};

/**
 * @desc Set Due bill List
 */
export const setDueBillList = (payload) => {
  return {
    type: Actions.SET_DUE_BILL_LIST,
    payload,
  };
};

/**
 * @desc update Due bill List
 */
export const updateDueBillList = (payload) => {
  return {
    type: Actions.UPDATE_DUE_BILL_LIST,
    payload,
  };
};

/**
 * @desc Set invoice Number list
 */
export const setPurchaseNumberList = (payload) => {
  return {
    type: Actions.SET_PURCHASE_NUMBER_LIST,
    payload,
  };
};

/**
 * @desc update Purchase Filtered List
 */
export const updatePurchaseFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_PURCHASE_FILTERED_LIST,
    payload,
  };
};

/**
 * @desc Get Purchase Invoice Tag Option
 */
export const setPurchaseInvoiceTagOption = (payload) => {
  return {
    type: Actions.SET_PURCHASE_INVOICE_TAG_OPTIONS,
    payload,
  };
};

/**
 * @desc set Purchase Items
 */
export const setPurchaseItems = (payload) => {
  return {
    type: Actions.SET_PURCHASE_ITEMS,
    payload,
  };
};

/**
 * @desc set Purchase Items Row
 */
export const setPurchaseItemsRow = (payload) => {
  return {
    type: Actions.SET_PURCHASE_CHALLAN_ITEMS_ROW,
    payload,
  };
};
