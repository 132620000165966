import * as Actions from './types';

/**
 * @desc Set Notification Loader
 */
export const setNotificationLoader = (payload) => {
  return {
    type: Actions.SET_NOTIFICATION_LOADER,
    payload: payload,
  };
};

/**
 * @desc Set Notification List
 */
export const setNotificationList = (payload) => {
  return {
    type: Actions.SET_NOTIFICATION_LIST,
    payload: payload,
  };
};

/**
 * @desc Set Notification List
 */
export const clearNotificationData = () => {
  return {
    type: Actions.CLEAR_NOTIFICATION_DATA,
  };
};
