import * as Actions from './types';
/**
 * @desc Update membership error
 */
export const updateMembershipError = (payload) => {
  return {
    type: Actions.UPDATE_MEMBERSHIP_ERROR,
    payload,
  };
};

/**
 * @desc clear membership error
 */
export const clearMembershipError = () => {
  return {
    type: Actions.CLEAR_MEMBERSHIP_ERROR,
  };
};
