import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import {
  setInventoryItemData,
  setInventoryItemsList,
  setInventoryLoader,
  setInventoryWarehouseList,
  setInventoryProductDetails,
  setTagOption,
  updateInventoryItemFilteredList,
  setInventoryHistoryList,
  setWarehouseItemData,
  setCatalogueOption,
  setBulkProductDetail,
  setMappedProductList,
  updateInventoryListItem,
  setPackingBagSizes,
  setInventoryWarehouseListWithDisable,
} from '../actions/InventoryActions';
import { updateInventoryTransactionFilteredList } from '../actions/inventoryTransactionActions';
import {
  clearResponseMessage,
  setErrorMessage,
  setItemErrorMessage,
  setItemSucessMessage,
  setSuccessMessage,
} from '../actions/messageActions';
import { setInvoiceProductsList, updateInvoiceProductListItem } from '../actions/salesActions';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import { REACT_APP_APIURL } from '../global/environment';
import { getAPIResponseError } from '../helper/common';
import ProductCacheService from './productCacheServices';

/**
 * @desc  Get Inventory items list
 * @param {*} CompanyId
 */
export const getInventoryItemsList =
  (company_id, isModalError, isLoadPagging = false, isIncludeDeactiveProduct, isUpdateRedux = true, signal) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearResponseMessage());
      dispatch(setInventoryLoader(true));
      const state = getState();
      const { inventoryItemList } = state?.inventory || {};
      if (isUpdateRedux || !inventoryItemList || inventoryItemList?.length === 0) {
        //Set list from local cache
        const local_data = await ProductCacheService.getInstance()?.getProducts();
        const result = local_data;
        if (result && result.length > 0) {
          const inventoryProducts = result.filter((x) => x.is_inventory_product === true);
          const invoiceProduct = result.filter((x) => x.is_invoice_product === true);
          dispatch(setInventoryItemsList(inventoryProducts || []));
          dispatch(setInvoiceProductsList(invoiceProduct || []));
          dispatch(updateInventoryItemFilteredList(inventoryProducts || []));
        }
      }
      const params = {};
      if (isIncludeDeactiveProduct) params.include_inactive = true;

      const lastUpdatedTimeFromLocalStorage = await ProductCacheService.getInstance()?.getLastUpdatedTime();
      const lastUpdatedTime = lastUpdatedTimeFromLocalStorage || 0;

      const response = await axios.get(
        `${REACT_APP_APIURL}/products/${company_id}/last-updated-list/${lastUpdatedTime}`,
        { params, signal },
      );
      const { updated, archived, lastUpdatedOn } = response.data.data;
      let hasUpdatedData = false;
      if (lastUpdatedTime === 0) {
        await ProductCacheService.getInstance()?.setProducts(updated, company_id);
        hasUpdatedData = true;
      } else {
        await ProductCacheService.getInstance()?.updateProducts(updated, archived, company_id);
        if ((updated && updated.length > 0) || (archived && archived.length > 0)) {
          hasUpdatedData = true;
        }
      }
      await ProductCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn, company_id);

      //Update data in redux
      if (hasUpdatedData) {
        //Set list from local cache
        const local_data = await ProductCacheService.getInstance()?.getProducts();
        if (local_data && local_data.length > 0) {
          const result = local_data;
          if (result) {
            const payload = { updated: updated || [], archived: archived || [] };
            dispatch(updateInventoryListItem(payload));
            dispatch(updateInvoiceProductListItem(payload));
          }
        }
      }
      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
      return false;
    } finally {
      dispatch(setInventoryLoader(false));
    }
  };

/**
 * @desc  Get Inventory void items list
 * @param {*} CompanyId
 */
export const getInventoryVoidItemsList =
  (company_id, isModalError, isLoadPagging = false, isIncludeDeactiveProduct, isVoidItem, signal) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage());
      const params = {
        include_inactive: true,
      };
      const response = await axios.get(
        `${REACT_APP_APIURL}/products/${company_id}${isVoidItem ? '/void-items' : ''}`,
        isIncludeDeactiveProduct && { params, signal },
      );
      const { data } = response.data;
      const inventoryProducts = data && data.filter((x) => x.is_inventory_product === true);
      const invoiceProduct = data && data.filter((x) => x.is_invoice_product === true);
      if (data) {
        dispatch(setInventoryItemsList(inventoryProducts || []));
        dispatch(setInvoiceProductsList(invoiceProduct || []));
        dispatch(updateInventoryItemFilteredList(inventoryProducts || []));
      }

      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchInventoryError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setInventoryLoader(false));
    }
  };

/**
 * @desc Inventory - Create Inventory Item
 * @param {*} obj CompanyId , Data Obj
 */
export const createInventoryItem = (company_id, obj) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!obj) {
      dispatchInventoryError('Inventory Name is Required', dispatch, isModalError);
      return;
    } else if (!obj.name) {
      dispatchInventoryError('Inventory Name is Required', dispatch, isModalError);
      return;
    } else if (!obj.hsn_code) {
      dispatchInventoryError('Hsn_code is Required', dispatch, isModalError);
      return;
    } else if (!obj.hsn_code || (obj.hsn_code.length !== 6 && obj.hsn_code?.length !== 8)) {
      dispatchInventoryError('Hsn code should be of 6 or 8 digit numerics', dispatch, isModalError);
      return;
    } else if (!obj.tax_slab) {
      dispatchInventoryError('Tax Rate is Required', dispatch, isModalError);
      return;
    } else if (!obj.sku) {
      dispatchInventoryError('SKU is Required', dispatch, isModalError);
      return;
    } else if (!obj.vendor) {
      dispatchInventoryError('Supplier is Required', dispatch, isModalError);
      return;
    } else if (!obj.weight) {
      dispatchInventoryError('Weight is Required', dispatch, isModalError);
      return;
    } else if (!obj.length) {
      dispatchInventoryError('Length is Required', dispatch, isModalError);
      return;
    } else if (!obj.height) {
      dispatchInventoryError('Height is Required', dispatch, isModalError);
      return;
    } else if (!obj.breadth) {
      dispatchInventoryError('Breadth is Required', dispatch, isModalError);
      return;
    } else if (!obj.dimension) {
      dispatchInventoryError('Dimension is Required', dispatch, isModalError);
      return;
    } else if (!obj.price_sales) {
      dispatchInventoryError('Selling Price is Required', dispatch, isModalError);
      return;
    } else if (!obj.price_purchase) {
      dispatchInventoryError('Supplier Price is Required', dispatch, isModalError);
      return;
    } else if (!obj.category) {
      dispatchInventoryError('Category is Required', dispatch, isModalError);
      return;
    } else if (!obj.catalogue) {
      dispatchInventoryError('Catalogue is Required', dispatch, isModalError);
      return;
    } else if (!obj.gallery || obj.gallery.length === 0) {
      dispatchInventoryError('Item Image is Required', dispatch, isModalError);
      return;
    }
    dispatch(setInventoryLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}`, obj);
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};
/**
 * @desc  Get Inventory items Details
 * @param {*} obj CompanyId , Data Obj
 */
export const getInventoryItemDetails = (company_id, item_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/item/${item_id}`, { signal });
    const { data } = response.data;
    if (data) {
      if (!data.vendors || data.vendors.length === 0) data.vendors = [{}];
      dispatch(setInventoryItemData(data || {}));
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Edit Inventory item
 * @param {*} obj CompanyId , ItemId , Data Obj
 */
export const updateInventoryItem = (company_id, item_id, itemObj, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    if (!itemObj) {
      dispatchInventoryError('Inventory Name is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.name) {
      dispatchInventoryError('Inventory Name is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.hsn_code) {
      dispatchInventoryError('Hsn Code is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.hsn_code || (itemObj.hsn_code.length !== 6 && itemObj.hsn_code?.length !== 8)) {
      dispatchInventoryError('Hsn code should be of 6 or 8 digit numerics', dispatch, isModalError);
      return;
    } else if (!itemObj.tax_slab) {
      dispatchInventoryError('Tax Rate is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.sku) {
      dispatchInventoryError('SKU is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.vendor) {
      dispatchInventoryError('Supplier is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.weight) {
      dispatchInventoryError('Weight is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.length) {
      dispatchInventoryError('Length is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.height) {
      dispatchInventoryError('Height is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.breadth) {
      dispatchInventoryError('Breadth is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.dimension) {
      dispatchInventoryError('Dimension is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.price_sales) {
      dispatchInventoryError('Selling Price is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.price_purchase) {
      dispatchInventoryError('Supplier Price is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.category) {
      dispatchInventoryError('Category is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.catalogue) {
      dispatchInventoryError('Catalogue is Required', dispatch, isModalError);
      return;
    } else if (!itemObj.gallery || itemObj.gallery.length === 0) {
      dispatchInventoryError('Item Image is Required', dispatch, isModalError);
      return;
    }
    const response = await axios.put(`${REACT_APP_APIURL}/products/${company_id}/item/${item_id}`, itemObj);
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory items Details
 * @param {*} obj CompanyId , Data Obj
 */
export const getInventoryProductDetails = (company_id, item_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/details/${item_id}`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setInventoryProductDetails(data || []));
      const localProductItem = await ProductCacheService.getInstance()?.getItem(item_id);
      const totalQty = data?.warehouses?.reduce((sum, val) => sum + Number(val?.qty || 0), 0);
      if (totalQty !== localProductItem?.qty) {
        localProductItem.qty = totalQty;
        await ProductCacheService.getInstance()?.updateExisting(item_id, localProductItem);
      }
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc Get Inventory History List
 * @param {*} obj CompanyId , Data Obj
 */
export const getInventoryHistoryList =
  (company_id, item_id, isLoadPagging = false) =>
  async (dispatch) => {
    const isModalError = true;
    try {
      dispatch(clearResponseMessage());
      dispatch(setInventoryLoader(true));

      const response = await axios.get(`${REACT_APP_APIURL}/activities/${company_id}/by-item/${item_id}`);
      const { data } = response.data;
      if (data) {
        dispatch(setInventoryHistoryList(data || []));
        dispatch(updateInventoryItemFilteredList(data || []));
      }
      return true;
    } catch (e) {
      console.log('ERROR', e);
      dispatchInventoryError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setInventoryLoader(false));
    }
  };

/**
 * @desc  Delete Inventory items
 * @param {*} obj CompanyId , ItemId
 */
export const deleteInventoryItem = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/products/${company_id}/item/${item_id}`);
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    console.log('ERROR', e);
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory Warehouse list With Disable
 * @param {*} CompanyId
 */
export const getInventoryWarehouseListWithDisable = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/warehouse/${company_id}?include_deactive=true`, { signal });
    const { data } = response.data;
    dispatch(setInventoryWarehouseListWithDisable(data || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory Warehouse list
 * @param {*} CompanyId
 */
export const getInventoryWarehouseList = (company_id, isModalError, signal) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage());
    const state = getState();
    const inventoryWarehouseList = state?.inventory?.inventoryWarehouseList;
    if (inventoryWarehouseList?.length > 0) return false;

    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/warehouse/${company_id}`, { signal });
    const { data } = response.data;
    dispatch(setInventoryWarehouseList(data || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Warehouse items Details
 * @param {*} obj CompanyId , Data Obj
 */
export const getWarehouseItemDetails = (company_id, item_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/warehouse/${company_id}/item/${item_id}`, { signal });
    const { data } = response.data;
    if (data) dispatch(setWarehouseItemData(data || {}));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc Inventory/warehouse - Create Warehouse Item
 * @param {*} obj CompanyId , Data Obj
 */
export const createWarehouse = (company_id, obj) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!obj) {
      dispatchInventoryError('Warehouse Name is Required', dispatch, isModalError);
      return;
    } else if (!obj.name) {
      dispatchInventoryError('Name is Required', dispatch, isModalError);
      return;
    } else if (!obj.capacity) {
      dispatchInventoryError('Capacity is Required', dispatch, isModalError);
      return;
    }
    dispatch(setInventoryLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/warehouse/${company_id}`, obj);
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    dispatchInventoryError(getAPIResponseError(e) || 'Unable to login please try again', dispatch, isModalError);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Edit Inventory Warehouse list
 * @param {*} CompanyId
 */
export const updateWarehouse = (company_id, itemId, obj) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage(''));
    if (!obj) {
      dispatchInventoryError('Warehouse Name is Required', dispatch, isModalError);
      return;
    } else if (!obj.name) {
      dispatchInventoryError('name is Required', dispatch, isModalError);
      return;
    } else if (!obj.capacity) {
      dispatchInventoryError('Capacity is Required', dispatch, isModalError);
      return;
    }
    dispatch(setInventoryLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/warehouse/${company_id}/item/${itemId}`, obj);
    const { data } = response.data;
    if (data) return true;
    return false;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Delete warehouse
 * @param {*} obj CompanyId , ItemId
 */
export const deleteWarehouse = (company_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/warehouse/${company_id}/item/${item_id}`);
    if (response.data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory Items Tag Option
 * @param {*} CompanyId
 */
export const getTagOption = (company_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/tags`, { signal });
    const { data } = response.data;
    let itemWithLabel = [];
    itemWithLabel = data && data.map((item) => ({ label: item, value: item }));
    if (itemWithLabel) dispatch(setTagOption(itemWithLabel || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory Items Category Option
 * @param {*} CompanyId
 */
export const getCatalogueOption = (company_id, signal) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/values/catalogue`, { signal });
    const { data } = response.data;
    let itemWithLabel = [];
    itemWithLabel = data && data.map((item) => ({ label: item, value: item }));
    if (itemWithLabel) dispatch(setCatalogueOption(itemWithLabel || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Inventory Items packing Back Size
 * @param {*} CompanyId
 */
export const getPackingBagSizes = (company_id, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setInventoryLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/values/packing_bag_size`, { signal });
    const { data } = response.data;
    let itemWithLabel = [];
    itemWithLabel = data && data.map((item) => ({ label: item, value: item }));
    if (itemWithLabel) dispatch(setPackingBagSizes(itemWithLabel || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Unable to get item packing bag sizes, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Import Product
 * @param {*} obj CompanyId , ItemId
 */
export const validateProductImportFile = (company_id, file) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    const body = new FormData();
    body.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/validate-import`, body, {
      headers: headers,
    });
    const { data } = response.data;
    if (data) return data;
    return;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Import Sales
 * @param {*} obj CompanyId , ItemId
 */
export const validateSalesImportFile = (company_id, file) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    const body = new FormData();
    body.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await axios.post(`${REACT_APP_APIURL}/sales/${company_id}/validate-import`, body, {
      headers: headers,
    });
    const { data } = response.data;
    if (data) return data;
    return;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Import Contact
 * @param {*} obj CompanyId , ItemId
 */
export const importProducts = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/import`, obj);
    const { data } = response.data;
    if (data) return data;
    return;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Import Sales
 * @param {*} obj CompanyId , ItemId
 */
export const importSales = (company_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/sales/${company_id}/import`, obj);
    const { data, message } = response.data;
    dispatchInventorySuccess(message, dispatch, false);
    return data;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch, false);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get Warehouse by Product Ids
 * @param {*} CompanyId
 */
export const getWarehouseStockBySelectedProducts =
  (company_id, productIds, isModalError, signal) => async (dispatch) => {
    try {
      dispatch(clearResponseMessage());
      dispatch(setInventoryLoader(true));
      const response = await axios.post(
        `${REACT_APP_APIURL}/products/${company_id}/warehouse-stocks`,
        {
          ids: productIds,
        },
        { signal },
      );
      const { data } = response.data;
      const list = data.filter((x) => x.qty > 0);
      return list || [];
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchInventoryError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setInventoryLoader(false));
    }
  };

/**
 * @desc  Get Warehouse by Product
 * @param {*} CompanyId
 */
export const getWarehouseStockByProduct =
  (company_id, product_id, isModalError, isFilterEmptyRacks = true, signal) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage());
      dispatch(setInventoryLoader(true));
      const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/warehouse-stocks/${product_id}`, {
        signal,
      });
      const { data } = response.data;
      let updateData = data.map((x) => {
        return {
          ...x,
          ...x.warehouse,
          label: x.warehouse.name,
        };
      });
      if (isFilterEmptyRacks) {
        updateData = updateData.filter((x) => x.qty > 0);
      }
      return updateData || [];
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchInventoryError(
        getAPIResponseError(e) || 'Something goes wrong, please try again later',
        dispatch,
        isModalError,
      );
      return false;
    } finally {
      dispatch(setInventoryLoader(false));
    }
  };

/**
 * @desc  Get Inventory bulk product detail
 * @param {*} CompanyId
 */
export const getBulkProductDetail = (company_id, obj, isModalError, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/bulk-details`, obj, { signal });
    const { data } = response.data;
    dispatch(setBulkProductDetail(data || []));
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Get mapped Products List
 * @param {*} CompanyId
 */
export const getMappedProductsList = (company_id, signal) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/products/${company_id}/inventory-mapping`, { signal });
    const { data } = response.data;
    if (data) {
      dispatch(setMappedProductList(data.reverse() || []));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Update Product Status
 * @param {*} CompanyId
 */
export const updateProductStatus = (company_id, item_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setInventoryLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/products/${company_id}/status/${item_id}`, payload);
    const { data } = response.data;
    if (data) {
      return true;
    }
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Unable to Update product status, please try again later');
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Map Inventory Items
 * @param {*} CompanyId
 */
export const mapInventoryItems = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/inventory-mapping`, payload);
    const { data, message } = response.data;
    if (data) {
      await dispatch(getMappedProductsList(company_id));
      dispatchInventorySuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchInventoryError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc search and filter Inventory Transactions on Server
 * @param {*} company_id CompanyId
 */
export const searchFilterInventoryTransactionOnServer = (company_id, searchText, filterData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setInventoryLoader(true));
    const payload = {
      search: searchText,
      filter: filterData,
    };
    const response = await axios.post(`${REACT_APP_APIURL}/inventory-transactions/${company_id}/search`, payload);
    const { data } = response.data;
    if (data) {
      const sorted_data = process(data, {
        sort: [
          { field: 'date', dir: 'desc' },
          { field: 'number', dir: 'desc' },
        ],
      });
      let list = sorted_data?.data;
      dispatch(updateInventoryTransactionFilteredList(list || []));
      return true;
    }
  } catch (e) {
    dispatchInventoryError(
      getAPIResponseError(e) || 'Unable to search or filter inventory transactions, please try again later',
      dispatch,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Import Product Sku List and Get SKu with Latest Available Qty
 * @param {*} obj CompanyId , ItemId
 */
export const ImportSkuAndGetQty = (company_id, file) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setInventoryLoader(true));

    const body = new FormData();
    body.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/qty-by-sku`, body, {
      headers: headers,
    });
    const { data } = response.data;
    if (data) return data;
    return;
  } catch (e) {
    console.log('ERROR', e);
    dispatchInventoryError(getAPIResponseError(e) || 'Unable to import file, please try again later', dispatch, false);
    return null;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc Clone product to Other Organization
 * @param {*} company_id CompanyId
 */
export const cloneProductToOtherOrg = (company_id, otherCompanyId, payload, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setInventoryLoader(true));

    if (!payload) {
      dispatchInventoryError('Product is Required', dispatch, isModalError);
      return;
    } else if (!otherCompanyId) {
      dispatchInventoryError('Organisation is Required', dispatch, isModalError);
      return;
    }

    payload = { ...payload, cid: otherCompanyId };
    const response = await axios.post(`${REACT_APP_APIURL}/products/${company_id}/clone-product`, payload);
    const { data } = response.data;
    if (data) {
      dispatchInventorySuccess('Product Clonned Successfully', dispatch);
      return true;
    }
  } catch (e) {
    dispatchInventoryError(
      getAPIResponseError(e) || 'Unable to clone product to other organization, please try again later',
      dispatch,
      isModalError,
    );
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

/**
 * @desc  Disable / Enable warehouse
 * @param {*} obj CompanyId , ItemId
 */
export const updateWarehouseStatus = (company_id, item_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setInventoryLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/warehouse/${company_id}/update-status/${item_id}`, {
      status: payload,
    });
    if (response.data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch(setErrorMessage(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later'));
    return false;
  } finally {
    dispatch(setInventoryLoader(false));
  }
};

function dispatchInventoryError(msg, dispatch, isModalError) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}

function dispatchInventorySuccess(msg, dispatch, isModalSuccess = false) {
  isModalSuccess ? dispatch(setItemSucessMessage(msg)) : dispatch(setSuccessMessage(msg));
}
