import { isEmpty } from 'lodash';
import moment from 'moment';
import store from '../store/store';
import * as Actions from './types';

/**
 * @desc Set Bank Account Loader
 */
export const setBankAccountLoader = (payload) => {
  return {
    type: Actions.SET_BANK_ACCOUNT_LOADER,
    payload,
  };
};

/**
 * @desc Set Bank Account List
 */
export const setBankAccountList = (payload) => {
  return {
    type: Actions.SET_BANK_ACCOUNT_LIST,
    payload,
  };
};

/**
 * @desc Set Banks transactions List
 */
export const setBankTransactionList = (payload) => {
  return {
    type: Actions.SET_BANKS_TRANSACTIONS_LIST,
    payload,
  };
};

/**
 * @desc Set Bank Item
 */
export const setBankItem = (payload) => {
  return {
    type: Actions.SET_BANKS_ITEM,
    payload,
  };
};

/**
 * @desc Update Bank Item
 */
export const updateBankItem = (payload) => {
  return {
    type: Actions.UPDATE_BANK_ITEM,
    payload,
  };
};

/**
 * @desc Clear Bank Item Data
 */
export const clearBankItemData = () => {
  return {
    type: Actions.CLEAR_BANK_ITEM,
  };
};

/**
 * @desc Update Bank Transaction Item
 */
export const updateBankTransactionsItem = (payload) => {
  return {
    type: Actions.UPDATE_BANK_TRANSACTIONS_ITEM,
    payload,
  };
};

/**
 * @desc Clear Bank Transaction Item
 */
export const clearBankTransactionItem = () => {
  return {
    type: Actions.CLEAR_BANK_TRANSACTIONS_ITEM,
  };
};

/**
 * @desc Update Transfer Fund Item
 */
export const updateTransferFundItem = (payload) => {
  return {
    type: Actions.UPDATE_TRANSFER_FUND_ITEM,
    payload,
  };
};

/**
 * @desc Clear Transfer Fund Item
 */
export const clearTransferFundItem = () => {
  return {
    type: Actions.CLEAR_TRANSFER_FUND_ITEM,
  };
};

/**
 * @desc Set Tax List
 */
export const setTaxList = (payload) => {
  return {
    type: Actions.SET_TAX_LIST,
    payload,
  };
};

/**
 * @desc Set TDS List
 */
export const setTdsList = (payload) => {
  return {
    type: Actions.SET_TDS_LIST,
    payload,
  };
};

/**
 * @desc Set TCS List
 */
export const setTcsList = (payload) => {
  return {
    type: Actions.SET_TCS_LIST,
    payload,
  };
};

/**
 * @desc Set Bank Transaction Detail
 */
export const setBankTransactionDetails = (payload) => {
  return {
    type: Actions.SET_BANKS_TRANSACTIONS_DETAILS,
    payload,
  };
};

/**
 * @desc Update Bank Transaction Detail
 */
export const updateBankTransactionDetails = (payload) => {
  return {
    type: Actions.UPDATE_BANKS_TRANSACTIONS_DETAILS,
    payload,
  };
};

/**
 * @desc Clear Bank Transaction Detail
 */
export const clearBankTransactionDetails = (payload) => {
  return {
    type: Actions.CLEAR_BANKS_TRANSACTIONS_DETAILS,
    payload,
  };
};

/**
 * @desc update Cash And Bank Filter List
 */
export const updateCashAndBankFilterList = (payload) => {
  // Check for bank transactions status if they achieved the benchmark by relative status
  if (payload && payload.length > 0) payload = getIsBankTransactionBenchmarkAlert(payload);
  return {
    type: Actions.UPDATE_FILTER_DATA_LIST,
    payload,
  };
};

/**
 * @desc Set Match Transaction Invoice List
 */
export const setMatchTransactionInvoiceList = (payload) => {
  return {
    type: Actions.SET_MATCH_TRANSACTION_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc Update Match Transaction Invoice List
 */
export const updateMatchTransactionInvoiceList = (payload) => {
  return {
    type: Actions.UPDATE_MATCH_TRANSACTION_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc Set Bank Transactions Uncategorized Benchmark
 */
export const setBankTransactionsUncategorizedBenchmark = (payload) => {
  return {
    type: Actions.SET_BANK_TRANSACTIONS_UNCATEGORIZED_BENCHMARK,
    payload,
  };
};

/**
 * @desc Clear Match Transaction Invoice List
 */
export const clearMatchTransactionInvoiceList = (payload) => {
  return {
    type: Actions.CLEAR_MATCH_TRANSACTION_INVOICE_LIST,
    payload,
  };
};

/**
 * @desc update Cash And Bank Data
 */
export const clearBankAccountData = (payload) => {
  return {
    type: Actions.CLEAR_BANK_ACCOUNT_DATA,
    payload,
  };
};

const getIsBankTransactionBenchmarkAlert = (list) => {
  if (list?.length === 0) return [];
  const state = store.getState();
  const { companySettings } = state.setting;
  const { uncategorized } = companySettings?.benchmark?.bank_transactions || {};
  if (isEmpty(companySettings?.benchmark?.bank_transactions)) return list;

  list = list?.map((x, i) => {
    if (x?.imported && x?.is_unverified && !x?.is_excluded && x?.status_updated_at && x?.status_change_history) {
      let isBenchMarkAlert = false;
      const statusUpdatedDate = moment(x?.status_updated_at);
      const todayDate = moment(new Date());
      const differenceInHours = todayDate.diff(statusUpdatedDate, 'hours');
      const benchmarkInHours = uncategorized * 24;
      isBenchMarkAlert = differenceInHours > benchmarkInHours;
      x.isBenchMarkAlert = isBenchMarkAlert;
    }
    return x;
  });
  return list;
};
