import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  manifestList: undefined,
  filteredDataList: [],
};

export const manifestReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_MANIFEST_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_MANIFEST_LIST:
      return {
        ...state,
        manifestList: action.payload,
      };
    case Actions.UPDATE_MANIFEST_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload,
      };
    case Actions.CLEAR_MANIFEST_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
